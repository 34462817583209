// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface iProps {
	color?:
		| 'primary'
		| 'inherit'
		| 'secondary'
		| 'info'
		| 'success'
		| 'warning'
		| 'error';
	size?: number;
	showText?: boolean;
	text?: string;
}

const PXLoader = ({
	color = 'primary',
	size = 25,
	showText = true,
	text = 'Cargando...',
}: iProps) => {
	return (
		<>
			<MDBox display={'flex'} justifyContent={'center'} alignItems={'center'}>
				<Box sx={{ display: 'flex' }} style={{ marginRight: '10px' }}>
					<CircularProgress size={size} color={`${color}`} />
				</Box>
				{showText && <span>{text}</span>}
			</MDBox>
		</>
	);
};

export default PXLoader;
