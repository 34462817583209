import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import MoreVert from '@mui/icons-material/MoreVert';
import MDBox from 'components/MDBox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Modal from '@mui/material/Modal';
import Timbrar from 'pantallas/Comprobantes/components/Timbrar/index';
import Email from 'pantallas/Comprobantes/components/Email/index';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PdfMakeOpenTest from './components/PDF/PdfMakeOpenTest';
import { descargarComprobante } from 'actions/Comprobante/comprobante';
import Cancelar from 'pantallas/Comprobantes/components/Cancelar';

function SplitButton({
	item,
	handleOpen,
	setComprobanteToUpdate,
	setComprobantesListData,
	isMyDataValid,
}: any) {
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(false);
	const [openModal, setOpenModal] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);
	const [xmlButton, setXmlButton] = useState('Ver XML');
	const [comprobante, setComprobante] = useState({ estatus: false });
	const [pantallaId, setPantallaId] = useState<number | null>(null);

	const verXML = (uuid: string): void => {
		setComprobante({ estatus: true });
		const comprobanteData = descargarComprobante(uuid);
		comprobanteData
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setComprobante({ estatus: false });
					const url = window.URL.createObjectURL(
						new Blob([respuesta.data], { type: 'text/xml' })
					);
					const link = document.createElement('a');
					link.href = url;
					link.target = '_blank';
					link.setAttribute('href', url);
					document.body.appendChild(link);
					link.click();
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					setComprobante({ estatus: false });
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					setComprobante({ estatus: false });
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 401) {
					setComprobante({ estatus: false });
					console.log('error401');
					console.log(error.response.data);
				} else if (error.response.status === 403) {
					setComprobante({ estatus: false });
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					setComprobante({ estatus: false });
					console.log('error404');
					console.log(error.response.data.message);
				} else {
					setComprobante({ estatus: false });
					console.log(error.response.data.message);
				}
			});
	};

	useEffect(() => {
		if (comprobante.estatus) setXmlButton('Cargando...');
		if (!comprobante.estatus) setXmlButton('Ver XML');
	}, [comprobante]);

	const cancelar = () => {
		if (isMyDataValid()) {
			setOpenModal(true); // abrimos el modal
			setOpen(false);
			setPantallaId(6);
		}
	};

	const handleCloseModal = () => setOpenModal(false);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};
	//Pantallas Modal
	const pantalla = () => {
		switch (pantallaId) {
			case 1: //Enviar por correo
				return (
					<Email
						closeModal={() => {
							handleCloseModal();
						}}
						item={item}
						setComprobantesListData={setComprobantesListData}
						tipoComprobante={1}
					/>
				);
			case 2: //Timbrar
				return (
					<Timbrar
						closeModal={() => {
							handleCloseModal();
						}}
						item={item}
						handleOpen={handleOpen}
						setComprobantesListData={setComprobantesListData}
						tipoComprobante={'A'}
					/>
				);
			case 6: // Cancelar
				return (
					<Cancelar
						closeModal={() => {
							handleCloseModal();
						}}
						item={item}
						setComprobantesListData={setComprobantesListData}
						tipoComprobante={'A'}
					/>
				);

			default:
				break;
		}
	};
	const descargarComprobantes = () => {
		setOpen(false);
		navigate(`/comprobante/descarga/${item.uuid}`);
	};

	const openAccion = (pantallaId: number): void => {
		if ([3, 4].includes(pantallaId)) {
			// handleToggle();
			switch (pantallaId) {
				case 3: // Descargar XML
					verXML(item.uuid);
					break;
				case 4: // Descargar PDF
					handleToggle();
					navigate(`/comprobante/pdf/${item.uuid}`);
					break;

				default:
					break;
			}
		} else if (pantallaId === 0) {
			//Editar
			setComprobanteToUpdate(item);
			handleToggle();
			handleOpen();
		} else {
			setPantallaId(pantallaId);
			setOpenModal(true);
			handleToggle();
		}
	};

	return (
		<React.Fragment>
			<ButtonGroup variant='text' ref={anchorRef} aria-label='split button'>
				<Button
					size='small'
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-label='select merge strategy'
					aria-haspopup='menu'
					onClick={handleToggle}
					style={{ backgroundColor: '#00A3BE' }}
				>
					<MoreVert style={{ color: 'white' }} />
				</Button>
			</ButtonGroup>

			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				style={{ zIndex: '3000' }}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								{item.timbrado ? (
									<MenuList id='split-button-menu' autoFocusItem>
										{item.timbrado &&
											item.estatus_comprobante_id !== 1 &&
											item.estatus_comprobante_id !== 5 && (
												<MenuItem onClick={() => openAccion(1)}>Enviar correo</MenuItem>
											)}
										<MenuItem
											onClick={() => openAccion(3)}
											disabled={comprobante.estatus}
										>
											{xmlButton}
										</MenuItem>
										<PdfMakeOpenTest
											uuid={item.uuid}
											tipo={'abrir'}
											isMyDataValid={isMyDataValid}
											id={item.id}
										/>
										<MenuItem onClick={descargarComprobantes}>
											Descargar XML & PDF
										</MenuItem>
										{item.timbrado &&
											item.estatus_comprobante_id !== 1 &&
											item.estatus_comprobante_id !== 5 && (
												<MenuItem onClick={cancelar}>Cancelar comprobante</MenuItem>
											)}
									</MenuList>
								) : (
									<MenuList id='split-button-menu' autoFocusItem>
										<MenuItem onClick={() => openAccion(0)}>Editar</MenuItem>
										<MenuItem onClick={() => openAccion(2)}>Timbrar</MenuItem>
										<PdfMakeOpenTest
											uuid={item.uuid}
											tipo={'abrir'}
											isMyDataValid={isMyDataValid}
											id={item.id}
										/>
									</MenuList>
								)}
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
			<Modal
				open={openModal}
				onClose={handleCloseModal}
				style={{
					maxHeight: '750px',
					maxWidth: '1350px',
					alignItems: 'center',
					margin: 'auto',
					justifyContent: 'center',
				}}
			>
				<MDBox>{pantalla()}</MDBox>
			</Modal>
		</React.Fragment>
	);
}

export default SplitButton;
