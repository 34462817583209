// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
// import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';

// @mui material components
//return <AgregarCliente />;
import './seleccionComprobante.css';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

function Comprobantes(props: any): JSX.Element {
	const seleccionaComprobante = (seleccion: string) => {
		props.siguientePantalla(seleccion);
	};

	const [botonFacturaDisabled, setBotonFacturaDisabled] = useState(false);
	const [botonRHDisabled, setBotonRHDisabled] = useState(false);

	useEffect(() => {
		if (props.soyPersonaFisica === 'Persona Fisica') {
			if (
				props.empresaData.regimen_fiscal === '612' ||
				props.empresaData.regimen_fiscal === '626'
			) {
				setBotonRHDisabled(false);
				setBotonFacturaDisabled(false);
			} else {
				setBotonRHDisabled(false);
				setBotonFacturaDisabled(true);
			}
		} else if (props.soyPersonaFisica === 'Persona Moral') {
			setBotonRHDisabled(true);
			setBotonFacturaDisabled(false);
		}
	}, [props.soyPersonaFisica, props.empresaData]);

	return (
		<MDBox
			display={'flex'}
			flexDirection={'column'}
			alignItems={'center'}
			sx={{ padding: '1rem', marginBottom: '2rem' }}
		>
			<Grid
				container
				justifyContent='center'
				spacing={1}
				style={{
					marginTop: '1rem',
					marginBottom: '2rem',
					width: '80%',
					placeItems: 'center',
				}}
			>
				<Grid item>
					<MDButton
						onClick={() => {
							seleccionaComprobante('Factura');
						}}
						style={{ backgroundColor: 'transparent' }}
						disabled={botonFacturaDisabled}
					>
						<Card
							sx={{
								display: 'flex',
								width: 190,
								height: 200,
								alignItems: 'center',
								margin: 'auto',
								justifyContent: 'center',
								textAlign: 'center',
							}}
							className='customButonComprobante'
						>
							<Box>
								<RequestQuoteOutlinedIcon
									style={{ color: '#00ACC8', height: '70px', width: '70px' }}
								/>
							</Box>

							<MDTypography
								variant='h5'
								fontWeight='medium'
								color='primary'
								align='center'
							>
								Factura
							</MDTypography>
						</Card>
					</MDButton>
				</Grid>
				<Grid item>
					<MDButton
						onClick={() => {
							seleccionaComprobante('Recibo de honorarios');
						}}
						style={{ backgroundColor: 'transparent' }}
						disabled={botonRHDisabled}
					>
						<Card
							sx={{
								width: 190,
								height: 200,
								alignItems: 'center',
								margin: 'auto',
								justifyContent: 'center',
								textAlign: 'center',
							}}
							className='customButonComprobante'
						>
							<Box>
								<VolunteerActivismOutlinedIcon
									style={{ color: '#00ACC8', height: '70px', width: '70px' }}
								/>
							</Box>
							<MDTypography
								variant='h5'
								fontWeight='medium'
								color='primary'
								align='center'
							>
								Recibo de Honorarios
							</MDTypography>
						</Card>
					</MDButton>
				</Grid>
				<Grid item>
					<MDButton
						onClick={() => {
							seleccionaComprobante('Nota de credito');
						}}
						style={{ backgroundColor: 'transparent' }}
						disabled
					>
						<Card
							sx={{
								width: 190,
								height: 200,
								alignItems: 'center',
								margin: 'auto',
								justifyContent: 'center',
								textAlign: 'center',
							}}
							className='customButonComprobante'
						>
							<Box>
								<PaymentsOutlinedIcon
									style={{ color: '#00ACC8', height: '70px', width: '70px' }}
								/>
							</Box>
							<MDTypography
								variant='h5'
								fontWeight='medium'
								color='primary'
								align='center'
							>
								Nota de
							</MDTypography>
							<MDTypography
								variant='h5'
								fontWeight='medium'
								color='primary'
								align='center'
							>
								credito
							</MDTypography>
						</Card>
					</MDButton>
				</Grid>
				<Grid item>
					<MDButton
						onClick={() => {
							seleccionaComprobante('Recibo de arrendamiento');
						}}
						style={{ backgroundColor: 'transparent' }}
						disabled
					>
						<Card
							sx={{
								width: 190,
								height: 200,
								alignItems: 'center',
								margin: 'auto',
								justifyContent: 'center',
								textAlign: 'center',
							}}
							className='customButonComprobante'
						>
							<Box>
								<ReceiptLongOutlinedIcon
									style={{ color: '#00ACC8', height: '70px', width: '70px' }}
								/>
							</Box>
							<MDTypography
								variant='h5'
								fontWeight='medium'
								color='primary'
								align='center'
							>
								Recibo de arrendamiento
							</MDTypography>
						</Card>
					</MDButton>
				</Grid>
				<Grid item>
					<MDButton
						onClick={() => {
							seleccionaComprobante('Nota de cargo');
						}}
						style={{ backgroundColor: 'transparent' }}
						disabled
					>
						<Card
							sx={{
								width: 190,
								height: 200,
								alignItems: 'center',
								margin: 'auto',
								justifyContent: 'center',
								textAlign: 'center',
							}}
							className='customButonComprobante'
						>
							<Box>
								<PostAddOutlinedIcon
									style={{ color: '#00ACC8', height: '70px', width: '70px' }}
								/>
							</Box>
							<MDTypography
								variant='h5'
								fontWeight='medium'
								color='primary'
								align='center'
							>
								Nota de
							</MDTypography>
							<MDTypography
								variant='h5'
								fontWeight='medium'
								color='primary'
								align='center'
							>
								cargo
							</MDTypography>
						</Card>
					</MDButton>
				</Grid>
			</Grid>

			<MDBox
				style={{
					alignItems: 'center',
					margin: 'auto',
					justifyContent: 'center',
					textAlign: 'center',
					marginTop: '2rem',
					marginBottom: '-6rem',
				}}
			></MDBox>
		</MDBox>
	);
}

export default Comprobantes;
