import { useState, useEffect } from 'react';

// react-router components
import { useLocation, Outlet } from 'react-router-dom';

// @mui material components
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React TS exampless
import Sidenav from 'examples/Sidenav';
import Configurator from 'examples/Configurator';

import routes from 'routes';
import {
	useMaterialUIController,
	setMiniSidenav,
	setOpenConfigurator,
} from 'context';
import brandPixcua from 'assets/images/pixcua.png';

const Pages = () => {
	const [controller, dispatch] = useMaterialUIController();
	const { miniSidenav, direction, layout, openConfigurator, sidenavColor } =
		controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const { pathname } = useLocation();

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};
	// Change the openConfigurator state
	const handleConfiguratorOpen = () =>
		setOpenConfigurator(dispatch, !openConfigurator);

	// Setting the dir attribute for the body element
	useEffect(() => {
		document.body.setAttribute('dir', direction);
	}, [direction]);

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	const configsButton = (
		<MDBox
			display='flex'
			justifyContent='center'
			alignItems='center'
			width='2.2rem'
			height='2.2rem'
			bgColor='white'
			shadow='sm'
			borderRadius='50%'
			position='fixed'
			right='1rem'
			bottom='5.3rem'
			zIndex={99}
			color='dark'
			sx={{ cursor: 'pointer' }}
			onClick={handleConfiguratorOpen}
		>
			<Icon fontSize='small' color='inherit'>
				settings
			</Icon>
		</MDBox>
	);
	return (
		<>
			{layout === 'dashboard' && (
				<>
					<Sidenav
						color={sidenavColor}
						brand={brandPixcua}
						brandName='Hector ruben martinez Dorantes sv. de c.v'
						routes={routes}
						onMouseEnter={handleOnMouseEnter}
						onMouseLeave={handleOnMouseLeave}
					/>
					<Configurator />
					{configsButton}
				</>
			)}
			{layout === 'vr' && <Configurator />}
			<Outlet />
		</>
	);
};

export default Pages;
