import {
	Box,
	CircularProgress,
	Grid,
	Typography,
	NativeSelect,
} from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import { ToastContainer, toast } from 'react-toastify';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { patchComprobante } from 'actions/Comprobante/comprobante';
import { item } from 'examples/Sidenav/styles/sidenavItem';
import MDBox from 'components/MDBox';
import { useState } from 'react';
import FormField from 'pantallas/MiCuenta/components/FormField';

const Cancelar = (props: any) => {
	const dispatch = useDispatch();

	const { loading } = useSelector((state: RootStore) => state.loading);
	const [formValue, setFormValue] = useState({
		idCancelacion: '',
		uuidRelacionado: '',
	});
	const handleInputChange = ({ target }: any) => {
		setFormValue({
			...formValue,
			[target.name]: target.value,
		});
	};

	const cancelar = () => {
		//comunicacion con el servicio
		if (formValue.idCancelacion) {
			if (formValue.idCancelacion === '01') {
				if (formValue.uuidRelacionado) {
					dispatch<any>(
						patchComprobante(
							{
								estatus_comprobante_id: 5,
								motivoCancelacion: formValue.idCancelacion,
								folioUUIDSustitucion: formValue.uuidRelacionado,
							},
							props.item.id,
							props.closeModal,
							props.tipoComprobante,
							props.setComprobantesListData
						)
					);
				} else {
					toast.error(`Debes incluir el UUID`);
				}
			} else {
				dispatch<any>(
					patchComprobante(
						{ estatus_comprobante_id: 5, motivoCancelacion: formValue.idCancelacion },
						props.item.id,
						props.closeModal,
						props.tipoComprobante,
						props.setComprobantesListData
					)
				);
			}
		} else {
			toast.error(`Selecciona una opcion de cancelación`);
		}
	};

	return (
		<>
			<Box sx={style}>
				<MDAvatar
					bgColor='primary'
					variant='circular'
					style={{
						alignItems: 'center',
						margin: 'auto',
						justifyContent: 'center',
						textAlign: 'center',
						fontSize: 100,
						width: 128,
						height: 128,
					}}
				>
					<HelpOutlineIcon />
				</MDAvatar>
				<Typography
					id='modal-modal-description'
					sx={{ mt: 2 }}
					style={{
						alignItems: 'center',
						// margin: '1rem',
						justifyContent: 'center',
						textAlign: 'center',
					}}
				>
					¿Estas seguro de solicitar la cancelación del comprobante?
				</Typography>
				<Typography
					id='modal-modal-description'
					style={{
						alignItems: 'center',
						justifyContent: 'center',
						textAlign: 'center',
					}}
					mb={2}
				>
					Por favor de seleccionar el motivo de cancelación
				</Typography>
				<MDBox display={'flex'} justifyContent={'center'}>
					<NativeSelect
						type='text'
						inputProps={{
							name: 'idCancelacion',
							id: 'cancelacionType',
						}}
						value={formValue.idCancelacion}
						onChange={handleInputChange}
						style={{ width: '50%' }}
						required
					>
						<option value={''}>Seleccione una opción</option>
						<option value={'01'}>
							Comprobantes emitidos con errores con relación
						</option>
						<option value={'02'}>
							Comprobantes emitidos con errores sin relación
						</option>
						<option value={'03'}>No se llevó a cabo la operación</option>
						<option value={'04'}>
							Operación nominativa relacionada en una factura global
						</option>
					</NativeSelect>
				</MDBox>
				{formValue.idCancelacion === '01' && (
					<MDBox display={'flex'} mt={2} justifyContent={'center'}>
						<FormField
							label='UUID relacionado:'
							placeholder='UUID relacionado'
							value={formValue.uuidRelacionado ?? ''}
							onChange={handleInputChange}
							name='uuidRelacionado'
							type='text'
							style={{ width: '50%' }}
						/>
					</MDBox>
				)}
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='fcenter'
					sx={{ marginTop: '2rem', marginBottom: '2rem' }}
				>
					{loading ? (
						<>
							<MDButton size='small' color='primary' sx={{ margin: '1rem' }} disabled>
								<CircularProgress color='secondary' size={15} />
								<span style={{ marginLeft: '8px' }}>Cargando...</span>
							</MDButton>
							<MDButton
								size='small'
								color='light'
								sx={{ margin: '1rem' }}
								onClick={() => {
									props.closeModal();
								}}
							>
								Cancelar
							</MDButton>
						</>
					) : (
						<>
							<MDButton
								size='small'
								color='primary'
								sx={{ margin: '1rem' }}
								onClick={cancelar}
							>
								SI
							</MDButton>
							<MDButton
								size='small'
								color='light'
								sx={{ margin: '1rem' }}
								onClick={() => {
									props.closeModal();
								}}
							>
								NO
							</MDButton>
						</>
					)}
				</Grid>
			</Box>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 800,
	bgcolor: 'background.paper',
	border: '2px',
	boxShadow: 24,
	p: 4,
};

export default Cancelar;
