import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const RequireSuscriptionActive = () => {
	const [suscriptionState, setSuscriptionState] = useState(true);
	const pxcSuscripcionActiva = localStorage.getItem('pxcCntAct') || '';

	useEffect(() => {
		if (pxcSuscripcionActiva === 'true') {
			setSuscriptionState(true);
		} else {
			setSuscriptionState(false);
		}
	}, []);

	if (!suscriptionState) return <Navigate to='/dashboard' />;

	return <Outlet />;
};
