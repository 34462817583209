/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cleanIsRegister, startLogin } from 'actions/Auth/auth';
import { useForm } from 'hooks/useForm';
import { iLoginData } from 'actions/Auth/authTypes';
import validator from 'validator';

// react-router-dom components
import { Link } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// @mui icons
import brandPixcua from 'assets/images/pixcua.png';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Authentication layout components
import CoverLayout from '../components/BasicLayout';

// Images
import bgImage from 'assets/images/pixcua-auth-background.jpg';
import MDAvatar from 'components/MDAvatar';

function Basic(): JSX.Element {
	const dispatch = useDispatch();
	const [errorMsg, setError] = useState<string>('');
	const loginData: iLoginData = {
		email: '',
		password: '',
	};
	//Uso de hook useForm para manejo de campos en el formulario
	const [formValues, handleInputChange] = useForm(loginData);
	const { email, password } = formValues;

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (isFormValid()) {
			dispatch<any>(startLogin({ email, password }));
		}
	};
	const isFormValid = (): boolean => {
		if (!validator.isEmail(email)) {
			setError('El campo email es incorrecto');
			return false;
		} else if (password.trim().length < 6) {
			setError('El password debe tener al menos 6 caracteres');
			return false;
		}

		setError('');
		return true;
	};

	useEffect(() => {
		dispatch<any>(cleanIsRegister());
	}, [dispatch]);

	return (
		<CoverLayout image={bgImage}>
			<Grid container justifyContent='center' alignItems='center'>
				<MDAvatar
					variant='square'
					style={{ marginTop: '1rem', width: '9rem' }}
					src={brandPixcua}
					alt='Avatar'
				/>
			</Grid>
			<Card>
				<MDBox
					variant='gradient'
					bgColor='primary'
					borderRadius='lg'
					coloredShadow='info'
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign='center'
				>
					<MDTypography variant='h4' fontWeight='medium' color='white' mt={1} mb={1}>
						Inicia sesión
					</MDTypography>
				</MDBox>
				<MDBox pt={5} pb={3} px={3}>
					<MDBox component='form' role='form' onSubmit={handleSubmit}>
						<MDBox mb={2}>
							<MDInput
								type='email'
								label='Correo Electrónico o Usuario'
								fullWidth
								name='email'
								value={email}
								onChange={handleInputChange}
							/>
						</MDBox>
						<MDBox mb={2}>
							<MDInput
								type='password'
								label='Contraseña'
								fullWidth
								name='password'
								value={password}
								onChange={handleInputChange}
							/>
						</MDBox>
						<MDBox mb={2}>
							{errorMsg && (
								<MDTypography
									variant='text'
									color='error'
									fontWeight='light'
									textGradient
									style={{ fontSize: '12px' }}
								>
									{errorMsg}.
								</MDTypography>
							)}
						</MDBox>
						<MDBox mt={4} mb={1}>
							<MDButton variant='gradient' color='primary' fullWidth type='submit'>
								Entrar
							</MDButton>
						</MDBox>
						<MDBox
							mt={3}
							mb={1}
							textAlign='center'
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<MDTypography variant='button' color='text'>
								¿Eres nuevo en Pixcua?{' '}
								<MDTypography
									component={Link}
									to='/signup'
									variant='button'
									color='primary'
									fontWeight='medium'
									textGradient
								>
									Crear una cuenta
								</MDTypography>
							</MDTypography>
							<MDTypography
								component={Link}
								to='/resetPassword'
								variant='button'
								color='secondary'
								fontWeight='medium'
								textGradient
							>
								¿Olvidaste tu contraseña?
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		</CoverLayout>
	);
}

export default Basic;
