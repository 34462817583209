import { Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

const NoActivo = () => {
	return (
		<>
			<MDBox display={'flex'} alignItems='center'>
				<UnpublishedIcon color='warning' fontSize='medium' />
				<Typography
					id='modal-modal-title'
					color={'secondary'}
					component='p'
					style={{ fontSize: '14px', marginLeft: '5px' }}
				>
					No activo
				</Typography>
			</MDBox>
		</>
	);
};

export default NoActivo;
