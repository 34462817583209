import { Dispatch } from 'redux';
import { axiosClient, getToken } from '../../Config/axiosConfig';
import {
	AuthDispatchTypes,
	AUTH_LOADING_FINISH,
	AUTH_LOGOUT,
	AUTH_START_LOADING,
	AUTH_SUCCESS,
	iLoginData,
	iNuevoUsuario,
	REGISTERING_FALSE,
	REGISTERING_OK,
	REGISTER_LOADING_END,
	REGISTER_START_LOADING,
} from './authTypes';
import Swal from 'sweetalert2';
import moment from 'moment';

//Definicion Controller
export const getPlanByAlias = async (alias: string | number) => {
	return await axiosClient.get(`planes/${alias}`);
};

export const postNuevoUsuario = (usuario: iNuevoUsuario) => {
	return async (dispatch: Dispatch<AuthDispatchTypes>) => {
		dispatch({ type: REGISTER_START_LOADING });

		axiosClient
			.post(`usuarios/`, usuario)
			.then((respuesta) => {
				if (respuesta.status === 201) {
					console.log(respuesta.data);
					dispatch({ type: REGISTER_LOADING_END });
					Swal.fire({
						position: 'top-end',
						icon: 'success',
						title: `¡Registro exitoso!`,
						showConfirmButton: false,
						timer: 2000,
					});
					setTimeout(() => {
						dispatch({ type: REGISTERING_OK });
					}, 2000);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					dispatch({ type: REGISTER_LOADING_END });
					console.log(error);
					console.log(error.response.data.msg);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 400) {
					dispatch({ type: REGISTER_LOADING_END });
					console.log('error400');
					console.log(error);
					Swal.fire({
						width: '35rem',
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 403) {
					dispatch({ type: REGISTER_LOADING_END });
					console.log('error403');
					console.log(error);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 404) {
					dispatch({ type: REGISTER_LOADING_END });
					console.log('error404');
					console.log(error);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else {
					dispatch({ type: REGISTER_LOADING_END });
					console.log(error);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				}
			});
	};
};

export const cleanIsRegister = () => {
	return async (dispatch: Dispatch<AuthDispatchTypes>) => {
		dispatch({ type: REGISTERING_FALSE });
	};
};

export const startLogin = (loginData: iLoginData) => {
	return async (dispatch: Dispatch<AuthDispatchTypes>) => {
		dispatch({ type: AUTH_START_LOADING });

		axiosClient
			.post(`auth/`, loginData)
			.then((respuesta) => {
				if (respuesta.status === 200) {
					dispatch({ type: AUTH_LOADING_FINISH });
					localStorage.setItem('pxc-tkn', respuesta.headers.token);
					//se obtiene fecha actual
					const time = moment().format('YYYY-MM-DD HH:mm:ss');
					//Se guarda el tiempo en el que se guardo el token en localStorage
					localStorage.setItem('pxc-tkn-init-date', time);
					localStorage.setItem('pxcUser', respuesta.data.data.email);
					localStorage.setItem('pxcCntAct', respuesta.data.suscripcionActiva);

					dispatch({
						type: AUTH_SUCCESS,
						payload: { usuario: respuesta.data.data },
					});
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					dispatch({ type: AUTH_LOADING_FINISH });
					console.log(error);
					console.log(error.response.data.msg);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 400) {
					dispatch({ type: AUTH_LOADING_FINISH });
					console.log('error400');
					console.log(error);
					Swal.fire({
						width: '35rem',
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 404) {
					dispatch({ type: AUTH_LOADING_FINISH });
					console.log('error404');
					console.log(error);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else {
					dispatch({ type: AUTH_LOADING_FINISH });
					console.log(error);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				}
			});
	};
};
export const restartPassword = (email: {}) => {
	return async (dispatch: Dispatch<any>) => {
		axiosClient
			.post(`/usuarios/recuperacionContrasena/`, email)
			.then((respuesta) => {
				if (respuesta.status === 201) console.log(respuesta.data);
			})
			.catch((error) => console.log(error.response.data));
	};
};

//LogOut
export const logOut = () => ({
	type: AUTH_LOGOUT,
});

//RenovarToken
export const startChecking = () => {
	return async (dispatch: Dispatch<AuthDispatchTypes>) => {
		const token = localStorage.getItem('pxc-tkn') || '';
		axiosClient
			.get(`auth/renew`, {
				headers: {
					Authorization: `${token}`,
				},
			})
			.then((respuesta) => {
				if (respuesta.status === 201) {
					dispatch({ type: AUTH_LOADING_FINISH });
					localStorage.setItem('pxc-tkn', respuesta.headers.token);
					//se obtiene fecha actual
					const time = moment().format('YYYY-MM-DD HH:mm:ss');
					//Se guarda el tiempo en el que se guardo el token en localStorage
					localStorage.setItem('pxc-tkn-init-date', time);
					localStorage.setItem('pxcUser', respuesta.data.data.email);
					localStorage.setItem('pxcCntAct', respuesta.data.suscripcionActiva);

					dispatch({
						type: AUTH_SUCCESS,
						payload: { usuario: respuesta.data.data },
					});
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log(error);
					localStorage.setItem('pxc-tkn', '');
					localStorage.setItem('pxc-tkn-init-date', '');
					localStorage.setItem('pxcUser', '');
					localStorage.setItem('pxcCntAct', '');
					dispatch({ type: AUTH_LOGOUT });
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error);
					localStorage.setItem('pxc-tkn', '');
					localStorage.setItem('pxc-tkn-init-date', '');
					localStorage.setItem('pxcUser', '');
					localStorage.setItem('pxcCntAct', '');
					dispatch({ type: AUTH_LOGOUT });
				} else if (error.response.status === 401) {
					console.log('error401');
					console.log(error);
					localStorage.setItem('pxc-tkn', '');
					localStorage.setItem('pxc-tkn-init-date', '');
					localStorage.setItem('pxcUser', '');
					localStorage.setItem('pxcCntAct', '');
					dispatch({ type: AUTH_LOGOUT });
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error);
					localStorage.setItem('pxc-tkn', '');
					localStorage.setItem('pxc-tkn-init-date', '');
					localStorage.setItem('pxcUser', '');
					localStorage.setItem('pxcCntAct', '');
					dispatch({ type: AUTH_LOGOUT });
				} else {
					console.log(error);
					localStorage.setItem('pxc-tkn', '');
					localStorage.setItem('pxc-tkn-init-date', '');
					localStorage.setItem('pxcUser', '');
					localStorage.setItem('pxcCntAct', '');
					dispatch({ type: AUTH_LOGOUT });
				}
			});
	};
};

export const stripeCheckOut = async (data: any) => {
	await getToken();
	return await axiosClient.post(`suscripciones/checkoutStripe`, data);
};
