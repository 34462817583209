import { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
// @mui material components
import {
	Card,
	Grid,
	Modal,
	Typography,
	Box,
	NativeSelect,
	InputLabel,
	Tooltip,
	IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormField from 'layouts/pages/account/components/FormField';
// // Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'components/Tables/DataTable';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Data
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';

//Actions
import servCliente from '../../actions/Clientes/clientes';
import servFormPago from '../../actions/Catalogo/forma_Pago';
import servMetodoPago from '../../actions/Catalogo/metodo_pago';
import { cliente, initcliente } from '../../actions/Clientes/clientesTypes';
import { iMensaje } from '../../Utilidades/mensaje.interface';
import Swal from 'sweetalert2';
import { CSVLink } from 'react-csv';
import {
	getEntidades,
	getEntidadesByPais,
	getRegimenes,
} from 'actions/MiCuenta/miCuenta';
import {
	LoadingDispatchTypes,
	LOADING_END,
	START_LOADING,
} from 'actions/Loading/loadingTypes';
import { axiosClient, getToken } from 'Config/axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import PXLoader from 'components/PXLoader/PXLoader';
import { sortData } from 'helpers/sortData';

function Comprobantes(): JSX.Element {
	const dispatch = useDispatch();
	const { loading } = useSelector((state: RootStore) => state.loading);
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
		reset();
	};
	const handleClose = (event?: object, reason?: string) => {
		if (reason && reason == 'backdropClick') return;
		setOpen(false);
		reset();
	};
	const [dataTableData, setdataTableData] = useState({
		columns: [],
		rows: [],
	});
	const [modo, setModo] = useState(0);
	const [titulo, setTitulo] = useState('');
	const [colFormaPago, setcolFormaPago] = useState([]);
	const [colMetodoPago, setcolMetodoPago] = useState([]);
	const [isNombre, setIsNombre] = useState(false);
	const [isRFC, setIsRFC] = useState(false);
	const [isEmail, setIsEmail] = useState(false);
	const [isCP, setIsCP] = useState(false);
	const [isTipoContribuyente, setIsTipoContribuyente] = useState(false);
	const [isRegimenFiscal, setIsRegimenFiscal] = useState(false);
	const [isMetodoPago, setIsMetodoPago] = useState(false);
	const [isFormaPago, setIsFormaPago] = useState(false);
	const [isDisable, setIsDisable] = useState(false);
	const [formValue, setFormValue] = useState(initcliente);
	const [estadosList, setEstadosList] = useState([]);
	const [regimenes, setRegimenes] = useState([]);
	const reset = () => {
		setFormValue(initcliente);
	};

	const handleInputChange = ({ target }: any) => {
		setFormValue({
			...formValue,
			[target.name]: target.value,
		});
	};

	let columns: any = [
		{ Header: 'Razón Social', accessor: 'nombre', width: '25%', align: 'center' },
		{ Header: 'R.F.C.', accessor: 'rfc', width: '20%' },
		{ Header: 'Teléfono', accessor: 'telefono', width: '20%' },
		{ Header: 'Correo', accessor: 'email', width: '25%' },
		{ Header: 'Acciones', accessor: 'acciones', width: '10%', align: 'center' },
	];

	//Obtiene data
	useEffect(() => {
		getCombos();
		dispatch<any>(getDatos());

		//Get Regimenes
		const data = getRegimenes();
		data
			.then((respuesta) => {
				if (respuesta.status === 201) {
					setRegimenes(respuesta.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data.message);
				} else {
					console.log(error.response.data.message);
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		//Get Entidades
		let pais = '';
		if (formValue.pais === 'México') pais = 'MEX';
		else if (formValue.pais === 'USA') pais = 'USA';
		else if (formValue.pais === 'Canada') pais = 'CAN';

		const dataEntidades = getEntidadesByPais(pais);
		dataEntidades
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setEstadosList(respuesta.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response);
				} else {
					console.log(error.response);
				}
			});
	}, [formValue.pais]);

	//Obtener Clientes
	const getDatos = () => {
		return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
			await getToken();
			dispatch({ type: START_LOADING });
			axiosClient
				.get(`/Clientes`)
				.then((respuesta) => {
					const respuesta1 = respuesta.data;
					sortData(respuesta1, 'nombre', true);
					loadData(respuesta1);
					dispatch({ type: LOADING_END });
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500', error.response.data);
						dispatch({ type: LOADING_END });
					} else if (error.response.status === 400) {
						console.log('error400', error.response.data);
						dispatch({ type: LOADING_END });
					} else if (error.response.status === 403) {
						console.log('error403', error.response.data);
						dispatch({ type: LOADING_END });
					} else if (error.response.status === 404) {
						console.log('No se encontraron datos');
						setdataTableData({
							columns: [],
							rows: [{ obj: {}, acciones: {} }],
						});
						dispatch({ type: LOADING_END });
					} else {
						console.log(error.response.data);
						dispatch({ type: LOADING_END });
					}
				});
		};
	};
	const loadData = (datos: cliente[]) => {
		let rows: any = [];
		rows = datos.map((item) => {
			return {
				nombre: item.nombre,
				rfc: item.rfc,
				telefono: item.telefono,
				email: item.email ? item.email : '123',
				acciones: <IconsCell obj={item} />,
			};
		});
		setdataTableData({ columns, rows });
	};
	const getCombos = () => {
		servFormPago
			.get()
			.then((r) => {
				const colData = r.data.map(
					(item: { c_FormaPago: string; descripcion: string }) => {
						return {
							c_FormaPago: item.c_FormaPago,
							descripcion: item.descripcion,
						};
					}
				);
				setcolFormaPago(colData);
			})
			.catch((e) => console.error('Error cargar formas de pago', e));
		servMetodoPago
			.get()
			.then((r) => {
				const colData = r.data.map(
					(item: { c_MetodoPago: string; descripcion: string }) => {
						return {
							c_MetodoPago: item.c_MetodoPago,
							descripcion: item.descripcion,
						};
					}
				);
				setcolMetodoPago(colData);
			})
			.catch((e) => console.error('Error cargar metodos de pago', e));
	};

	const modoModal = (modo: number, obj: cliente) => {
		limpiamos();
		setModo(modo);
		switch (modo) {
			case 1: //Editar
				handleOpen();
				setTitulo('Actualizar Cliente');
				loadForm(obj);
				break;
			case 2: //Eliminar
				eliminarregistro(obj.id);
				break;
			case 3: //Detalle
				handleOpen();
				setTitulo('Detalle del Cliente');
				setIsDisable(true);
				loadForm(obj);
				break;
		}
	};

	const loadForm = (obj: cliente) => {
		try {
			setFormValue(obj);
		} catch (e) {
			console.log('Error al cargar datos al modal:', e);
		}
	};

	const eliminarregistro = (id: number) => {
		Swal.fire({
			icon: 'question',
			title: '¿Estas seguro de eliminar el siguiente registro?',
			showCancelButton: true,
			confirmButtonText: '!Eliminar!',
			confirmButtonColor: '#00ACC8',
		}).then((r) => {
			/* Read more about isConfirmed, isDenied below */
			if (r.isConfirmed) {
				servCliente
					.delete(id)
					.then((r) => procesamosResponse(r.data))
					.catch((e) => console.log(e));
			}
		});
	};

	const crear = () => {
		handleOpen();
		limpiamos();
		setModo(0);
		setTitulo('Crear Cliente');
	};

	const acciones = async () => {
		switch (modo) {
			case 0: //Crear
				await servCliente
					.post(formValue)
					.then((r) => {
						procesamosResponse(r.data);
					})
					.catch((e) => {
						procesamosResponse(e.response.data);
					});
				break;
			case 1: //Actualizar
				await servCliente
					.update(formValue.id, formValue)
					.then((r) => {
						procesamosResponse(r.data);
					})
					.catch((e) => {
						procesamosResponse(e.response.data);
					});
				break;
		}
	};

	const procesamosResponse = (resp: any) => {
		let r: iMensaje[] = resp.messages;
		r.forEach((e) => {
			switch (e.tipo) {
				case 1: //Mensaje Error
					toast.error(e.mensaje);
					break;
				case 2: //Atributos con error
					switch (e.mensaje) {
						case 'nombre':
							setIsNombre(true);
							break;
						case 'rfc':
							setIsRFC(true);
							break;
						case 'codigo_postal':
							setIsCP(true);
							break;
						case 'codigo_postal':
							setIsCP(true);
							break;
						case 'codigo_postal':
							setIsCP(true);
							break;
						case 'codigo_postal':
							setIsCP(true);
							break;
						case 'forma_de_pago':
							setIsCP(true);
							break;
					}
					console.log('Atributos', e.mensaje);
					break;
				case 3: //Exitoso
					switch (modo) {
						case 2:
							Swal.fire('Eliminado!', '', 'success');
							break;
						default:
							toast.success(e.mensaje);
							handleClose();
							limpiamos();
							break;
					}
					dispatch<any>(getDatos());
					console.log('cargando..data..clientes');
					break;
				case 4: //Informativo
					toast.info(e.mensaje);
					break;
				case 5: //Parametro
					break;
			}
		});
	};

	const limpiamos = () => {
		setIsEmail(false);
		setIsNombre(false);
		setIsRFC(false);
		setIsDisable(false);
	};

	const IconsCell = (item: { obj: cliente }) => {
		return (
			<MDBox display='flex' alignItems='center'>
				<MDBox mr={2}>
					<Tooltip title='Editar'>
						<IconButton color='warning' onClick={() => modoModal(1, item.obj)}>
							<EditIcon />
						</IconButton>
					</Tooltip>
				</MDBox>
				<MDBox mr={2}>
					<Tooltip title='Ver'>
						<IconButton color='info' onClick={() => modoModal(3, item.obj)}>
							<VisibilityIcon />
						</IconButton>
					</Tooltip>
				</MDBox>
				<MDBox mr={2}>
					<Tooltip title='Eliminar'>
						<IconButton color='error' onClick={() => modoModal(2, item.obj)}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</MDBox>
			</MDBox>
		);
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			{/* Tabla */}
			<MDBox pt={6} pb={3}>
				<Card>
					<MDBox p={3} lineHeight={1}>
						<MDTypography variant='h5' fontWeight='medium' color='primary'>
							Clientes
						</MDTypography>
						<Grid
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='flex-end'
							spacing={1}
						>
							<MDButton size='small' color='primary' onClick={() => crear()}>
								+ Agregar
							</MDButton>
							<CSVLink
								data={dataTableData.rows.map((d) => {
									return {
										nombre: d.nombre,
										rfc: d.rfc,
										telefono: d.telefono,
										email: d.email,
									};
								})}
								filename='Clientes.csv'
							>
								<MDButton size='small' sx={{ marginLeft: '1rem', border: '1px solid' }}>
									+ Exportar CSV
								</MDButton>
							</CSVLink>
						</Grid>
					</MDBox>

					<DataTable table={dataTableData} canSearch />
					{loading && (
						<>
							<MDBox m={4}>
								<PXLoader />
							</MDBox>
						</>
					)}
				</Card>
			</MDBox>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<Tooltip title='Cerrar'>
						<IconButton
							onClick={() => handleClose()}
							color='secondary'
							aria-label='upload picture'
							component='label'
							style={{
								position: 'absolute',
								top: '-10px',
								right: '-10px',
								backgroundColor: '#fff',
								border: '1px solid #00ACC8',
							}}
						>
							<CloseIcon />
						</IconButton>
					</Tooltip>
					<Typography id='modal-modal-title' variant='h5' component='h2' pb={1}>
						{titulo}
					</Typography>
					{!isDisable && (
						<>
							<MDBox>
								<MDTypography variant='body2'>
									Todos los campos con * son obligatorios
								</MDTypography>
							</MDBox>
							<MDBox
								mb={2}
								mt={1}
								style={{
									maxWidth: '680px',
									lineHeight: '18px',
									fontSize: '15px',
									textAlign: 'justify',
									backgroundColor: '#e1a90244',
									border: '2px solid #ffbf00',
									padding: '10px',
									borderRadius: '8px',
								}}
							>
								<MDTypography variant='captions' style={{ color: '#383838' }}>
									NOTA: Los datos ingresados en esta sección deberan coincidir
									exactamente con los registrados ante el SAT, respetando Mayúsculas,
									Minúsculas, Espacios, Acentos y Signos de puntuación.
								</MDTypography>
							</MDBox>
						</>
					)}
					<MDBox component='form' pb={3} px={3}>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={4}>
								<FormField
									label='Nombre o razón social*'
									placeholder='Nombre o razón social*'
									value={formValue.nombre}
									onChange={handleInputChange}
									name='nombre'
									type='text'
									error={isNombre}
									disabled={isDisable}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<FormField
									label='RFC*'
									placeholder='RFC*'
									value={formValue.rfc}
									onChange={handleInputChange}
									name='rfc'
									type='text'
									error={isRFC}
									disabled={isDisable}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<FormField
									label='C.P*'
									placeholder='C.P*'
									value={formValue.codigo_postal}
									onChange={handleInputChange}
									name='codigo_postal'
									type='text'
									error={isCP}
									disabled={isDisable}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<FormField
									label='Calle'
									placeholder='Calle'
									value={formValue.calle}
									onChange={handleInputChange}
									name='calle'
									type='text'
									disabled={isDisable}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<FormField
									label='# Exterior'
									placeholder='# Exterior'
									value={formValue.no_exterior}
									onChange={handleInputChange}
									name='no_exterior'
									type='text'
									disabled={isDisable}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<FormField
									label='# Interior'
									placeholder='# Interior'
									value={formValue.no_interior}
									onChange={handleInputChange}
									name='no_interior'
									type='text'
									disabled={isDisable}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<MDBox
									style={
										isDisable
											? { width: '100%', backgroundColor: '#EFF2F5' }
											: { width: '100%' }
									}
								>
									<InputLabel variant='standard' htmlFor='uncontrolled-native'>
										País
									</InputLabel>
									<NativeSelect
										disabled={isDisable}
										onChange={handleInputChange}
										value={formValue.pais}
										inputProps={{
											name: 'pais',
											id: 'uncontrolled-native',
										}}
										style={{ width: '100%' }}
										required
									>
										<option value={''}>Seleccione una opción</option>
										<option value={'México'}>México</option>
										<option value={'USA'}>USA</option>
										<option value={'Canada'}>Canada</option>
									</NativeSelect>
								</MDBox>
							</Grid>
							<Grid item xs={12} sm={4}>
								<MDBox
									style={
										isDisable
											? { width: '100%', backgroundColor: '#EFF2F5' }
											: { width: '100%' }
									}
								>
									<InputLabel variant='standard' htmlFor='uncontrolled-native'>
										Estado
									</InputLabel>
									<NativeSelect
										disabled={isDisable}
										onChange={handleInputChange}
										value={formValue.estado}
										inputProps={{
											name: 'estado',
											id: 'uncontrolled-native',
										}}
										style={{ width: '100%' }}
										required
									>
										<option value={''}>Seleccione una opción</option>
										{estadosList.map((item) => (
											<option key={item.id} value={item.nombre}>
												{item.nombre}
											</option>
										))}
									</NativeSelect>
								</MDBox>
							</Grid>
							<Grid item xs={12} sm={4}>
								<FormField
									label='Localidad'
									placeholder='Localidad'
									value={formValue.localidad}
									onChange={handleInputChange}
									name='localidad'
									type='text'
									disabled={isDisable}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<FormField
									label='Alcaldia/Municipio'
									placeholder='Alcaldia/Municipio'
									value={formValue.municipio}
									onChange={handleInputChange}
									name='municipio'
									type='text'
									disabled={isDisable}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<FormField
									label='Colonia'
									placeholder='Colonia'
									value={formValue.colonia}
									onChange={handleInputChange}
									name='colonia'
									type='text'
									disabled={isDisable}
								/>
							</Grid>

							<Grid item xs={12} sm={4}>
								<FormField
									label='Referencia'
									placeholder='Referencia:'
									value={formValue.referencia}
									onChange={handleInputChange}
									name='referencia'
									type='text'
									disabled={isDisable}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<FormField
									label='Teléfono'
									placeholder='Teléfono'
									inputProps={{ type: 'number' }}
									value={formValue.telefono}
									onChange={handleInputChange}
									name='telefono'
									type='text'
									disabled={isDisable}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<FormField
									label='email'
									placeholder='example@email.com'
									inputProps={{ type: 'email' }}
									value={formValue.email}
									onChange={handleInputChange}
									name='email'
									type='text'
									error={isEmail}
									disabled={isDisable}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<MDBox
									style={
										isDisable
											? { width: '100%', backgroundColor: '#EFF2F5' }
											: { width: '100%' }
									}
								>
									<InputLabel
										variant='standard'
										htmlFor='selectTipoContribuyenteWizardCliente'
									>
										Tipo Contribuyente*
									</InputLabel>
									<NativeSelect
										disabled={isDisable}
										onChange={handleInputChange}
										value={formValue.tipo_Persona}
										inputProps={{
											name: 'tipo_Persona',
											id: 'selectTipoContribuyenteWizardCliente',
										}}
										style={{ width: '100%' }}
										error={isTipoContribuyente}
										required
									>
										<option value={''}>Seleccione una opción*</option>
										<option value={'Persona Fisica'}>Persona Fisica</option>
										<option value={'Persona Moral'}>Persona Moral</option>
									</NativeSelect>
								</MDBox>
							</Grid>
							<Grid item xs={12} sm={6}>
								<MDBox
									style={
										isDisable
											? { width: '100%', backgroundColor: '#EFF2F5' }
											: { width: '100%' }
									}
								>
									<InputLabel variant='standard' htmlFor='selectRegimenWizardCliente'>
										Régimen Fiscal*
									</InputLabel>
									<NativeSelect
										disabled={isDisable}
										onChange={handleInputChange}
										value={formValue.c_regimen_fiscal_Cliente}
										inputProps={{
											name: 'c_regimen_fiscal_Cliente',
											id: 'selectRegimenWizardCliente',
										}}
										style={{ width: '100%' }}
										error={isRegimenFiscal}
										required
									>
										<option value={''}>Seleccione una opción*</option>
										{regimenes.map((item) => (
											<option key={item.id} value={item.c_RegimenFiscal}>
												{`${item.c_RegimenFiscal} - ${item.descripcion}`}
											</option>
										))}
									</NativeSelect>
								</MDBox>
							</Grid>
							<Grid item xs={12} sm={6}>
								<MDBox
									style={
										isDisable
											? { width: '100%', backgroundColor: '#EFF2F5' }
											: { width: '100%' }
									}
								>
									<InputLabel variant='standard' htmlFor='uncontrolled-native'>
										Método de Pago*
									</InputLabel>
									<NativeSelect
										disabled={isDisable}
										onChange={handleInputChange}
										value={formValue.metodo_de_pago}
										inputProps={{
											name: 'metodo_de_pago',
											id: 'uncontrolled-native',
										}}
										style={{ width: '100%' }}
										error={isMetodoPago}
										required
									>
										<option value={''}>Seleccione una opción*</option>
										{colMetodoPago.map((item) => (
											<option key={item.c_MetodoPago} value={item.c_MetodoPago}>
												{`${item.c_MetodoPago} - ${item.descripcion}`}
											</option>
										))}
									</NativeSelect>
								</MDBox>
							</Grid>
							<Grid item xs={12} sm={6}>
								<MDBox
									style={
										isDisable
											? { width: '100%', backgroundColor: '#EFF2F5' }
											: { width: '100%' }
									}
								>
									<InputLabel variant='standard' htmlFor='uncontrolled-native'>
										Forma de Pago*
									</InputLabel>
									<NativeSelect
										disabled={isDisable}
										onChange={handleInputChange}
										value={formValue.forma_de_pago}
										inputProps={{
											name: 'forma_de_pago',
											id: 'uncontrolled-native',
										}}
										style={{ width: '100%' }}
										error={isFormaPago}
										required
									>
										<option value={''}>Seleccione una opción*</option>
										{colFormaPago.map((item) => (
											<option key={item.c_FormaPago} value={item.c_FormaPago}>
												{`${item.c_FormaPago} - ${item.descripcion}`}
											</option>
										))}
									</NativeSelect>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
					{isDisable ? (
						<Grid
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='flex-end'
							spacing={1}
						>
							<MDButton size='small' color='primary' onClick={handleClose}>
								Cerrar
							</MDButton>
						</Grid>
					) : (
						<Grid
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='flex-end'
							spacing={1}
						>
							<MDButton size='small' onClick={handleClose}>
								Cancelar
							</MDButton>
							<MDButton size='small' color='primary' onClick={acciones}>
								Guardar
							</MDButton>
						</Grid>
					)}
				</Box>
			</Modal>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</DashboardLayout>
	);
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 900,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	toast: {
		position: 'absolute' as 'absolute',
		top: 0,
		right: 0,
	},
	toastH: {
		position: 'relative' as 'relative',
	},
};

export default Comprobantes;
