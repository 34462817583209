import { Dispatch } from 'redux';
import { axiosClient, getToken } from 'Config/axiosConfig';
import { iComprobante } from './comprobanteTypes';
import {
	LoadingDispatchTypes,
	LOADING_END,
	LOADING_OK,
	START_LOADING,
} from 'actions/Loading/loadingTypes';
import { toast } from 'react-toastify';
import axios from 'axios';

export const getComprobantes = async () => {
	await getToken();
	return await axiosClient.get(`/comprobantes`);
};
export const postComprobante = async (data: iComprobante) => {
	await getToken();
	return await axiosClient.post(`/comprobantes`, data);
};
export const envioComprobante = async (data: {
	uuId: string;
	email: string;
	cc: boolean;
}) => {
	await getToken();
	return await axiosClient.post(`/envioComprobante`, data);
};
export const putComprobante = async (data: any, comprobanteId: number) => {
	await getToken();
	return await axiosClient.put(`/comprobantes/${comprobanteId}`, data);
};

export const getComprobantesById = async (comprobanteId: number | string) => {
	await getToken();
	return await axiosClient.get(`/comprobantes/${comprobanteId}`);
};
export const getDocRelacionadoByComprobanteId = async (
	comprobanteId: number | string
) => {
	await getToken();
	return await axiosClient.get(`/tipo_relaciones_comprobantes/${comprobanteId}`);
};
export const descargarComprobante = async (uuId: string) => {
	console.log('RecibiendoUUID:::', uuId);
	await getToken();
	return await axiosClient.get(`/comprobantes/${uuId}/xml`);
};

export const timbrarComprobante = (
	uuid: string,
	closeModal: any,
	setComprobantesListData: any,
	tipoComprobante?: any
) => {
	return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
		const token = localStorage.getItem('pxc-tkn') || '';
		dispatch({ type: START_LOADING });

		axiosClient
			.get(`/comprobantes/${uuid}/xml`, {
				headers: {
					Authorization: `${token}`,
				},
			})
			.then((respuesta) => {
				console.log('respuestaTIMBRAR::', respuesta);
				if (respuesta.status === 200) {
					dispatch({ type: LOADING_END });
					console.log(respuesta);

					toast.success(`${respuesta.data.message}`);
					setTimeout(() => {
						dispatch({ type: LOADING_OK });
						closeModal(); //Cerrar modal
					}, 2000);
					if (tipoComprobante === 'A') {
						const getComprobantesAsync = () => {
							return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
								await getToken();
								dispatch({ type: START_LOADING });
								axiosClient
									.get(`/comprobantes`)
									.then((respuesta) => {
										setComprobantesListData(respuesta.data);
										dispatch({ type: LOADING_END });
									})
									.catch((error) => {
										if (error.response.status === 500) {
											console.log('error500', error.response.data);
											dispatch({ type: LOADING_END });
										} else if (error.response.status === 400) {
											console.log('error400', error.response.data);
											dispatch({ type: LOADING_END });
										} else if (error.response.status === 403) {
											console.log('error403', error.response.data);
											dispatch({ type: LOADING_END });
										} else if (error.response.status === 404) {
											console.log('error404', error.response.data);
											setComprobantesListData([]);
											dispatch({ type: LOADING_END });
										} else {
											console.log(error.response.data);
											dispatch({ type: LOADING_END });
										}
									});
							};
						};
						//Get Comprobantes serie A
						dispatch<any>(getComprobantesAsync());
					} else if (tipoComprobante === 'CP') {
						dispatch<any>(getComprobantesCP(setComprobantesListData));
					}
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					dispatch({ type: LOADING_END });
					console.log(error.response);
					toast.error(`${error.response.data.message}`);
					setTimeout(() => {
						closeModal(); //Cerrar modal
					}, 2000);
				} else if (error.response.status === 400) {
					dispatch({ type: LOADING_END });
					console.log('error400');
					console.log(error);
					toast.error(`${error.response.data.message}`);
				} else if (error.response.status === 401) {
					dispatch({ type: LOADING_END });
					console.log('error401');
					console.log(error);
					toast.error(`${error.response.data.message}`);
				} else if (error.response.status === 402) {
					dispatch({ type: LOADING_END });
					console.log('error402');
					console.log(error);
					toast.error(`Tus timbres se han agotado.`);
					localStorage.setItem('pxcCntAct', 'false');
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else if (error.response.status === 403) {
					dispatch({ type: LOADING_END });
					console.log('error403');
					console.log(error);
					toast.error(`${error.response.data.message}`);
				} else if (error.response.status === 404) {
					dispatch({ type: LOADING_END });
					console.log('error404');
					console.log(error);
					toast.error(`${error.response.data.message}`);
				} else {
					dispatch({ type: LOADING_END });
					console.log(error);
					toast.error(`${error.message}`);
					setTimeout(() => {
						closeModal(); //Cerrar modal
					}, 2000);
				}
			});
	};
};

export const downloadXmlFromServer = (uuId: string) => {
	console.log('descargandoXml');
	try {
		axios({
			url: `/comprobantes/${uuId}/xml`,
			method: 'GET',
			responseType: 'blob',
		})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'plantilla.xml');
				document.body.appendChild(link);
				link.click();
			})
			.catch(function (error) {
				console.log(error);
			});
	} catch (error) {
		console.log(error);
	}
};

export const getDocumentoRelacionadoById = async (
	documento_id: number | string,
	cliente_id?: number | string
) => {
	await getToken();
	return await axiosClient.get(
		`/complementoPagos?busqueda=${documento_id}&cliente=${cliente_id}`
	);
};
export const postPostComplementoDePago = async (data: {}) => {
	await getToken();
	console.log('enviandoCP', data);
	return await axiosClient.post(`/comprobantes/`, data);
};

export const getComprobantesCP = (setDatos: any) => {
	return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
		await getToken();
		dispatch({ type: START_LOADING });
		axiosClient
			.get(`/comprobantes/?tipo_comprobante_id=7`)
			.then((respuesta) => {
				setDatos(respuesta.data);
				dispatch({ type: LOADING_END });
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500', error.response.data);
					dispatch({ type: LOADING_END });
				} else if (error.response.status === 400) {
					console.log('error400', error.response.data);
					dispatch({ type: LOADING_END });
				} else if (error.response.status === 403) {
					console.log('error403', error.response.data);
					dispatch({ type: LOADING_END });
				} else if (error.response.status === 404) {
					console.log('error404', error.response.data);
					setDatos([]);
					dispatch({ type: LOADING_END });
				} else {
					console.log(error.response.data);
					dispatch({ type: LOADING_END });
				}
			});
	};
};
export const getComprobantesA = (setDatos: any) => {
	return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
		await getToken();
		dispatch({ type: START_LOADING });
		axiosClient
			.get(`/comprobantes/`)
			.then((respuesta) => {
				setDatos(respuesta.data);
				dispatch({ type: LOADING_END });
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500', error.response.data);
					dispatch({ type: LOADING_END });
				} else if (error.response.status === 400) {
					console.log('error400', error.response.data);
					dispatch({ type: LOADING_END });
				} else if (error.response.status === 403) {
					console.log('error403', error.response.data);
					dispatch({ type: LOADING_END });
				} else if (error.response.status === 404) {
					console.log('error404', error.response.data);
					setDatos([]);
					dispatch({ type: LOADING_END });
				} else {
					console.log(error.response.data);
					dispatch({ type: LOADING_END });
				}
			});
	};
};

export const getTipoRelaciones = async () => {
	await getToken();
	return await axiosClient.get(`/tipoRelaciones`);
};

export const getComprobantesBySearchUuid = async (
	uuid: any,
	cliente_id: any
) => {
	await getToken();
	return await axiosClient.get(
		`/complementoPagos?busqueda=${uuid}&cliente=${cliente_id}&uuidRelacionado=1`
	);
};
export const getComprobantesByParams = async (
	cliente_id: number,
	fecha_inicio: string,
	fecha_fin: string
) => {
	await getToken();
	if (!fecha_inicio && !fecha_fin && !cliente_id) {
		console.log('solicitando TODO');
		return await axiosClient.get(`/comprobantes/?isReporte=1`);
	} else if (fecha_inicio && fecha_fin && cliente_id) {
		console.log('solicitando 3');
		return await axiosClient.get(
			`/comprobantes/?cliente_id=${cliente_id}&fecha_inicial=${fecha_inicio}&fecha_final=${fecha_fin}&isReporte=1`
		);
	} else if (fecha_inicio && fecha_fin && !cliente_id) {
		console.log('solicitando FECHAS');
		return await axiosClient.get(
			`/comprobantes/?fecha_inicial=${fecha_inicio}&fecha_final=${fecha_fin}&isReporte=1`
		);
	} else if (!fecha_inicio && !fecha_fin && cliente_id) {
		console.log('Solicitando CLIENTE');
		return await axiosClient.get(
			`/comprobantes/?cliente_id=${cliente_id}&isReporte=1`
		);
	}
};

// export const patchComprobante = async (data: any, comprobanteId: number) => {
// 	await getToken();
// 	return await axiosClient.patch(`/comprobantes/${comprobanteId}`, data);
// };

export const patchComprobante = (
	data: {},
	comprobanteId: number,
	closeModal: any,
	tipoComprobante: any,
	setComprobantesListData: any
) => {
	return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
		const token = localStorage.getItem('pxc-tkn') || '';
		dispatch({ type: START_LOADING });
		console.log('EnviandoDATA::', data);
		console.log('EnviandoComprobanteId::', comprobanteId);
		axiosClient
			.patch(`/comprobantes/${comprobanteId}`, data, {
				headers: {
					Authorization: `${token}`,
				},
			})
			.then((respuesta) => {
				console.log('respuestaCancelar::', respuesta);
				if (respuesta.status === 201) {
					dispatch({ type: LOADING_END });
					console.log(respuesta);
					toast.success(`${respuesta.data.msg}`);

					setTimeout(() => {
						dispatch({ type: LOADING_OK });
						closeModal(); //Cerrar modal
					}, 2000);

					if (tipoComprobante === 'A') {
						const getComprobantesAsync = () => {
							return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
								await getToken();
								dispatch({ type: START_LOADING });
								axiosClient
									.get(`/comprobantes`)
									.then((respuesta) => {
										console.log('GETComprobantesA');
										setComprobantesListData(respuesta.data);
										dispatch({ type: LOADING_END });
									})
									.catch((error) => {
										if (error.response.status === 500) {
											console.log('error500', error.response.data);
											dispatch({ type: LOADING_END });
										} else if (error.response.status === 400) {
											console.log('error400', error.response.data);
											dispatch({ type: LOADING_END });
										} else if (error.response.status === 403) {
											console.log('error403', error.response.data);
											dispatch({ type: LOADING_END });
										} else if (error.response.status === 404) {
											console.log('error404', error.response.data);
											setComprobantesListData([]);
											dispatch({ type: LOADING_END });
										} else {
											console.log(error.response.data);
											dispatch({ type: LOADING_END });
										}
									});
							};
						};
						//Get Comprobantes serie A
						dispatch<any>(getComprobantesAsync());
					} else if (tipoComprobante === 'CP') {
						dispatch<any>(getComprobantesCP(setComprobantesListData));
					}
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					dispatch({ type: LOADING_END });
					console.log(error.response);
					toast.error(`${error.response.data.message}`);
					setTimeout(() => {
						closeModal(); //Cerrar modal
					}, 2000);
				} else if (error.response.status === 400) {
					dispatch({ type: LOADING_END });
					console.log('error400');
					console.log(error);
					toast.error(`${error.response.data.message}`);
					setTimeout(() => {
						closeModal(); //Cerrar modal
					}, 2000);
				} else if (error.response.status === 401) {
					dispatch({ type: LOADING_END });
					console.log('error401');
					console.log(error);
					toast.error(`${error.response.data.message}`);
					setTimeout(() => {
						closeModal(); //Cerrar modal
					}, 2000);
				} else if (error.response.status === 402) {
					dispatch({ type: LOADING_END });
					console.log('error402');
					console.log(error);
					toast.error(`Tus timbres se han agotado.`);
					localStorage.setItem('pxcCntAct', 'false');
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else if (error.response.status === 403) {
					dispatch({ type: LOADING_END });
					console.log('error403');
					console.log(error);
					toast.error(`${error.response.data.message}`);
					setTimeout(() => {
						closeModal(); //Cerrar modal
					}, 2000);
				} else if (error.response.status === 404) {
					dispatch({ type: LOADING_END });
					console.log('error404');
					console.log(error);
					toast.error(`${error.response.data.message}`);
					setTimeout(() => {
						closeModal(); //Cerrar modal
					}, 2000);
				} else {
					dispatch({ type: LOADING_END });
					console.log(error);
					toast.error(`${error.message}`);
					setTimeout(() => {
						closeModal(); //Cerrar modal
					}, 2000);
				}
			});
	};
};
