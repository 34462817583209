import { iComprobante } from 'actions/Comprobante/comprobanteTypes';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import brandPixcua from 'assets/images/pixcua.png';
import MDTypography from 'components/MDTypography';
import { getEmpresaInfoById } from 'actions/MiCuenta/miCuenta';
import {
	iMiEmpresaInfoToPDF,
	initMiEmpresaFormulario,
} from 'actions/MiCuenta/miCuentaTypes';
import { getComprobantesById } from 'actions/Comprobante/comprobante';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import PdfMakeOpenTest from 'pantallas/Comprobantes/Data/acciones/components/PDF/PdfMakeOpenTest';
import PdfMakeComplementoPago from 'pantallas/Comprobantes/Data/acciones/components/PdfMakeComplementoPago/PdfMakeComplementoPago';

const ComprobantePDF = () => {
	const navigate = useNavigate();
	const initComprobante: iComprobante = {
		c_regimen_fiscal_Cliente: '',
		c_regimen_fiscal_Empresa: '',
		calle: '',
		cantidad_letra: '',
		certificado: null,
		cliente_id: 0,
		codigo_postal: '0',
		colonia: '',
		comprobantesDetalles: [],
		descuento: 0,
		empresa_id: 0,
		estado: '',
		estatus_comprobante_id: 0,
		fecha: '',
		fecha_timbrado: null,
		folio: 0,
		forma_de_pago: '',
		id: 0,
		isr: null,
		iva: 0,
		localidad: '',
		lugar_expedicion: '',
		metodo_de_pago: '',
		moneda: '',
		motivo_de_descuento: '',
		municipio: '',
		no_certificado: '',
		no_certificado_sat: null,
		no_exterior: '',
		no_interior: '',
		nombre: '',
		num_cta_pago: '',
		pagado: false,
		pais: '',
		referencia: '',
		retencion_isr: 0,
		retencion_iva: 0,
		rfc: '',
		sello: null,
		sello_sat: null,
		serie: '',
		subtotal: 0,
		subtotal_iva_cero: 0,
		tasa: 0,
		timbrado: false,
		tipo_cambio: null,
		tipo_de_comprobante: '',
		tipo_uso: '',
		total: 0,
		uuid: '',
		version: '',
		tipo_relacion_id: null,
		comprobante_relacionado_uuid: null,
	};
	const [comprobante, setComprobante] = useState(initComprobante);
	const [empresaInfo, setEmpresaInfo] = useState<iMiEmpresaInfoToPDF>(
		initMiEmpresaFormulario
	);

	const params = useParams();

	//Get comprobanteByUuid
	useEffect(() => {
		const comprobanteData = getComprobantesById(params.uuid);
		comprobanteData
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setComprobante(respuesta.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 401) {
					console.log('error401');
					console.log(error.response.data);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data.message);
				} else {
					console.log(error.response.data.message);
				}
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Get miEmpresaInfo By id
	useEffect(() => {
		if (comprobante.empresa_id) {
			const dataEmpresa = getEmpresaInfoById(comprobante.empresa_id);
			dataEmpresa
				.then((respuesta) => {
					if (respuesta.status === 200) {
						setEmpresaInfo({
							...empresaInfo,
							nombre: respuesta.data.nombre,
							rfc: respuesta.data.rfc,
							codigo_postal: respuesta.data.codigo_postal,
							calle: respuesta.data.calle ? respuesta.data.calle : '',
							no_exterior: respuesta.data.no_exterior
								? respuesta.data.no_exterior
								: '',
							no_interior: respuesta.data.no_interior
								? respuesta.data.no_interior
								: '',
							colonia: respuesta.data.colonia ? respuesta.data.colonia : '',
							localidad: respuesta.data.localidad ? respuesta.data.localidad : '',
							estado: respuesta.data.estado ? respuesta.data.estado : '',
							pais: respuesta.data.pais ? respuesta.data.pais : '',
						});
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response.data.message);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response.data.message);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response.data.message);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response.data.message);
					} else {
						console.log(error.response.data.message);
					}
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [comprobante.empresa_id]);

	const atras = () => {
		navigate(-1);
	};
	return (
		<>
			<MDBox pt={4} pb={3}>
				{/* {authState.isAutenticated && (
					<Button
						color='primary'
						variant='contained'
						startIcon={<ArrowBackIosIcon style={{ color: '#fff' }} />}
						style={{ backgroundColor: '#00ACC8', color: '#fff', marginLeft: '10px' }}
						onClick={atras}
					>
						regresar
					</Button>
				)} */}
				<Grid container justifyContent='center' alignItems='center'>
					<MDAvatar
						variant='square'
						style={{ marginTop: '2rem', width: '9rem' }}
						src={brandPixcua}
						alt='Avatar'
					/>
				</Grid>
				{comprobante.uuid ? (
					<MDBox pt={4} mt={5} textAlign='center'>
						<MDTypography variant='h4' fontWeight='medium' color='text'>
							Descarga aquí tu comprobante:
						</MDTypography>
						<MDTypography variant='h4' fontWeight='medium' color='text' mb={5}>
							{' '}
							{comprobante.uuid}.pdf
						</MDTypography>
						{comprobante.tipo_comprobante_id === 1 && (
							<PdfMakeOpenTest
								uuid={comprobante.uuid}
								tipo={'descargar'}
								id={comprobante.id}
							/>
						)}
						{comprobante.tipo_comprobante_id === 2 && (
							<PdfMakeOpenTest
								uuid={comprobante.uuid}
								tipo={'descargar'}
								id={comprobante.id}
							/>
						)}
						{comprobante.tipo_comprobante_id === 7 && (
							<PdfMakeComplementoPago uuid={comprobante.uuid} tipo={'descargar'} />
						)}
					</MDBox>
				) : (
					<MDBox pt={4} mt={5} textAlign='center'>
						<MDTypography variant='h4' fontWeight='medium' color='text'>
							PÁGINA NO ENCONTRADA
						</MDTypography>
						<MDTypography variant='button' color='text'>
							volver a{' '}
							<MDTypography
								component={Link}
								to='/login'
								variant='button'
								color='primary'
								fontWeight='medium'
								textGradient
							>
								Inicio
							</MDTypography>
						</MDTypography>
					</MDBox>
				)}
			</MDBox>
		</>
	);
};

export default ComprobantePDF;
