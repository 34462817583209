import { Card } from '@mui/material';
import MDBadgeDot from 'components/MDBadgeDot';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import PieChart from 'examples/Charts/PieChart';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import {
	LoadingDispatchTypes,
	LOADING_END,
	START_LOADING,
} from 'actions/Loading/loadingTypes';
import { axiosClient, getToken } from 'Config/axiosConfig';
import { useDispatch } from 'react-redux';
import { numeroAMoneda } from 'helpers/aMoneda';

const PxcPieChart = () => {
	const dispatch = useDispatch();
	const actualYear = moment().format('yyyy');
	const [clientesData, setClientesData] = useState([
		{ nombre: '', porcentaje: 0, ventas: 0 },
	]);

	useEffect(() => {
		//Obtener Clientes Principales
		const getClientesPrincipales = () => {
			return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
				await getToken();
				dispatch({ type: START_LOADING });
				axiosClient
					.get(`/comprobantes/infoClientes/`)
					.then((respuesta) => {
						if (respuesta.status === 200) {
							setClientesData(respuesta.data);
						}
						dispatch({ type: LOADING_END });
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 400) {
							console.log('error400', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 403) {
							console.log('error403', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 404) {
							console.log('error404', error.response.data);
							dispatch({ type: LOADING_END });
						} else {
							console.log(error.response.data);
							dispatch({ type: LOADING_END });
						}
					});
			};
		};
		dispatch<any>(getClientesPrincipales());
	}, []);
	const labels = clientesData.reduce((acumulador: any, currentValue: any) => {
		return acumulador.concat(
			currentValue.nombre + '. ' + currentValue.porcentaje.toFixed(1) + '%'
		);
	}, []);
	const datasets = clientesData.reduce((acumulador: any, currentValue: any) => {
		return acumulador.concat(currentValue.ventas);
	}, []);

	const pxcChannelChartData = {
		labels: labels,
		datasets: {
			label: 'Projects',
			backgroundColors: ['primary', 'success', 'error', 'info', 'dark'],
			data: datasets,
		},
	};

	return (
		<>
			<Card sx={{ height: '100%' }}>
				<MDBox
					display='flex'
					justifyContent='space-between'
					alignItems='center'
					pt={2}
					px={2}
				>
					<MDTypography variant='h6'>Clientes Principales {actualYear}</MDTypography>
				</MDBox>
				<MDBox mt={3}>
					<MDBox display={'flex'} flexDirection={'column'}>
						<MDBox item xs={7}>
							<PieChart chart={pxcChannelChartData} height='12.5rem' />
						</MDBox>
						<MDBox item xs={5}>
							<MDBox pr={1}>
								{clientesData.map((item: any, index) => (
									<MDBox key={item.nombre} mb={1}>
										{index === 0 && (
											<MDBadgeDot
												color='primary'
												size='sm'
												badgeContent={` ${item.nombre} -        ${numeroAMoneda(
													item.ventas
												)}`}
											/>
										)}
										{index === 1 && (
											<MDBadgeDot
												color='success'
												size='sm'
												badgeContent={`${item.nombre} -        ${numeroAMoneda(
													item.ventas
												)}`}
											/>
										)}
										{index === 2 && (
											<MDBadgeDot
												color='error'
												size='sm'
												badgeContent={` ${item.nombre} -        ${numeroAMoneda(
													item.ventas
												)}`}
											/>
										)}
										{index === 3 && (
											<MDBadgeDot
												color='info'
												size='sm'
												badgeContent={`${item.nombre} -        ${numeroAMoneda(
													item.ventas
												)}`}
											/>
										)}
										{index === 4 && (
											<MDBadgeDot
												color='dark'
												size='sm'
												badgeContent={`${item.nombre} -        ${numeroAMoneda(
													item.ventas
												)}`}
											/>
										)}
									</MDBox>
								))}
							</MDBox>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		</>
	);
};

export default PxcPieChart;
