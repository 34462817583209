import  { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MDBox from 'components/MDBox';
import Typography from '@mui/material/Typography';
import { planAtributos } from 'actions/Auth/authTypes';
import MDButton from 'components/MDButton';
import { Icon } from '@mui/material';
import { getSuscripcion } from 'actions/Suscripcion/suscripcion';
import { getPlanByAlias, logOut, stripeCheckOut } from 'actions/Auth/auth';
import brandPixcua from 'assets/images/pixcua.png';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getEmpresaById } from 'actions/MiCuenta/miCuenta';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import { Toast } from 'Config/swalAlert';
import moment from 'moment';

const UpdateSubscription = () => {
	const { empresa_id } = useSelector((state: RootStore) => state.auth);
	const myDispatch = useDispatch();
	const navigate = useNavigate();
	const [suscriptionState, setSuscriptionState] = useState(false);
	const [cus_stripe_id, setCus_stripe_id] = useState('second');
	const pxcSuscripcionActiva = localStorage.getItem('pxcCntAct') || '';

	interface iPlan {
		timbres: number;
		vigencia: string;
		plan_id?: number;
	}
	const tipoPlan: planAtributos = {
		id: null,
		nombre: '',
		cantidad: null,
		precio: null,
		alias: '',
		caracteristicas: '',
		stripe_id: '',
		periodo: '',
		estaActivo: false,
	};
	const [plan, setPlan] = useState(tipoPlan);
	const [suscripcionInfo, setSuscripcionInfo] = useState<iPlan>({
		timbres: 0,
		vigencia: '',
		plan_id: 0,
	});

	useEffect(() => {
		// Get suscripcion info
		const suscripcionData = getSuscripcion();
		suscripcionData
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('suscripcion:', respuesta.data.data);
					const time = moment(respuesta.data.data.fecha_fin).format('DD/MM/YYYY');
					setSuscripcionInfo({
						timbres: respuesta.data.data.timbres_disponibles,
						vigencia: time,
						plan_id: respuesta.data.data.plan_id,
					});
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 401) {
					console.log('error401');
					console.log(error.response.data);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data.message);
				} else {
					console.log(error.response.data.message);
				}
			});
	}, []);

	const getPlan = (planId?: number) => {
		const data = getPlanByAlias(planId);
		data
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('obteninendoPLAN', respuesta.data);
					setPlan(respuesta.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data.message);
				} else {
					console.log(error.response.data.message);
				}
			});
	};
	const getEmpresaInfo = (empresaId: number) => {
		const data = getEmpresaById(empresaId);
		data
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setCus_stripe_id(respuesta.data.cus_stripe_id);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data.message);
				} else {
					console.log(error.response.data.message);
				}
			});
	};

	//Efecto para obtener la Data del PlanbyID
	useEffect(() => {
		if (suscripcionInfo.plan_id) {
			getPlan(suscripcionInfo.plan_id);
		}
	}, [suscripcionInfo]);
	//Efecto para obtener la Data de mi Empresa
	useEffect(() => {
		if (empresa_id) {
			getEmpresaInfo(empresa_id);
		}
	}, [empresa_id]);

	//Efecto para cambiar el status de la suscripción
	useEffect(() => {
		if (pxcSuscripcionActiva === 'true') {
			console.log('SuscripcionTRUE', pxcSuscripcionActiva);
			setSuscriptionState(true);
		} else {
			console.log('SuscripcionFALSE', pxcSuscripcionActiva);
			setSuscriptionState(false);
		}
		if (suscriptionState) {
			navigate('/');
		}
	}, [suscriptionState, pxcSuscripcionActiva]);

	//Metodo para cerrar sesion
	const startLogOut = () => {
		localStorage.setItem('pxc-tkn', '');
		localStorage.setItem('pxc-tkn-init-date', '');
		localStorage.setItem('pxcUser', '');
		localStorage.setItem('pxcCntAct', '');
		myDispatch(logOut());
	};

	const startCheckout = () => {
		if (plan.stripe_id) {
			console.log('Enviando...', {
				Price_Id: plan.stripe_id,
				cus_stripe_id: cus_stripe_id,
			});

			const data = stripeCheckOut({
				Price_Id: plan.stripe_id,
				cus_stripe_id: cus_stripe_id,
			});
			data
				.then((respuesta) => {
					console.log('Respuesta de StripeCheckOut', respuesta);
					if (respuesta.status === 200) {
						console.log(respuesta.data.data.url);

						toStripe(respuesta.data.data.url);
						console.log('despues de mavigate');

						
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response.data);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response.data);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response.data);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response.data);
					} else {
						console.log(error);
					}
				});
		} else {
			Toast.fire({
				icon: 'error',
				title: `Algo salio mal, por favor refresque la página y vuelva a intentarlo. `,
				timer: 5000,
			});
		}
	};

	const toStripe = (url: string) => {
		window.location.href = url;
	};

	return (
		<div>
			<MDBox marginLeft={3} style={{ position: 'absolute' }}>
				
				<MDBox
					component='img'
					src={brandPixcua}
					alt='Brand'
					width='11rem'
					marginTop={3}
					alignItems={'center'}
				/>
			</MDBox>
			<MDBox
				display='flex'
				alignItems='center'
				justifyContent={'center'}
				style={{ height: '100vh' }}
			>
				<Box
					sx={{
						position: 'relative',

						width: 600,
						bgcolor: 'background.paper',
						borner: 'none',
						boxShadow: (theme) => theme.shadows[5],
					}}
					style={{ maxWidth: '90%' }}
				>
					<MDBox p={4} style={{ backgroundColor: '#00ACC8' }}>
						<Typography variant='h5' component='h2' style={{ color: '#f5f5f5' }}>
							Actualiza tu suscripción.
						</Typography>
					</MDBox>
					<Typography
						variant='h6'
						component='h2'
						style={{ padding: '10px 30px 0px 30px' }}
					>
						Estimado cliente, tu suscripción ha vencido o tus timbres se han agotado.
					</Typography>
					<Typography variant='body2' style={{ padding: '10px 30px 0px 30px' }}>
						{`Fecha de vencimiento: ${suscripcionInfo.vigencia}`}
					</Typography>
					<Typography variant='body2' style={{ padding: '10px 30px 0px 30px' }}>
						{`Timbres disponibles:  ${suscripcionInfo.timbres}`}
					</Typography>
					<Typography variant='body2' style={{ padding: '10px 30px 0px 30px' }}>
						{`Plan contratado: ${plan.nombre} ( $ ${plan.precio} )`}
					</Typography>
					<Typography
						variant='body2'
						style={{ padding: '10px 30px 0px 30px' }}
						mb={2}
					>
						Para renovar tu suscripción, da click en el siguiente botón o comunicate
						con nosotros vía chat, también tienes la opción de envíar un correo a
						soporte@pixcua.mx
					</Typography>
					<MDBox
						display={'flex'}
						justifyContent={'space-between'}
						style={{
							margin: '10px 30px 20px 30px',
						}}
					>
						

						<MDButton
							variant='gradient'
							onClick={startCheckout}
							style={{
								backgroundColor: '#00ACC8',
								color: '#f5f5f5',
							}}
						>
							Suscribirse&nbsp;
							<Icon>loyalty</Icon>
						</MDButton>
						<MDButton
							variant='gradient'
							onClick={startLogOut}
							style={{
								color: '#000',
							}}
						>
							Salir
						</MDButton>
					</MDBox>
				</Box>
			</MDBox>
		</div>
	);
};

export default UpdateSubscription;
