// @material-ui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
// @mui material icon
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

import { Button, InputLabel, NativeSelect } from '@mui/material';
import { useToggle } from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import {
	getEmpresaById,
	getEntidades,
	getEntidadesByPais,
	getRegimenes,
	patchEmpresaData,
} from 'actions/MiCuenta/miCuenta';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import Swal from 'sweetalert2';
import { iMiEmpresaFormulario } from 'actions/MiCuenta/miCuentaTypes';
import { Toast } from 'Config/swalAlert';

function EmpresaInfo(): JSX.Element {
	const { empresa_id } = useSelector((state: RootStore) => state.auth);
	const [value, toggleValue] = useToggle(true); //Recibe el valor inicial

	const [regimenes, setRegimenes] = useState([]);
	const [estadosList, setEstadosList] = useState([]);

	const miEmpresa: iMiEmpresaFormulario = {
		nombre: '',
		rfc: '',
		calle: '',
		no_exterior: '',
		no_interior: '',
		colonia: '',
		localidad: '',
		referencia: '',
		municipio: '',
		estado: '',
		pais: '',
		codigo_postal: '',
		telefono: '',
		email: '',
		regimen_fiscal: '',
		entidad_id: 0,
		logo: '',
	};
	//Uso de hook useForm para manejo de campos en el formulario
	const [formValues, setFormValues] = useState(miEmpresa);
	const {
		nombre,
		rfc,
		calle,
		no_exterior,
		no_interior,
		colonia,
		localidad,
		referencia,
		municipio,
		estado,
		pais,
		codigo_postal,
		telefono,
		email,
		regimen_fiscal,
	} = formValues;

	const handleInputChange = (event: any) => {
		setFormValues({
			...formValues,
			[event.target.name]: event.target.value,
		});
	};

	useEffect(() => {
		const dataEmpresa = getEmpresaById(empresa_id);
		dataEmpresa
			.then((respuesta) => {
				if (respuesta.status === 200) {
					console.log('REspuestaEmpresa', respuesta.data);
					setFormValues({
						...formValues,
						nombre: respuesta.data.nombre,
						rfc: respuesta.data.rfc,
						codigo_postal: respuesta.data.codigo_postal,
						pais: respuesta.data.pais,
						estado: respuesta.data.estado,
						municipio: respuesta.data.municipio,
						colonia: respuesta.data.colonia,
						calle: respuesta.data.calle,
						no_exterior: respuesta.data.no_exterior,
						no_interior: respuesta.data.no_interior,
						localidad: respuesta.data.localidad,
						referencia: respuesta.data.referencia,
						telefono: respuesta.data.telefono,
						email: respuesta.data.email,
						regimen_fiscal: respuesta.data.regimen_fiscal,
					});
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data.message);
				} else {
					console.log(error.response.data.message);
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [empresa_id]);

	useEffect(() => {
		//Get Regimenes
		const data = getRegimenes();
		data
			.then((respuesta) => {
				if (respuesta.status === 201) {
					setRegimenes(respuesta.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data.message);
				} else {
					console.log(error.response.data.message);
				}
			});

		//Get Entidades
		const dataEntidades = getEntidades();
		dataEntidades
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setEstadosList(respuesta.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response);
				} else {
					console.log(error.response);
				}
			});
	}, []);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (isFormValid()) {
			const patchUsuario = patchEmpresaData(formValues, empresa_id);
			patchUsuario
				.then((respuesta) => {
					console.log(respuesta);
					if (respuesta.status === 201) {
						setFormValues(respuesta.data.record);
						toggleValue();
						Swal.fire({
							position: 'top-end',
							icon: 'success',
							title: `¡Actualización exitosa!`,
							showConfirmButton: false,
							timer: 2000,
						});
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response.data.message);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response.data.message);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response);
						toggleValue();
						Swal.fire({
							position: 'top-end',
							icon: 'error',
							title: `${error.response.data.msg}`,
							showConfirmButton: false,
							timer: 3500,
						});
					} else {
						console.log(error.response.data.message);
					}
				});
		} else {
			console.log('form values', formValues);
			Toast.fire({
				icon: 'error',
				title: `Todos los campos marcados con "*" son obligatorios.`,
				timer: 2000,
			});
		}
	};

	const cancelarEdicion = () => {
		toggleValue();
	};

	const isFormValid = () => {
		if (
			!formValues.nombre ||
			!formValues.rfc ||
			!formValues.codigo_postal ||
			!formValues.pais ||
			!formValues.estado ||
			!formValues.estado ||
			!formValues.municipio ||
			!formValues.colonia ||
			!formValues.calle ||
			!formValues.no_exterior ||
			!formValues.localidad ||
			!formValues.regimen_fiscal ||
			!formValues.telefono ||
			!formValues.email
		) {
			return false;
		}
		return true;
	};
	useEffect(() => {
		//Get Entidades
		let miPais = '';
		if (pais === 'México') miPais = 'MEX';
		else if (pais === 'USA') miPais = 'USA';
		else if (pais === 'Canada') miPais = 'CAN';

		const dataEntidades = getEntidadesByPais(miPais);
		dataEntidades
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setEstadosList(respuesta.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response);
				} else {
					console.log(error.response);
				}
			});
	}, [pais]);

	return (
		<Card id='basic-info' sx={{ overflow: 'visible', marginBottom: '3rem' }}>
			<MDBox pl={3}>
				<MDTypography variant='h5'>
					Mi Empresa
					<Button
						color='secondary'
						variant='text'
						size='large'
						startIcon={<EditIcon />}
						onClick={toggleValue}
					>
						Editar
					</Button>
				</MDTypography>
				<MDTypography variant='body2'>
					Todos los campos con * son obligatorios
				</MDTypography>
			</MDBox>
			{!value && (
				<MDBox
					ml={2}
					mb={3}
					mt={1}
					style={{
						maxWidth: '600px',
						lineHeight: '18px',
						fontSize: '15px',
						textAlign: 'justify',
						backgroundColor: '#e1a90244',
						border: '2px solid #ffbf00',
						padding: '10px',
						borderRadius: '10px',
					}}
				>
					<MDTypography variant='captions' style={{ color: '#383838' }}>
						NOTA: Los datos ingresados en esta sección deberan coincidir exactamente
						con los registrados ante el SAT, respetando Mayúsculas, Minúsculas,
						Espacios, Acentos y Signos de puntuación.
					</MDTypography>
				</MDBox>
			)}
			<MDBox component='form' pb={3} px={3} onSubmit={handleSubmit}>
				<Grid container spacing={4}>
					<Grid item xs={12} sm={3}>
						<FormField
							label='Nombre o razón social*'
							placeholder='Nombre o razón social*'
							name='nombre'
							value={nombre}
							onChange={handleInputChange}
							disabled={value}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormField
							label='RFC*'
							placeholder='RFC*'
							name='rfc'
							value={rfc}
							onChange={handleInputChange}
							disabled={value}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormField
							label='Código Postal*'
							placeholder='Código Postal*'
							name='codigo_postal'
							value={codigo_postal}
							onChange={handleInputChange}
							disabled={value}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						{/* <FormField
							label='País*'
							placeholder='País*'
							name='pais'
							value={pais}
							onChange={handleInputChange}
							disabled={value}
						/> */}
						<MDBox
							style={
								value
									? { width: '100%', backgroundColor: '#EFF2F5' }
									: { width: '100%' }
							}
						>
							<InputLabel variant='standard' htmlFor='uncontrolled-native'>
								País
							</InputLabel>
							<NativeSelect
								disabled={value}
								onChange={handleInputChange}
								value={pais}
								inputProps={{
									name: 'pais',
									id: 'uncontrolled-native',
								}}
								style={{ width: '100%' }}
								required
							>
								<option value={''}>Seleccione una opción</option>
								<option value={'México'}>México</option>
								<option value={'USA'}>USA</option>
								<option value={'Canada'}>Canada</option>
							</NativeSelect>
						</MDBox>
					</Grid>

					<Grid item xs={15} sm={3}>
						<MDBox
							style={
								value
									? { width: '100%', backgroundColor: '#EFF2F5' }
									: { width: '100%' }
							}
						>
							<InputLabel variant='standard' htmlFor='uncontrolled-native'>
								Estado*
							</InputLabel>
							<NativeSelect
								disabled={value}
								onChange={handleInputChange}
								value={estado}
								inputProps={{
									name: 'estado',
									id: 'uncontrolled-native',
								}}
								style={{ width: '100%' }}
								required
							>
								<option value={''}>Seleccione una opción</option>
								{estadosList.map((item) => (
									<option key={item.id} value={item.nombre}>
										{item.nombre}
									</option>
								))}
							</NativeSelect>
						</MDBox>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormField
							label='Delegación/Municipio*'
							placeholder='Delegación/Municipio*'
							name='municipio'
							value={municipio}
							onChange={handleInputChange}
							disabled={value}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormField
							label='Colonia*'
							placeholder='Colonia*'
							name='colonia'
							value={colonia}
							onChange={handleInputChange}
							disabled={value}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormField
							label='Calle*'
							placeholder='Calle*'
							name='calle'
							value={calle}
							onChange={handleInputChange}
							disabled={value}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormField
							label='No.Exterior*'
							placeholder='No.Exterior*'
							name='no_exterior'
							value={no_exterior}
							onChange={handleInputChange}
							disabled={value}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormField
							label='No. Interior'
							placeholder='No. Interior'
							name='no_interior'
							value={no_interior}
							onChange={handleInputChange}
							disabled={value}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormField
							label='Localidad*'
							placeholder='Localidad*'
							name='localidad'
							value={localidad}
							onChange={handleInputChange}
							disabled={value}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormField
							label='Referencia'
							placeholder='Referencia'
							name='referencia'
							value={referencia}
							onChange={handleInputChange}
							disabled={value}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={1} mt={2}>
					<Grid item xs={12} sm={3}>
						<Button
							href='https://www.sat.gob.mx/sitio_internet/asistencia_contribuyente/principiantes/comprobantes_fiscales/66_22080.html'
							target='_blank'
							rel='noreferrer'
							color='secondary'
							variant='outlined'
							size='small'
							style={{ color: '#bdbdbd' }}
							endIcon={<OpenInNewIcon />}
						>
							¿Cúal es mi régimen fiscal?
						</Button>
					</Grid>
				</Grid>
				<Grid container spacing={1} mt={2}>
					<Grid item xs={12} sm={3} mt={0}>
						<MDBox
							style={
								value
									? { width: '100%', backgroundColor: '#EFF2F5' }
									: { width: '100%' }
							}
						>
							<InputLabel variant='standard' htmlFor='uncontrolled-native'>
								Regimen Fiscal*
							</InputLabel>
							<NativeSelect
								disabled={value}
								onChange={handleInputChange}
								value={regimen_fiscal}
								inputProps={{
									name: 'regimen_fiscal',
									id: 'uncontrolled-native',
								}}
								style={{ width: '100%' }}
								required
							>
								<option value={''}>Seleccione una opción</option>
								{regimenes.map((item) => (
									<option key={item.id} value={item.c_RegimenFiscal}>
										{`${item.c_RegimenFiscal} - ${item.descripcion}`}
									</option>
								))}
							</NativeSelect>
						</MDBox>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormField
							label='Número de teléfono*'
							placeholder='Número de teléfono*'
							name='telefono'
							value={telefono}
							onChange={handleInputChange}
							disabled={value}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormField
							label='Correo electrónico*'
							placeholder='Correo electrónico*'
							name='email'
							value={email}
							onChange={handleInputChange}
							disabled={value}
						/>
					</Grid>
					{!value && (
						<Grid item xs={12} sm={3}>
							<Button
								color='secondary'
								variant='outlined'
								size='small'
								style={{ color: '#bdbdbd', margin: '5px' }}
								onClick={cancelarEdicion}
							>
								Cancelar
							</Button>
							<Button
								type='submit'
								color='primary'
								variant='contained'
								size='small'
								style={{ color: 'white', margin: '5px' }}
							>
								Guardar
							</Button>
						</Grid>
					)}
				</Grid>
			</MDBox>
		</Card>
	);
}

export default EmpresaInfo;
