import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import Grid from '@mui/material/Grid';
import MDInput from 'components/MDInput';
import {
	Autocomplete,
	Box,
	Checkbox,
	FormControlLabel,
	InputLabel,
	NativeSelect,
	TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
	getComprobantesBySearchUuid,
	getTipoRelaciones,
} from 'actions/Comprobante/comprobante';
import { numeroAMoneda } from 'helpers/aMoneda';

function Extras({
	extras,
	handleInputChangeExtras,
	checkedDocRelacionado,
	setCheckedDocRelacionado,
	clienteData,
}: any): JSX.Element {
	const [tipoRelacionesData, setTipoRelacionesData] = useState([]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedDocRelacionado(event.target.checked);
	};
	const initOptions: any[] = [];
	const [options, setOptions] = useState(initOptions);
	const initUuidOptions: any[] = [];
	const [uuidOptions, setUuidOptions] = useState(initUuidOptions);

	useEffect(() => {
		//Get TipoRelaciones
		const dataTipoRelaciones = getTipoRelaciones();
		dataTipoRelaciones
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setTipoRelacionesData(respuesta.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data);
				} else {
					console.log(error.response.data.message);
				}
			});
	}, []);

	//Clave Concepto
	//Debouncing para obtener el autocompletado de conceptos
	useEffect(() => {
		let timeOutSearch = setTimeout(() => {
			if (extras.comprobante_relacionado_uuid.length > 2) {
				const data = getComprobantesBySearchUuid(
					extras.comprobante_relacionado_uuid,
					clienteData.id
				);
				data
					.then((respuesta) => {
						if (respuesta.status === 200) {
							setUuidOptions(respuesta.data);
						}
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500');
							console.log(error.response.data.message);
						} else if (error.response.status === 400) {
							console.log('error400');
							console.log(error.response.data.message);
						} else if (error.response.status === 403) {
							console.log('error403');
							console.log(error.response.data.message);
						} else if (error.response.status === 404) {
							console.log('error404');
						} else {
							console.log(error.response.data.message);
						}
					});
			} else {
				setUuidOptions([]);
			}
		}, 500);

		return () => {
			clearTimeout(timeOutSearch);
		};
	}, [extras.comprobante_relacionado_uuid]);

	return (
		<MDBox mb={4}>
			<MDBox mt={4}>
				<MDTypography
					variant='h5'
					fontWeight='medium'
					color='primary'
					sx={{ margin: '1rem' }}
				>
					Extras
				</MDTypography>
				<MDTypography
					variant='caption'
					fontWeight='medium'
					color='primary'
					sx={{ margin: '1rem', marginBottom: '1rem' }}
				>
					Todos los campos con * son obligatorios
				</MDTypography>
			</MDBox>
			<MDBox mt={1} display={'flex'} flexDirection={'column'}>
				<MDBox>
					<MDInput
						variant='standard'
						label='Lugar de expedición*'
						sx={{ margin: '1rem', width: '15rem' }}
						value={extras.lugar_expedicion}
						name='lugar_expedicion'
						// onChange={handleInputChangeExtras}
						disabled
					/>
					<MDInput
						variant='standard'
						label='No. de cuenta (Últimos 4 digitos)'
						sx={{ margin: '1rem', width: '15rem' }}
						value={extras.num_cta_pago}
						name='num_cta_pago'
						onChange={handleInputChangeExtras}
					/>
				</MDBox>
				<MDBox display={'flex'}>
					<MDBox sx={{ margin: '1rem', width: '15rem' }}>
						<InputLabel variant='standard' htmlFor='monedaExtrasWizard'>
							Moneda
						</InputLabel>
						<NativeSelect
							type={'number'}
							inputProps={{
								name: 'moneda',
								id: 'monedaExtrasWizard',
							}}
							value={extras.moneda}
							onChange={handleInputChangeExtras}
							style={{ width: '100%' }}
						>
							<option value={'MXN'}>Peso Mexicano</option>
							<option value={'USD'}>Dólar Americano</option>
							<option value={'CAD'}>Dólar Canadiense</option>
							<option value={'EUR'}>Euro</option>
						</NativeSelect>
					</MDBox>
					{extras.moneda === 'MXN' ? null : (
						<Grid item xs={12} sm={5} mt={3}>
							<InputLabel variant='standard' htmlFor='cantidadConceptosWizard'>
								Tipo cambio*
							</InputLabel>
							<MDBox>
								<input
									className='customInputFiled'
									type={'number'}
									id='cantidadConceptosWizard'
									placeholder='Tipo de cambio*'
									value={extras.tipo_cambio}
									name='tipo_cambio'
									onChange={handleInputChangeExtras}
								/>
							</MDBox>
						</Grid>
					)}
				</MDBox>
				<MDBox>
					<FormControlLabel
						sx={{ margin: '1rem 0rem 0rem .4rem', width: '15rem' }}
						control={
							<Checkbox checked={checkedDocRelacionado} onChange={handleChange} />
						}
						label='¿Relacionar documento?'
						color='secondary'
					/>
				</MDBox>
				{checkedDocRelacionado && (
					<MDBox px={3} mt={2}>
						<Grid container spacing={3}>
							<NativeSelect
								value={extras.tipo_relacion_id}
								onChange={handleInputChangeExtras}
								inputProps={{
									name: 'tipo_relacion_id',
									id: 'uncontrolled-tipo_relacion_id',
								}}
								sx={{ marginLeft: '1rem', marginTop: '1rem', width: '15rem' }}
								required
							>
								<option value={''}>Selecciona un tipo de relación*</option>
								{tipoRelacionesData.map((item) => (
									<option key={item.id} value={item.id}>
										{item.descripcion}
									</option>
								))}
							</NativeSelect>
							<Grid item xs={12} sm={4}>
								<input
									className='customInputFiled'
									type={'text'}
									list='datalistOptions'
									id='comprobante_relacionado_uuid'
									placeholder='Uuid de comprobante a relacionar*'
									value={extras.comprobante_relacionado_uuid ?? ''}
									name='comprobante_relacionado_uuid'
									onChange={handleInputChangeExtras}
									required
									autoComplete='off'
								/>

								<datalist id='datalistOptions'>
									{uuidOptions &&
										uuidOptions.map((opcion: any) => (
											<option key={opcion.id} value={opcion.uuid}>
												{`${opcion.uuid} - ${opcion.serie}
											${opcion.folio} - ${numeroAMoneda(opcion.total)}`}
											</option>
										))}
								</datalist>
							</Grid>
						</Grid>
					</MDBox>
				)}
			</MDBox>
		</MDBox>
	);
}

export default Extras;
