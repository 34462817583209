import { axiosClient, getToken } from 'Config/axiosConfig';

export const getSuscripcion = async () => {
	await getToken();
	return await axiosClient.get(`/suscripcion`);
};
export const getSuscripciones = async () => {
	await getToken();
	return await axiosClient.get(`/suscripciones`);
};
