export const numeroAMes = (numeroMes: number) => {
	if (numeroMes === 1) {
		return 'Enero';
	} else if (numeroMes === 2) {
		return 'Febrero';
	} else if (numeroMes === 3) {
		return 'Marzo';
	} else if (numeroMes === 4) {
		return 'Abril';
	} else if (numeroMes === 5) {
		return 'Mayo';
	} else if (numeroMes === 6) {
		return 'Junio';
	} else if (numeroMes === 7) {
		return 'Julio';
	} else if (numeroMes === 8) {
		return 'Agosto';
	} else if (numeroMes === 9) {
		return 'Septiembre';
	} else if (numeroMes === 10) {
		return 'Octubre';
	} else if (numeroMes === 11) {
		return 'Noviembre';
	} else if (numeroMes === 12) {
		return 'Diciembre';
	}

	return 'Invalido';
};
