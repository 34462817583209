
import MDBox from 'components/MDBox';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

const EstatusPagoNoPagado = () => {
  return (
    <>
    <MDBox display={'flex'} alignItems='center' ml={3}>
        <UnpublishedIcon fontSize='medium' style={{color:'#ff7d7d'}} />
    </MDBox>
</>
  )
}

export default EstatusPagoNoPagado