import { axiosClient, getToken } from '../../Config/axiosConfig';
import { iFormaPago } from './forma_PagoTypes';

class formaPago {
	async get() {
		await getToken();
		return await axiosClient.get<iFormaPago[]>('/formaPago');
	}
	async getId(id: number) {
		await getToken();
		return await axiosClient.get<iFormaPago>(`/formaPago/${id}`);
	}
}

export default new formaPago();

export const getFormaPago = async (c_FormaPago: string) => {
	await getToken();
	return await axiosClient.get(`/formaPago/?c_FormaPago=${c_FormaPago}`);
};
export const getFormaPagoAll = async () => {
	await getToken();
	return await axiosClient.get(`/formaPago`);
};
