export interface iMiEmpresaFormulario {
	nombre: string;
	rfc: string;
	calle: string;
	no_exterior: string;
	no_interior: string;
	colonia: string;
	localidad: string;
	referencia: string;
	municipio: string;
	estado: string;
	pais: string;
	codigo_postal: string;
	telefono: string;
	email: string;
	regimen_fiscal: string;
	entidad_id: number;
	logo: string;
}
export interface iMiEmpresaInfoToPDF {
	nombre: string;
	rfc: string;
	codigo_postal: string;
	calle: string;
	no_interior: string;
	no_exterior: string;
	colonia: string;
	localidad: string;
	estado: string;
	pais: string;
	regimen_fiscal: string;
	logo: string;
	imagen_templete:string;
}
export const initMiEmpresaFormulario: iMiEmpresaInfoToPDF = {
	nombre: '',
	rfc: '',
	codigo_postal: '',
	calle: '',
	no_interior: '',
	no_exterior: '',
	colonia: '',
	localidad: '',
	estado: '',
	pais: '',
	regimen_fiscal: '',
	logo: '',
	imagen_templete:''
};
