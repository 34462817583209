import { useEffect, useState } from 'react';
// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MDButton from 'components/MDButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { ToastContainer, toast } from 'react-toastify';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import CircularProgress from '@mui/material/CircularProgress';

//Material icons
import ErrorIcon from '@mui/icons-material/Error';

// Data
import MDInput from 'components/MDInput';

//Styles
import './certificados.css';
import { useForm } from 'hooks/useForm';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import DataTable from 'components/Tables/DataTable';
import Activo from './components/Activo';
import NoActivo from './components/NoActivo';
import { getEmpresaById } from 'actions/MiCuenta/miCuenta';
import { icertificado } from 'actions/Certificados/certificadosTypes';

import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { axiosClient, getToken } from 'Config/axiosConfig';
import {
	LoadingDispatchTypes,
	LOADING_END,
	LOADING_OK,
	START_LOADING,
} from 'actions/Loading/loadingTypes';
import PXLoader from 'components/PXLoader/PXLoader';
import { Buffer } from 'buffer';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function Certificados(): JSX.Element {
	const dispatch = useDispatch();
	const { loading } = useSelector((state: RootStore) => state.loading);
	const { empresa_id } = useSelector((state: RootStore) => state.auth);
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(!open);
		reset();
		setErrorMsg('');
	};
	const handleClose = (event?: object, reason?: string) => {
		if (reason && reason == 'backdropClick') return;
		setOpen(false);
		reset();
		setErrorMsg('');
	};
	const [errorMsg, setErrorMsg] = useState('');

	//useState que guarda la Data obtenida del servidor
	const [certificadosData, setCertificadosData] = useState<icertificado[]>([]);
	const [empresaRFC, setEmpresaRFC] = useState('');
	const [llavePass64, setLlavePass64] = useState('');

	const [formValues, handleInputChange, reset] = useForm({
		certificadoFile: '',
		llaveFile: '',
		llavePass: '',
	});
	const { certificadoFile, llaveFile, llavePass } = formValues;

	const handleSubmit = (e: any) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		const formData2 = new FormData();

		const sello = formData.get('certificadoFile');
		const llave = formData.get('llaveFile');

		formData2.append('certificado', sello);
		formData2.append('certificado', llave);

		if (isFormValid()) {
			const postCertificados = (data: any, psword: string, handleOpen: any) => {
				return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
					const token = localStorage.getItem('pxc-tkn') || '';
					dispatch({ type: START_LOADING });

					axiosClient
						.post(`/empresas/certificados`, data, {
							headers: {
								'content-type': 'multipart/form-data',
								Authorization: `${token}`,
								Password: `${psword}`,
							},
						})
						.then((respuesta) => {
							if (respuesta.status === 201) {
								dispatch({ type: LOADING_END });
								dispatch<any>(getCertificados());
								toast.success('¡Registro exitoso!');
								setTimeout(() => {
									dispatch({ type: LOADING_OK });
									handleOpen();
								}, 2000);
							}
						})
						.catch((error) => {
							if (error.response.status === 500) {
								dispatch({ type: LOADING_END });
								console.log('error', error);
								if (error.response.data.messages[0].mensaje.code) {
									if (
										error.response.data.messages[0].mensaje.code ===
										'ERR_INVALID_ARG_TYPE'
									) {
										toast.error(`Ha ocurrido un error, por favor inténtelo de nuevo.`);
									} else toast.error(`${error.response.data.messages[0].mensaje.code}`);
								} else if (error.response.data.messages[0].mensaje) {
									toast.error(`${error.response.data.messages[0].mensaje}`);
								} else if (
									error.response.data.mensaje.code === 'ERR_INVALID_ARG_TYPE'
								) {
									toast.error(`Ha ocurrido un error, por favor inténtelo de nuevo.`);
								} else {
									toast.error(`${error.response.data.messages[0].mensaje}`);
								}
							} else if (error.response.status === 400) {
								dispatch({ type: LOADING_END });
								console.log('error400');
								console.log(error);
								toast.error(`${error.response.data.msg}`);
							} else if (error.response.status === 401) {
								dispatch({ type: LOADING_END });
								console.log('error401');
								console.log(error);
								toast.error(`${error.response.data.msg}`);
							} else if (error.response.status === 403) {
								dispatch({ type: LOADING_END });
								console.log('error403');
								console.log(error);
								toast.error(`${error.response.data.msg}`);
							} else if (error.response.status === 404) {
								dispatch({ type: LOADING_END });
								console.log('error404');
								console.log(error);
								toast.error(`${error.response.data.msg}`);
							} else {
								dispatch({ type: LOADING_END });
								console.log(error);
								toast.error(`${error.response.data.msg}`);
							}
						});
				};
			};

			dispatch<any>(postCertificados(formData2, llavePass64, handleOpen));
		}
	};

	const isFormValid = (): boolean => {
		// El inputFile no debe estar vacío
		if (certificadoFile === '') {
			setErrorMsg('Por favor seleccione el Certificado de sellos digital');
			return false;
		} else if (llaveFile === '') {
			setErrorMsg('Por favor seleccione el Archivo de llave privada');
			return false;
		} else if (llavePass64 === '') {
			setErrorMsg('El campo contraseña no puede estar vacío');
			return false;
		}
		//Regresa estado a un string vacío
		setErrorMsg('');
		return true;
	};

	//Estuctura de datos para tabla
	const datosTabla2 = {
		columns: [
			{ Header: 'No.de Certificado', accessor: 'noCertificado', width: '20%' },
			{ Header: 'Tipo', accessor: 'tipo', width: '20%' },
			{ Header: 'Fecha Inicial', accessor: 'fechaInicial', width: '20%' },
			{ Header: 'Fecha Final', accessor: 'fechaFinal', width: '20%' },
			{ Header: 'Estado', accessor: 'estado', width: '20%' },
		],
		rows: certificadosData
			.map((item) => {
				return {
					noCertificado: item.no_certificado,
					tipo: item.tipo,
					fechaInicial: moment(item.fecha_inicial).format('DD/MM/YYYY'),
					fechaFinal: moment(item.fecha_final).format('DD/MM/YYYY'),
					estado: item.activo ? <Activo /> : <NoActivo />,
				};
			})
			.reverse(),
	};

	//Get Certificados
	useEffect(() => {
		dispatch<any>(getCertificados());
	}, []);
	//Obtener Certificados
	const getCertificados = () => {
		return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
			await getToken();
			dispatch({ type: START_LOADING });
			axiosClient
				.get(`/empresa/certificados`)
				.then((respuesta) => {
					if (respuesta.status === 200) {
						setCertificadosData(respuesta.data);
						dispatch({ type: LOADING_END });
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500', error.response.data);
						dispatch({ type: LOADING_END });
					} else if (error.response.status === 400) {
						console.log('error400', error.response.data);
						dispatch({ type: LOADING_END });
					} else if (error.response.status === 403) {
						console.log('error403', error.response.data);
						dispatch({ type: LOADING_END });
					} else if (error.response.status === 404) {
						console.log('error404', error.response.data);

						dispatch({ type: LOADING_END });
					} else {
						console.log(error.response.data);
						dispatch({ type: LOADING_END });
					}
				});
		};
	};

	//GetEmpresaById
	useEffect(() => {
		if (empresa_id) {
			const dataEmpresa = getEmpresaById(empresa_id);
			dataEmpresa
				.then((respuesta) => {
					if (respuesta.status === 200) {
						setEmpresaRFC(respuesta.data.rfc);
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response.data.message);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response.data.message);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response.data.message);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response.data.message);
					} else {
						console.log(error.response.data.message);
					}
				});
		}
	}, [empresa_id]);

	useEffect(() => {
		//Convertir a base64
		setLlavePass64(Buffer.from(llavePass, 'utf8').toString('base64'));
	}, [llavePass]);

	return (
		<DashboardLayout>
			<DashboardNavbar />

			<MDBox pt={6} pb={3}>
				<Card>
					<MDBox p={3} lineHeight={1}>
						<MDTypography variant='h5' fontWeight='medium' color='primary'>
							Certificados
						</MDTypography>
						<Grid
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='flex-end'
						>
							<MDButton size='small' color='primary' onClick={handleOpen}>
								+ Agregar
							</MDButton>
						</Grid>
					</MDBox>
					<DataTable table={datosTabla2} canSearch />
					{loading && (
						<>
							<MDBox m={4}>
								<PXLoader />
							</MDBox>
						</>
					)}
				</Card>
			</MDBox>

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<Tooltip title='Cerrar'>
						<IconButton
							onClick={() => handleClose()}
							color='secondary'
							aria-label='upload picture'
							component='label'
							style={{
								position: 'absolute',
								top: '-10px',
								right: '-10px',
								backgroundColor: '#fff',
								border: '1px solid #00ACC8',
							}}
						>
							<CloseIcon />
						</IconButton>
					</Tooltip>
					<Typography
						id='modal-modal-title'
						color={'primary'}
						variant='h6'
						component='h2'
						marginBottom={3}
					>
						Agregar Certificado de Sellos Digital (CSD)
					</Typography>
					<MDBox pb={3} px={3} component='form' role='form' onSubmit={handleSubmit}>
						<Typography
							id='modal-modal-title'
							color={'secondary'}
							component='p'
							style={{ fontSize: '12px' }}
						>
							{empresaRFC}
						</Typography>
						<MDBox mb={5} style={{ maxWidth: '350px' }}>
							<MDTypography
								component='label'
								variant='button'
								fontWeight='regular'
								color='primary'
							>
								Certificado de sellos digital.
							</MDTypography>
							<input
								className={
									errorMsg === 'Por favor seleccione el Certificado de sellos digital'
										? 'custom-fileInput custom-fileInputError'
										: 'custom-fileInput'
								}
								type='file'
								name='certificadoFile'
								id='formFileSello'
								accept='.cer'
								value={certificadoFile}
								onChange={handleInputChange}
								style={{ width: '100%' }}
							/>
						</MDBox>
						<MDBox mb={5} style={{ backgroundColor: '', maxWidth: '350px' }}>
							<MDTypography
								component='label'
								variant='button'
								fontWeight='regular'
								color='primary'
							>
								Archivo de llave privada.
							</MDTypography>
							<input
								className={
									errorMsg === 'Por favor seleccione el Archivo de llave privada'
										? 'custom-fileInput custom-fileInputError'
										: 'custom-fileInput'
								}
								type='file'
								name='llaveFile'
								value={llaveFile}
								onChange={handleInputChange}
								id='formFileLlave'
								accept='.key'
								style={{ width: '100%' }}
							/>
						</MDBox>
						<MDBox mb={5} style={{ backgroundColor: '', maxWidth: '300px' }}>
							<MDInput
								type='password'
								label='Contraseña de llave privada'
								variant='standard'
								fullWidth
								name='llavePass'
								error={
									errorMsg === 'El campo contraseña no puede estar vacío' ? true : false
								}
								value={llavePass}
								onChange={handleInputChange}
							/>
						</MDBox>
						<MDBox mb={5} style={{ backgroundColor: '', maxWidth: '300px' }}>
							{errorMsg && (
								<Typography color={'error'} component='p' style={{ fontSize: '12px' }}>
									<ErrorIcon /> {errorMsg}.
								</Typography>
							)}
						</MDBox>

						<Grid
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='flex-end'
							spacing={1}
						>
							<MDButton size='small' onClick={handleOpen}>
								Cancelar
							</MDButton>
							{loading ? (
								<MDButton type={'submit'} size='small' color='primary' disabled>
									<CircularProgress color='secondary' size={15} />
									<span style={{ marginLeft: '8px' }}>Cargando...</span>
								</MDButton>
							) : (
								<MDButton type={'submit'} size='small' color='primary'>
									Guardar
								</MDButton>
							)}
						</Grid>
					</MDBox>
				</Box>
			</Modal>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</DashboardLayout>
	);
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90vw',
	maxWidth: 800,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
};

export default Certificados;
