/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate, useParams } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

import { Grid } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import brandPixcua from 'assets/images/pixcua.png';
import { useForm } from 'hooks/useForm';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import {
	sendPassword,
	validationToken,
} from 'actions/RestablecerPassword/restablecerPasword';

function ConfirmPassword(): JSX.Element {
	const dispatch = useDispatch();
	const { passworUpdated, empresatknInfo } = useSelector(
		(state: RootStore) => state.restPass
	);
	const { id, email } = empresatknInfo;
	const { registerState } = useSelector((state: RootStore) => state.auth);

	const navigate = useNavigate();
	const params = useParams();
	const tkn = params.tkn;

	const [errorMsg, setError] = useState<string>('');
	interface iNuevoPasswordFormulario {
		password: string;
		confimpassword: string;
	}
	const usuario: iNuevoPasswordFormulario = {
		password: '',
		confimpassword: '',
	};
	//Uso de hook useForm para manejo de campos en el formulario
	const [formValues, handleInputChange] = useForm(usuario);
	const { password, confimpassword } = formValues;

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (isFormValid()) {
			console.log('enviando nuevo password');
			dispatch<any>(sendPassword(tkn, email, password));
		}
	};
	const isFormValid = (): boolean => {
		if (password.trim().length < 6) {
			setError('El password debe tener al menos 6 caracteres');
			return false;
		} else if (password !== confimpassword) {
			setError('Las contraseñas no coinciden');
			return false;
		}
		setError('');
		return true;
	};
	//Efecto que se ejecuta al cargar el componente
	useEffect(() => {
		dispatch<any>(validationToken(tkn));
	}, [dispatch, tkn]);

	//useEffect para redireccionar al login una vez se actualizo el password
	useEffect(() => {
		if (passworUpdated) {
			navigate('/');
		}
	}, [passworUpdated, navigate]);

	return (
		<MDBox pt={4} pb={3}>
			<Grid container justifyContent='center' alignItems='center'>
				<MDAvatar
					variant='square'
					style={{ marginTop: '2rem', width: '9rem' }}
					src={brandPixcua}
					alt='Avatar'
				/>
			</Grid>
			{!!id ? (
				<Grid
					display={'flex'}
					alignItems={'center'}
					justifyContent={'center'}
					style={{ marginTop: '2.5rem', flexWrap: 'wrap' }}
				>
					<Card style={{ minWidth: '300px', maxWidth: '400px', margin: '5px' }}>
						<MDBox pt={4} pb={3} px={3}>
							<MDBox pt={1} pb={3} textAlign='center'>
								<MDTypography color='secondary'>
									INGRESA UNA NUEVA CONTRASEÑA PARA ACCEDER AL SISTEMA.
								</MDTypography>
								<MDTypography display='block' fontSize={'1.5rem'} color='primary'>
									{email}
								</MDTypography>
							</MDBox>
							<MDBox component='form' role='form' onSubmit={handleSubmit}>
								<MDBox mb={2}>
									<MDInput
										type='password'
										label='Contraseña'
										variant='standard'
										fullWidth
										name='password'
										value={password}
										onChange={handleInputChange}
										required
									/>
								</MDBox>
								<MDBox mb={2}>
									<MDInput
										type='password'
										label='Confirma contraseña'
										variant='standard'
										fullWidth
										name='confimpassword'
										value={confimpassword}
										onChange={handleInputChange}
										required
									/>
								</MDBox>

								<MDBox mb={2}>
									{errorMsg && (
										<MDTypography
											variant='text'
											color='error'
											fontWeight='light'
											textGradient
											style={{ fontSize: '12px' }}
										>
											{errorMsg}.
										</MDTypography>
									)}
								</MDBox>

								<MDBox mt={4} mb={1}>
									{!registerState.loading ? (
										<MDButton variant='gradient' color='primary' fullWidth type='submit'>
											Enviar
										</MDButton>
									) : (
										<MDButton variant='gradient' color='primary' fullWidth disabled>
											Enviando...
										</MDButton>
									)}
								</MDBox>
							</MDBox>
						</MDBox>
					</Card>
				</Grid>
			) : (
				<MDBox pt={4} mt={5} textAlign='center'>
					<MDTypography variant='h4' fontWeight='medium' color='text'>
						PÁGINA NO ENCONTRADA
					</MDTypography>
					<MDTypography variant='button' color='text'>
						volver a{' '}
						<MDTypography
							component={Link}
							to='/login'
							variant='button'
							color='primary'
							fontWeight='medium'
							textGradient
						>
							Inicio
						</MDTypography>
					</MDTypography>
				</MDBox>
			)}
		</MDBox>
	);
}

export default ConfirmPassword;
