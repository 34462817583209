import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { toast } from 'react-toastify';
import BtnEliminar from '../CrearComprobante/components/BtnEliminar';
import { getEmpresaById } from 'actions/MiCuenta/miCuenta';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { RootStore } from 'store/Store';
import SeleccionaComprobante from 'pantallas/Comprobantes/components/seleccionComprobante/index';
import CrearComprobante from 'pantallas/Comprobantes/components/CrearComprobante/index';
import Extras from 'pantallas/Comprobantes/components/Extras/index';
import Cliente from 'pantallas/Comprobantes/components/Cliente/index';
import { cliente, initcliente } from 'actions/Clientes/clientesTypes';
import { numeroALetras } from 'pantallas/Comprobantes/Data/acciones/components/PDF/numeroaletra';
import { getClienteById, getClientesBySearch } from 'actions/Clientes/clientes';
import { numeroAMoneda } from 'helpers/aMoneda';
import { axiosClient, getToken } from 'Config/axiosConfig';
import Swal from 'sweetalert2';
import {
	iComprobante,
	iComprobante_detalle,
	initComprobanteDetalle,
	initComprobanteDetalleList,
	initExtras,
	initTotales,
} from 'actions/Comprobante/comprobanteTypes';

import {
	getDocRelacionadoByComprobanteId,
	postComprobante,
	putComprobante,
} from 'actions/Comprobante/comprobante';

import {
	LoadingDispatchTypes,
	LOADING_END,
	START_LOADING,
} from 'actions/Loading/loadingTypes';
import { IconButton, Tooltip } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

interface props {
	handleClose: any;
	comprobanteToUpdate: iComprobante;
	setComprobantesListData: any;
}
function Wizard({
	handleClose,
	comprobanteToUpdate,
	setComprobantesListData,
}: props): JSX.Element {
	const { empresa_id } = useSelector((state: RootStore) => state.auth);
	const dispatch = useDispatch();

	const [activeStep, setActiveStep] = useState<number>(0);
	const [option, setOption] = useState('');
	const [steps, setsteps] = useState([]);
	const isLastStep: boolean = activeStep === steps.length - 1;
	const [tipoComprobanteId, setTipoComprobanteId] = useState(0);
	const [tipoComprobante, setTipoComprobante] = useState('');
	const [isUpdatingData, setIsUpdatingData] = useState(false);
	const [clienteData, setClienteData] = useState<cliente>(initcliente);
	const [usoFacturaValue, setUsoFacturaValue] = useState({ usoDeLaFactura: '' });
	const [comprobanteDetalleList, setComprobanteDetalleList] = useState(
		initComprobanteDetalleList
	);
	const [porcentajeDescuento, setPorcentajeDescuento] = useState({
		porcentajeDescuento: 0,
	});
	const handleInputPorcentajeChange = ({ target }: any) => {
		setPorcentajeDescuento({
			...porcentajeDescuento,
			[target.name]: target.value,
		});
	};
	const [totales, setTotales] = useState(initTotales);
	const [extras, setExtras] = useState(initExtras);
	const [empresaData, setMiEmpresaData] = useState({
		regimen_fiscal: '',
		rfc: '',
	});
	const [checkedDocRelacionado, setCheckedDocRelacionado] = useState(false);
	const [miTipoContribuyente, setMiTipoContribuyente] =
		useState('Persona Fisica');
	const personaMoral: string = 'Persona Moral';
	const personaFisica: string = 'Persona Fisica';
	const resicoId: string = '626';
	const personaFisicaConActividadesEmpresarialesId: string = '612';
	const arrayClientesInitialState: any = [];
	const [arrayClientes, setArrayClientes] = useState(arrayClientesInitialState);
	const [docRelacionados, setdocRelacionados] = useState({
		tipo_relacion_id: '',
		comprobante_relacionado_uuid: '',
	});

	//Checked de retencion_iva
	const [retencionIVAchecked, setRetencionIVAChecked] = React.useState(false);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRetencionIVAChecked(event.target.checked);
	};
	//Checked de retencion_isr
	const [retencionISRchecked, setRetencionISRChecked] = React.useState(false);
	const handleChangeISR = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRetencionISRChecked(event.target.checked);
	};

	const retencionesON = (): any => {
		setRetencionIVAChecked(true);
		setRetencionISRChecked(true);
	};
	const retencionesOFF = (): any => {
		setRetencionIVAChecked(false);
		setRetencionISRChecked(false);
	};

	//Efecto que determina si es un update o un registro nuevo
	useEffect(() => {
		if (comprobanteToUpdate) {
			setIsUpdatingData(true);
			setsteps(['Paso 1: Cliente', 'Paso 2: Conceptos', 'Paso 3: Extra']);
			//NOTA: PEDIR LOS DATOS DEL COMPROBANTE

			setTipoComprobanteId(comprobanteToUpdate.tipo_comprobante_id);
			setTipoComprobante(comprobanteToUpdate.tipo_de_comprobante);
			if (comprobanteToUpdate.tipo_comprobante_id === 1) {
				setOption('Factura');
			}
			if (comprobanteToUpdate.tipo_comprobante_id === 2) {
				setOption('Recibo de honorarios');
			}
			setClienteData({
				id: comprobanteToUpdate.cliente_id,
				empresa_id: comprobanteToUpdate.empresa_i,
				nombre: comprobanteToUpdate.nombre,
				rfc: comprobanteToUpdate.rfc,
				calle: comprobanteToUpdate.calle,
				no_exterior: comprobanteToUpdate.no_exterior,
				no_interior: comprobanteToUpdate.no_interior,
				colonia: comprobanteToUpdate.colonia,
				localidad: comprobanteToUpdate.localidad,
				referencia: comprobanteToUpdate.referencia,
				municipio: comprobanteToUpdate.municipio,
				estado: comprobanteToUpdate.estado,
				pais: comprobanteToUpdate.pais,
				codigo_postal: comprobanteToUpdate.codigo_postal,
				telefono: comprobanteToUpdate.telefono,
				email: comprobanteToUpdate.email,
				metodo_de_pago: comprobanteToUpdate.metodo_de_pago,
				forma_de_pago: comprobanteToUpdate.forma_de_pago,
				activo: false,
				c_regimen_fiscal_Cliente: comprobanteToUpdate.c_regimen_fiscal_Cliente,
			});

			setUsoFacturaValue({ usoDeLaFactura: comprobanteToUpdate.tipo_uso });
			setComprobanteDetalleList([...comprobanteToUpdate.comprobantesDetalles]);
		} else {
			setIsUpdatingData(false);
			setsteps([
				'Paso 1: Seleccion de Comprobantes',
				'Paso 2: Cliente',
				'Paso 3: Conceptos',
				'Paso 4: Extra',
			]);
		}
	}, []);
	useEffect(() => {
		if (isUpdatingData) {
			setExtras({
				...extras,
				lugar_expedicion: comprobanteToUpdate.lugar_expedicion,
				num_cta_pago: comprobanteToUpdate.num_cta_pago,
				moneda: comprobanteToUpdate.moneda,
				tipo_cambio: comprobanteToUpdate.tipo_cambio,
			});
		}
	}, [isUpdatingData, comprobanteToUpdate]);

	//Efecto para obtener los datos del Cliente  cuando isUpdatingData cambia
	useEffect(() => {
		if (isUpdatingData) {
			//Get ClienteById
			const updateInfo = async () => {
				const data = getClienteById(comprobanteToUpdate.cliente_id);
				await data
					.then((respuesta) => {
						if (respuesta.status === 200) {
							setClienteData({
								...clienteData,
								tipo_Persona: respuesta.data.tipo_Persona,
								telefono: respuesta.data.telefono,
								email: respuesta.data.email,
							});
						}
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500');
							console.log(error.response.data);
						} else if (error.response.status === 400) {
							console.log('error400');
							console.log(error.response.data);
						} else if (error.response.status === 403) {
							console.log('error403');
							console.log(error.response.data);
						} else if (error.response.status === 404) {
							console.log('error404');
							console.log(error.response.data);
						} else {
							console.log(error.response.data);
						}
					});
				setTotales({
					...totales,
					subtotal: Number(comprobanteToUpdate.subtotal),
					total: Number(comprobanteToUpdate.total),
					descuento: Number(comprobanteToUpdate.descuento),
				});
				//Aqui estaba setExtras
			};
			updateInfo();
		}
	}, [isUpdatingData]);

	//Efecto para documentos relacionados
	useEffect(() => {
		const getInfoDocRelacionado = async () => {
			const comprobanteDocRelacionadoData = getDocRelacionadoByComprobanteId(
				comprobanteToUpdate.id
			);
			await comprobanteDocRelacionadoData
				.then((respuesta) => {
					if (respuesta.status === 200) {
						setCheckedDocRelacionado(true);
						setdocRelacionados({
							tipo_relacion_id: respuesta.data.tipo_relacion_id,
							comprobante_relacionado_uuid:
								respuesta.data.tipoRelacionesComprobante.uuid,
						});
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response.data.message);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response.data.message);
					} else if (error.response.status === 401) {
						console.log('error401');
						console.log(error.response.data);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response.data.message);
					} else if (error.response.status === 404) {
						console.log('Comprobante SIN DocRelacionado::: error404');
						console.log(error.response.data.message);
					} else {
						console.log(error.response.data.message);
					}
				});
			setExtras({
				...extras,
				tipo_relacion_id: docRelacionados.tipo_relacion_id,
				comprobante_relacionado_uuid: docRelacionados.comprobante_relacionado_uuid,
				lugar_expedicion: comprobanteToUpdate.lugar_expedicion,
				num_cta_pago: comprobanteToUpdate.num_cta_pago,
				moneda: comprobanteToUpdate.moneda,
				tipo_cambio: comprobanteToUpdate.tipo_cambio,
			});
		};

		if (isUpdatingData) {
			getInfoDocRelacionado();
		}
	}, [
		isUpdatingData,
		docRelacionados.tipo_relacion_id,
		docRelacionados.comprobante_relacionado_uuid,
	]);

	const cleanExtrasDocRelacionados = () => {
		setExtras({
			...extras,
			comprobante_relacionado_uuid: '',
			tipo_relacion_id: '',
		});
	};

	//efectto para limpiar Extras Doc.Relacionados
	useEffect(() => {
		if (!checkedDocRelacionado) cleanExtrasDocRelacionados();
	}, [checkedDocRelacionado]);

	//Debouncing para obtener el autocompletado de clientes
	useEffect(() => {
		let timeOutSearch = setTimeout(() => {
			if (clienteData.nombre.length === 0) {
				setClienteData(initcliente);
			}
			if (clienteData.nombre.length > 0) {
				//Get ClienteBySearch

				const data = getClientesBySearch(clienteData.nombre);
				data
					.then((respuesta) => {
						if (respuesta.status === 200) {
							setArrayClientes(respuesta.data);
						}
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500');
							console.log(error.response.data.message);
						} else if (error.response.status === 400) {
							console.log('error400');
							console.log(error.response.data.message);
						} else if (error.response.status === 403) {
							console.log('error403');
							console.log(error.response.data.message);
						} else if (error.response.status === 404) {
							console.log('error404');
							setArrayClientes([]);
						} else {
							console.log(error.response.data.message);
						}
					});
			}
		}, 500);

		return () => {
			clearTimeout(timeOutSearch);
		};
	}, [clienteData.nombre]);

	const clienteAsignacion = () => {
		if (arrayClientes.length > 0) {
			const clienteDataFound: any = arrayClientes.find(
				(item: any) => item.nombre === clienteData.nombre
			);
			if (clienteDataFound) setClienteData(clienteDataFound);
		}
	};

	useEffect(() => {
		clienteAsignacion();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clienteData.nombre, arrayClientes]);

	//Efecto para determinar initialState de Reglas de retenciones
	useEffect(() => {
		if (!isUpdatingData) {
			switch (tipoComprobanteId) {
				case 1: //Factura
					retencionesOFF();
					break;
				case 2: //Recibo Honorarios
					retencionesOFF();
					break;
				case 3: //Otro
					console.log('Opcion 3');
					break;

				default:
					break;
			}
		} else {
			if (comprobanteToUpdate.retencion_iva > 0) setRetencionIVAChecked(true);
			else setRetencionIVAChecked(false);
			if (comprobanteToUpdate.retencion_isr > 0) setRetencionISRChecked(true);
			else setRetencionISRChecked(false);
		}
	}, [clienteData.tipo_Persona, isUpdatingData]);

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};
	const handleBack = () => setActiveStep(activeStep - 1);

	const handleOption = (seleccion: string) => {
		setActiveStep(activeStep + 1);
		setOption(seleccion);
		if (seleccion === 'Factura') {
			setTipoComprobanteId(1);
			setTipoComprobante('I');
		} else if (seleccion === 'Recibo de honorarios') {
			setTipoComprobanteId(2);
			setTipoComprobante('I');
		} else if (seleccion === 'Nota de credito') {
			setTipoComprobanteId(3);
			setTipoComprobante('E');
		} else if (seleccion === 'Recibo de arrendamiento') {
			setTipoComprobanteId(4);
			setTipoComprobante('I');
		} else if (seleccion === 'Nota de cargo') {
			setTipoComprobanteId(6);
			setTipoComprobante('I');
		}
	};

	const handleInputChange = ({ target }: any) => {
		setClienteData({
			...clienteData,
			[target.name]: target.value,
		});
	};
	const handleInputChangeUsoFactura = ({ target }: any) => {
		setUsoFacturaValue({
			...usoFacturaValue,
			[target.name]: target.value,
		});
	};

	//---Logica para DetalleComprobante---
	const [conceptoIdValue, setConceptoIdValue] = useState<string | null>('');
	const [inputConceptoIdValue, setInputConceptoIdValue] = React.useState('');
	const [inputConceptoDesc, setInputConceptoDesc] = React.useState('');
	const [unidadIdValue, setUnidadIdValue] = useState<string | null>('');
	const [inputUnidadIdValue, setInputUnidadIdValue] = React.useState('');
	const [inputUnidadDesc, setInputUnidadDesc] = React.useState('');
	const [formConceptosValue, setFormConceptosValue] = useState(
		initComprobanteDetalle
	);

	const resetConceptos = () => {
		setFormConceptosValue(initComprobanteDetalle);
		setInputConceptoDesc('');
		setInputConceptoIdValue('');
		setConceptoIdValue('');
		setUnidadIdValue('');
		setInputUnidadIdValue('');
		setInputUnidadDesc('');
	};
	const handleInputConceptosChange = ({ target }: any) => {
		setFormConceptosValue({
			...formConceptosValue,
			[target.name]: target.value,
		});
	};
	const handleInputTotalesChange = ({ target }: any) => {
		setTotales({
			...totales,
			[target.name]: target.value,
		});
	};

	//Boton agregar detalle de comprobante a la tabla
	const btnAdd = () => {
		if (isFormValid()) {
			if (formConceptosValue.tasa_iva === 'IVA exento') {
				setComprobanteDetalleList([
					...comprobanteDetalleList,
					{
						concepto_sat_id: formConceptosValue.concepto_sat_id,
						cantidad: formConceptosValue.cantidad,
						unidad_sat_id: formConceptosValue.unidad_sat_id,
						unidad: formConceptosValue.unidad,
						concepto: formConceptosValue.concepto,
						precio_unitario: formConceptosValue.precio_unitario,
						importe: formConceptosValue.importe,
						tasa_iva: null,
						numero_identificacion: formConceptosValue.numero_identificacion,
					},
				]);
			} else {
				setComprobanteDetalleList([...comprobanteDetalleList, formConceptosValue]);
			}
			resetConceptos();
		}
	};
	//Validacion para agregar Detalle de comprobante
	const isFormValid = (): boolean => {
		if (!clienteData.rfc) {
			toast.warning(
				'Es necesario ingresar un rfc del cliente en el paso anterior'
			);
			return false;
		} else if (!clienteData.c_regimen_fiscal_Cliente) {
			toast.warning(
				'Es necesario ingresar el "Regimen Fiscal" del cliente en el paso anterior'
			);
			return false;
		} else if (!clienteData.tipo_Persona) {
			toast.warning(
				'Es necesario ingresar el "Tipo Contribuyente" del cliente en el paso anterior'
			);
			return false;
		} else if (
			!formConceptosValue.concepto_sat_id ||
			!formConceptosValue.unidad_sat_id ||
			!formConceptosValue.unidad ||
			!formConceptosValue.concepto ||
			formConceptosValue.cantidad === 0 ||
			formConceptosValue.precio_unitario === 0
		) {
			toast.error('Todos los campos son necesarios.');
			return false;
		} else if (!usoFacturaValue.usoDeLaFactura) {
			toast.warning('Es necesario ingresar el "Uso de la factura" ');
			return false;
		}

		toast.success('Agregado.');
		return true;
	};
	// Get Datos Mi Empresa
	useEffect(() => {
		const miEmpresaData = getEmpresaById(empresa_id);
		miEmpresaData
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setMiEmpresaData({
						rfc: respuesta.data.rfc,
						regimen_fiscal: respuesta.data.regimen_fiscal,
					});
					if (respuesta.data.codigo_postal) {
						setExtras({ ...extras, lugar_expedicion: respuesta.data.codigo_postal });
					}
					if (respuesta.data.rfc.length === 12) {
						setMiTipoContribuyente('Persona Moral');
					} else if (respuesta.data.rfc.length === 13) {
						setMiTipoContribuyente('Persona Fisica');
					} else {
						console.log('Error de Mi RFC');
					}
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data.message);
					Swal.fire({
						width: '35rem',
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.message}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else {
					console.log(error.response.data.message);
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Estuctura de datos para TABLA
	const datosTablaDetalleComprobante = {
		columns: [
			{ Header: 'Acciones', accessor: 'acciones' },
			{ Header: 'Numero identificacion', accessor: 'numeroIdentificacion' },
			{ Header: 'Cantidad', accessor: 'cantidad' },
			{ Header: 'Clave unidad', accessor: 'claveUnidad' },
			{ Header: 'Unidad', accessor: 'unidad' },
			{ Header: 'Clave concepto', accessor: 'claveConcepto' },
			{ Header: 'Concepto', accessor: 'concepto' },
			{ Header: 'P.U.', accessor: 'pU' },
			{ Header: 'Importe', accessor: 'importe' },
		],
		rows: comprobanteDetalleList.map((item, index) => {
			return {
				acciones: (
					<BtnEliminar
						item={item}
						index={index}
						comprobanteDetalleList={comprobanteDetalleList}
						setComprobanteDetalleList={setComprobanteDetalleList}
					/>
				),
				numeroIdentificacion: item.numero_identificacion,
				cantidad: item.cantidad,
				claveUnidad: item.unidad_sat_id,
				unidad: item.unidad,
				claveConcepto: item.concepto_sat_id,
				concepto: item.concepto,
				pU: <>{numeroAMoneda(Number(item.precio_unitario))}</>,
				importe: <>{numeroAMoneda(Number(item.importe))}</>,
			};
		}),
	};

	//---Logica para TOTALES (Comprobantes)---
	//Totales
	const subtotal = comprobanteDetalleList
		.map((item: any) => {
			if (typeof item.importe === 'string') {
				return parseFloat(item.importe);
			} else {
				return item.importe;
			}
		})
		.reduce((prev: any, curr: any) => prev + curr, 0);

	const comprobanteDetalleListConIVA = comprobanteDetalleList.filter(
		(item: any) => item.tasa_iva === 16
	);
	const subTotalConIVA = comprobanteDetalleListConIVA
		.map((item: any) => item.importe)
		.reduce((prev: any, curr: any) => prev + curr, 0);

	const retencionIVAFix2 = comprobanteDetalleListConIVA
		.map((item: any) => {
			return (
				Math.floor((Math.floor(item.importe * 100) / 100) * 0.106667 * 100) / 100
			);
		})
		.reduce((prev: any, curr: any) => prev + curr, 0);

	const IVA = subTotalConIVA * 0.16;
	// Retencion IVA & ISR
	const retencionIsr = (subtotal * 0.1).toFixed(2);
	const retencionIsrRESICO = (subtotal * 0.0125).toFixed(2);

	const total =
		totales.subtotal +
		parseFloat(IVA.toFixed(2)) -
		totales.retencion_isr -
		totales.retencion_iva -
		totales.descuento;

	//Effect - Subtotal
	useEffect(() => {
		setTotales({ ...totales, subtotal: parseFloat(subtotal.toFixed(2)) });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [comprobanteDetalleList]);
	//Porcentaje de Descuento
	useEffect(() => {
		if (porcentajeDescuento.porcentajeDescuento > 100) {
			toast.error('Porcentaje no puede ser mayor a 100%');
			setPorcentajeDescuento({ porcentajeDescuento: 100 });
		} else {
			const descuento =
				totales.subtotal * (porcentajeDescuento.porcentajeDescuento / 100);
			setTotales({ ...totales, descuento: parseFloat(descuento.toFixed(2)) });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [porcentajeDescuento, totales.subtotal]);
	// //Cantidad de Descuento
	useEffect(() => {
		//Deboucing para teclear la cantidad
		let timeOutSearch = setTimeout(() => {
			if (totales.descuento !== 0) {
				if (totales.descuento > totales.subtotal) {
					toast.error(
						`Cantidad de descuento no puede ser mayor a $ ${totales.subtotal}`
					);
					setTotales({ ...totales, descuento: totales.subtotal });
				} else {
					const porcentajeDescuento = (totales.descuento / totales.subtotal) * 100;
					setPorcentajeDescuento({
						porcentajeDescuento: porcentajeDescuento,
					});
				}
			}
		}, 500);
		return () => {
			clearTimeout(timeOutSearch);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totales.descuento]);
	//Effect - I.V.A
	useEffect(() => {
		setTotales({
			...totales,
			iva: parseFloat(IVA.toFixed(2)),
			total: parseFloat(total.toFixed(2)),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		totales.subtotal,
		totales.descuento,
		totales.retencion_iva,
		totales.retencion_isr,
	]);

	const aplicaRetencionIVA = () => {
		setTotales({
			...totales,
			retencion_iva: parseFloat(retencionIVAFix2.toFixed(2)),
			total: parseFloat(total.toFixed(2)),
			iva: parseFloat(IVA.toFixed(2)),
		});
	};
	const noAplicaRetencionIVA = () => {
		setTotales({
			...totales,
			retencion_iva: 0,
			total: parseFloat(total.toFixed(2)),
			iva: parseFloat(IVA.toFixed(2)),
		});
	};
	const aplicaRetencionISR = () => {
		setTotales({
			...totales,
			retencion_isr: parseFloat(retencionIsr),
			total: parseFloat(total.toFixed(2)),
			iva: parseFloat(IVA.toFixed(2)),
		});
	};
	const aplicaRetencionResicoISR = () => {
		setTotales({
			...totales,
			retencion_isr: parseFloat(retencionIsrRESICO),
			total: parseFloat(total.toFixed(2)),
			iva: parseFloat(IVA.toFixed(2)),
		});
	};
	const noAplicaRetencionISR = () => {
		setTotales({
			...totales,
			retencion_isr: 0,
			total: parseFloat(total.toFixed(2)),
			iva: parseFloat(IVA.toFixed(2)),
		});
	};

	// Effect - Retencion IVA
	useEffect(() => {
		switch (tipoComprobanteId) {
			case 1:
			case 2: //Factura & Recibo Honorarios
				if (retencionIVAchecked) aplicaRetencionIVA();
				else noAplicaRetencionIVA();
				break;
			case 3: //Otro
				console.log('Retencion IVA Opcion 3');
				break;

			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [retencionIVAchecked, totales.subtotal, totales.retencion_isr]);

	// Effect - Retencion ISR
	useEffect(() => {
		switch (tipoComprobanteId) {
			case 1:
			case 2: //Factura &  Recibo Honorarios
				if (retencionISRchecked) {
					if (empresaData.regimen_fiscal === resicoId) aplicaRetencionResicoISR();
					else aplicaRetencionISR();
				} else noAplicaRetencionISR();
				break;
			case 3: //Otro ...
				console.log('Retencion ISR opcion 3...');
				break;

			default:
				break;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		retencionISRchecked,
		totales.subtotal,
		clienteData.c_regimen_fiscal_Cliente,
	]);

	// ---Logica para Extras---
	const handleInputChangeExtras = ({ target }: any) => {
		setExtras({
			...extras,
			[target.name]: target.value,
		});
	};

	//---Pasos para wizard---
	const getStepContent = (stepIndex: number) => {
		if (isUpdatingData) {
			switch (stepIndex) {
				case 0:
					return (
						<Cliente
							formValue={clienteData}
							setFormValue={setClienteData}
							handleInputChange={handleInputChange}
							usoFacturaValue={usoFacturaValue}
							setUsoFacturaValue={setUsoFacturaValue}
							handleInputChangeUsoFactura={handleInputChangeUsoFactura}
							isUpdatingData={isUpdatingData}
							arrayClientes={arrayClientes}
						/>
					);
				case 1:
					return (
						<CrearComprobante
							selectOption={option}
							formConceptosValue={formConceptosValue}
							setFormConceptosValue={setFormConceptosValue}
							handleInputConceptosChange={handleInputConceptosChange}
							btnAdd={btnAdd}
							datosTablaDetalleComprobante={datosTablaDetalleComprobante}
							totales={totales}
							setTotales={setTotales}
							comprobanteDetalleList={comprobanteDetalleList}
							handleInputTotalesChange={handleInputTotalesChange}
							usoFacturaValue={usoFacturaValue}
							setUsoFacturaValue={setUsoFacturaValue}
							handleInputChangeUsoFactura={handleInputChangeUsoFactura}
							checked={retencionIVAchecked}
							handleChange={handleChange}
							checkedIsr={retencionISRchecked}
							handleChangeISR={handleChangeISR}
							porcentajeDescuento={porcentajeDescuento}
							handleInputPorcentajeChange={handleInputPorcentajeChange}
							conceptoIdValue={conceptoIdValue}
							setConceptoIdValue={setConceptoIdValue}
							inputConceptoIdValue={inputConceptoIdValue}
							setInputConceptoIdValue={setInputConceptoIdValue}
							inputConceptoDesc={inputConceptoDesc}
							setInputConceptoDesc={setInputConceptoDesc}
							unidadIdValue={unidadIdValue}
							setUnidadIdValue={setUnidadIdValue}
							inputUnidadIdValue={inputUnidadIdValue}
							setInputUnidadIdValue={setInputUnidadIdValue}
							inputUnidadDesc={inputUnidadDesc}
							setInputUnidadDesc={setInputUnidadDesc}
						/>
					);
				case 2:
					return (
						<Extras
							extras={extras}
							handleInputChangeExtras={handleInputChangeExtras}
							checkedDocRelacionado={checkedDocRelacionado}
							setCheckedDocRelacionado={setCheckedDocRelacionado}
							clienteData={clienteData}
						/>
					);
				default:
					return null;
			}
		} else {
			switch (stepIndex) {
				case 0:
					return (
						<SeleccionaComprobante
							siguientePantalla={(select: string) => handleOption(select)}
							soyPersonaFisica={miTipoContribuyente}
							empresaData={empresaData}
						/>
					);
				case 1:
					return (
						<Cliente
							formValue={clienteData}
							setFormValue={setClienteData}
							handleInputChange={handleInputChange}
							usoFacturaValue={usoFacturaValue}
							setUsoFacturaValue={setUsoFacturaValue}
							handleInputChangeUsoFactura={handleInputChangeUsoFactura}
							isUpdatingData={isUpdatingData}
							arrayClientes={arrayClientes}
						/>
					);
				case 2:
					return (
						<CrearComprobante
							selectOption={option}
							formConceptosValue={formConceptosValue}
							setFormConceptosValue={setFormConceptosValue}
							handleInputConceptosChange={handleInputConceptosChange}
							btnAdd={btnAdd}
							datosTablaDetalleComprobante={datosTablaDetalleComprobante}
							totales={totales}
							setTotales={setTotales}
							comprobanteDetalleList={comprobanteDetalleList}
							handleInputTotalesChange={handleInputTotalesChange}
							usoFacturaValue={usoFacturaValue}
							setUsoFacturaValue={setUsoFacturaValue}
							handleInputChangeUsoFactura={handleInputChangeUsoFactura}
							checked={retencionIVAchecked}
							handleChange={handleChange}
							checkedIsr={retencionISRchecked}
							handleChangeISR={handleChangeISR}
							porcentajeDescuento={porcentajeDescuento}
							handleInputPorcentajeChange={handleInputPorcentajeChange}
							conceptoIdValue={conceptoIdValue}
							setConceptoIdValue={setConceptoIdValue}
							inputConceptoIdValue={inputConceptoIdValue}
							setInputConceptoIdValue={setInputConceptoIdValue}
							inputConceptoDesc={inputConceptoDesc}
							setInputConceptoDesc={setInputConceptoDesc}
							unidadIdValue={unidadIdValue}
							setUnidadIdValue={setUnidadIdValue}
							inputUnidadIdValue={inputUnidadIdValue}
							setInputUnidadIdValue={setInputUnidadIdValue}
							inputUnidadDesc={inputUnidadDesc}
							setInputUnidadDesc={setInputUnidadDesc}
						/>
					);
				case 3:
					return (
						<Extras
							extras={extras}
							handleInputChangeExtras={handleInputChangeExtras}
							checkedDocRelacionado={checkedDocRelacionado}
							setCheckedDocRelacionado={setCheckedDocRelacionado}
							clienteData={clienteData}
						/>
					);
				default:
					return null;
			}
		}
	};
	//GET Comprobantes
	const getComprobantesAsync = () => {
		return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
			await getToken();
			dispatch({ type: START_LOADING });
			axiosClient
				.get(`/comprobantes/`)
				.then((respuesta) => {
					setComprobantesListData(respuesta.data);
					dispatch({ type: LOADING_END });
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500', error.response.data);
						dispatch({ type: LOADING_END });
					} else if (error.response.status === 400) {
						console.log('error400', error.response.data);
						dispatch({ type: LOADING_END });
					} else if (error.response.status === 403) {
						console.log('error403', error.response.data);
						dispatch({ type: LOADING_END });
					} else if (error.response.status === 404) {
						console.log('error404', error.response.data);
						setComprobantesListData([]);
						dispatch({ type: LOADING_END });
					} else {
						console.log(error.response.data);
						dispatch({ type: LOADING_END });
					}
				});
		};
	};

	const esTasaDiesiseis = (): boolean => {
		const response = comprobanteDetalleList.some(
			(item: iComprobante_detalle) => item.tasa_iva === 16
		);

		if (response) return true;
		else return false;
	};
	const esTasaCero = (): boolean => {
		const response = comprobanteDetalleList.some(
			(item: iComprobante_detalle) => item.tasa_iva === 0 || item.tasa_iva === '0'
		);

		if (response) return true;
		else return false;
	};
	const esTasaExcenta = (): boolean => {
		const response = comprobanteDetalleList.some(
			(item: iComprobante_detalle) => item.tasa_iva === null
		);

		if (response) return true;
		else return false;
	};

	// const [tipoTasaValue, setTipoTasaValue] = useState<number | null>(16);
	const tipoTasa = () => {
		if (esTasaDiesiseis()) return 16;
		else if (esTasaCero()) return 0;
		else if (esTasaExcenta()) return null;
	};

	const [enviandoValue, setEnviandoValue] = useState(false);

	// handdle Enviar
	const enviarComprobante = () => {
		console.log('ENVIANDO_t_T::', tipoTasa());

		const comprobanteData: iComprobante = {
			tipo_comprobante_id: tipoComprobanteId,
			tipo_de_comprobante: tipoComprobante,
			tipo_uso: usoFacturaValue.usoDeLaFactura,
			c_regimen_fiscal_Cliente: clienteData.c_regimen_fiscal_Cliente,
			c_regimen_fiscal_Empresa: empresaData.regimen_fiscal,
			cliente_id: clienteData.id,
			nombre: clienteData.nombre.trim(),
			rfc: clienteData.rfc.trim(),
			calle: clienteData.calle,
			no_exterior: clienteData.no_exterior,
			no_interior: clienteData.no_interior,
			pais: clienteData.pais,
			estado: clienteData.estado,
			localidad: clienteData.localidad,
			municipio: clienteData.municipio,
			colonia: clienteData.colonia,
			codigo_postal: clienteData.codigo_postal.trim(),
			referencia: clienteData.referencia,
			telefono: clienteData.telefono,
			email: clienteData.email,
			forma_de_pago: clienteData.forma_de_pago,
			metodo_de_pago: clienteData.metodo_de_pago,
			tasa: tipoTasa(),
			subtotal: totales.subtotal,
			subtotal_iva_cero: 0.0,
			descuento: totales.descuento,
			motivo_de_descuento: totales.motivo_de_descuento,
			iva: totales.iva,
			retencion_iva: totales.retencion_iva,
			retencion_isr: totales.retencion_isr,
			total: totales.total,
			cantidad_letra: numeroALetras(totales.total),
			lugar_expedicion: extras.lugar_expedicion,
			num_cta_pago: extras.num_cta_pago,
			moneda: extras.moneda,
			tipo_cambio: extras.tipo_cambio ? Number(extras.tipo_cambio) : null,
			comprobantesDetalles: comprobanteDetalleList,
			tipo_Persona: clienteData.tipo_Persona,
			tipo_relacion_id: extras.tipo_relacion_id,
			comprobante_relacionado_uuid: extras.comprobante_relacionado_uuid,
		};
		if (isWizardValid()) {
			setEnviandoValue(true);
			console.log('EnviandoComprobante:', comprobanteData);
			if (isUpdatingData) {
				const putComprobantes = putComprobante(
					comprobanteData,
					comprobanteToUpdate.id
				);
				putComprobantes
					.then((respuesta) => {
						if (respuesta.status === 201) {
							toast.success('¡Comprobante actualizado!');
							setTimeout(() => {
								handleClose();
								setEnviandoValue(false);
							}, 1500);
							dispatch<any>(getComprobantesAsync());
						}
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500');
							console.log(error.response.data.message);
							toast.error(error.response.data.message);
							setEnviandoValue(false);
						} else if (error.response.status === 400) {
							console.log('error400');
							console.log(error.response.data);
							toast.error(error.response.data.message);
							setEnviandoValue(false);
						} else if (error.response.status === 403) {
							console.log('error403');
							console.log(error.response.data.message);
							toast.error(error.response.data.message);
							setEnviandoValue(false);
						} else if (error.response.status === 404) {
							if (
								error.response.data.message ===
								'La empresa no cuenta con un certificado activo'
							) {
								console.log('error404');
								toast.error(
									'La empresa no cuenta con un certificado activo. Registra tu certificado en: Configuración / Certificados /+Agregar'
								);
								setEnviandoValue(false);
								console.log(error.response.data);
							} else {
								console.log('error404');
								toast.error(error.response.data.message);
								console.log(error.response.data);
								setEnviandoValue(false);
							}
						} else {
							console.log(error.response.data.message);
							toast.error(error.response.data.message);
							setEnviandoValue(false);
						}
					});
			} else {
				const postComprobantes = postComprobante(comprobanteData);
				postComprobantes
					.then((respuesta) => {
						if (respuesta.status === 201) {
							toast.success('¡Comprobante creado!');
							setTimeout(() => {
								handleClose();
								setEnviandoValue(false);
							}, 1500);

							dispatch<any>(getComprobantesAsync());
						}
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500');
							console.log(error.response.data.message);
							toast.error(error.response.data.message);
							setEnviandoValue(false);
						} else if (error.response.status === 400) {
							console.log('error400');
							console.log(error.response.data);
							toast.error(error.response.data.message);
							setEnviandoValue(false);
						} else if (error.response.status === 403) {
							console.log('error403');
							console.log(error.response.data.message);
							toast.error(error.response.data.message);
							setEnviandoValue(false);
						} else if (error.response.status === 404) {
							if (
								error.response.data.message ===
								'La empresa no cuenta con un certificado activo'
							) {
								console.log('error404');
								toast.error(
									'La empresa no cuenta con un certificado activo. Registra tu certificado en: Configuración / Certificados /+Agregar'
								);
								console.log(error.response.data);
								setEnviandoValue(false);
							} else {
								console.log('error404');
								toast.error(error.response.data.message);
								console.log(error.response.data);
								setEnviandoValue(false);
							}
						} else {
							console.log(error.response.data.message);
							toast.error(error.response.data.message);
							setEnviandoValue(false);
						}
					});
			}
		}
	};
	const isWizardValid = (): boolean => {
		if (!tipoComprobanteId) {
			toast.warning('Es necesario seleccionar el tipo del comprobante.');
			return false;
		} else if (!clienteData.rfc) {
			toast.warning('Es necesario ingresar un rfc del cliente.');
			return false;
		} else if (!clienteData.nombre) {
			toast.warning(
				'Es necesario ingresar el "Nombre o Razón social" del cliente.'
			);
			return false;
		} else if (!clienteData.codigo_postal) {
			toast.warning('Es necesario ingresar el "Codigo postal" del cliente.');
			return false;
		} else if (!clienteData.metodo_de_pago) {
			toast.warning('Es necesario ingresar el "Metodo de pago" del cliente.');
			return false;
		} else if (!clienteData.forma_de_pago) {
			toast.warning('Es necesario ingresar la "Forma de pago" del cliente.');
			return false;
		} else if (!clienteData.tipo_Persona) {
			toast.warning(
				'Es necesario ingresar el "Tipo de Contribuyente" del cliente.'
			);
			return false;
		} else if (!clienteData.c_regimen_fiscal_Cliente) {
			toast.warning('Es necesario ingresar el "Regimen Fiscal" del cliente.');
			return false;
		} else if (!usoFacturaValue.usoDeLaFactura) {
			toast.warning('Es necesario ingresar el "Uso de CFDI" ');

			return false;
		} else if (comprobanteDetalleList.length === 0) {
			toast.warning(
				'No se puede crear un comprobante si detalle de conceptos está vacio.'
			);
			return false;
		} else if (!extras.lugar_expedicion) {
			toast.warning(
				'Es necesario ingresar el "Lugar de expedicion -> Configuración / Mi Cuenta/ Mi Empresa / Código Postal"'
			);
			return false;
		} else if (checkedDocRelacionado) {
			if (!extras.tipo_relacion_id) {
				toast.warning('Es necesario ingresar el tipo de relación');
				return false;
			} else if (!extras.comprobante_relacionado_uuid) {
				toast.warning('Es necesario ingresar el comprobante a relacionar');
				return false;
			}
		}

		return true;
	};

	return (
		<React.Fragment>
			<MDBox
				style={{
					alignItems: 'center',
					margin: 'auto',
					justifyContent: 'center',
				}}
			>
				<Card>
					<MDBox mt={-3} mx={2} sx={{ height: 50 }} style={{ zIndex: '100' }}>
						<Tooltip title='Cerrar'>
							<IconButton
								onClick={() => handleClose()}
								color='secondary'
								aria-label='upload picture'
								component='label'
								style={{
									position: 'absolute',
									right: '-10px',
									backgroundColor: '#fff',
									border: '1px solid #00ACC8',
								}}
							>
								<CloseIcon />
							</IconButton>
						</Tooltip>
						<Stepper
							activeStep={activeStep}
							alternativeLabel
							style={{ background: '#00ACC8' }}
						>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>
					</MDBox>
					<MDBox
						p={2}
						style={{
							maxHeight: '85vh',
							overflow: 'auto',
						}}
					>
						<MDBox>
							{getStepContent(activeStep)}
							<MDBox width='100%' display='flex' justifyContent='space-between'>
								{activeStep === 0 ? (
									<MDBox />
								) : (
									<MDButton variant='outlined' color='primary' onClick={handleBack}>
										Regresar
									</MDButton>
								)}
								<MDButton
									variant='gradient'
									color='primary'
									onClick={!isLastStep ? handleNext : enviarComprobante}
									disabled={enviandoValue}
								>
									{isLastStep ? 'Guardar' : 'Siguiente'}
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
			</MDBox>
		</React.Fragment>
	);
}

export default Wizard;
