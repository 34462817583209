export const START_LOADING = 'startLoading';
export const LOADING_END = 'loadingEnd';
export const LOADING_OK = 'loadingOk';
export const LOADING_FALSE = 'loadingFalse';

export interface iLoadingState {
	loading: boolean;
	isLoadingOk: boolean;
}

export interface StartLoading {
	type: typeof START_LOADING;
}
export interface LoadingEnd {
	type: typeof LOADING_END;
}
export interface LoadingOK {
	type: typeof LOADING_OK;
}
export interface LoadingFalse {
	type: typeof LOADING_FALSE;
}

//Types para el dispatch
export type LoadingDispatchTypes =
	| StartLoading
	| LoadingEnd
	| LoadingOK
	| LoadingFalse;
