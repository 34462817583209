import { rootReducer } from 'reducers/RootReducer';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

//Creacion del store
export const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(thunk))
);

//Exporta el type del rootReducer
export type RootStore = ReturnType<typeof rootReducer>;
