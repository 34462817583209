import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MDAvatar from 'components/MDAvatar';
import MDInput from 'components/MDInput';
import Icon from '@mui/material/Icon';
import MDButton from 'components/MDButton';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getClienteById } from 'actions/Clientes/clientes';
import {
	envioComprobante,
	getComprobantesA,
	getComprobantesCP,
} from 'actions/Comprobante/comprobante';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

function Email(props: any): JSX.Element {
	const dispatch = useDispatch();
	const [copy, setCopy] = useState(false);
	const [cliente, setCliente] = useState({ email: '' });
	const handleInputChangeCliente = ({ target }: any) => {
		setCliente({
			...cliente,
			[target.name]: target.value,
		});
	};

	const sendMail = () => {
		if (props.item.uuid && cliente.email) {
			const data = envioComprobante({
				uuId: props.item.uuid,
				email: cliente.email,
				cc: copy,
			});
			data
				.then((respuesta) => {
					if (respuesta.status === 201) {
						toast.success('¡Envío exitoso!');
						setTimeout(() => {
							props.closeModal();
						}, 3000);

						if (props.tipoComprobante === 1) {
							dispatch<any>(getComprobantesA(props.setComprobantesListData));
						} else if (props.tipoComprobante === 7) {
							setTimeout(() => {
								dispatch<any>(getComprobantesCP(props.setComprobantesListData));
							}, 3000);
						}
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response.data);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response.data);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response.data);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response.data);
					} else {
						console.log(error.response.data);
					}
				});
		} else if (!props.item.uuid) {
			toast.error('¡Error de uuId!');
		}
	};

	useEffect(() => {
		//Get ClienteById
		if (props.item.cliente_id) {
			const data = getClienteById(props.item.cliente_id);
			data
				.then((respuesta) => {
					if (respuesta.status === 200) {
						setCliente({
							email: respuesta.data.email,
						});
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response.data);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response.data);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response.data);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response.data);
					} else {
						console.log(error.response.data);
					}
				});
		}
	}, []);

	return (
		<React.Fragment>
			<Box sx={style}>
				<MDAvatar
					bgColor='primary'
					variant='circular'
					style={{
						alignItems: 'center',
						margin: 'auto',
						justifyContent: 'center',
						textAlign: 'center',
						fontSize: 100,
						width: 128,
						height: 128,
					}}
				>
					<Icon>mail</Icon>
				</MDAvatar>
				<Typography
					id='modal-modal-description'
					sx={{ mt: 2 }}
					style={{
						alignItems: 'center',
						margin: '1rem',
						justifyContent: 'center',
						textAlign: 'center',
					}}
				>
					{props.tipo === 'CP'
						? 'Enviar comprobante de complemento de pago por email'
						: 'Enviar comprobante por email'}
				</Typography>

				<FormGroup
					style={{
						alignItems: 'center',
						margin: '1rem',
						justifyContent: 'center',
						textAlign: 'center',
					}}
				>
					<MDInput
						label='Correo electronico'
						type='email'
						value={cliente.email}
						name='email'
						onChange={handleInputChangeCliente}
					/>
					<FormControlLabel
						control={<Checkbox />}
						label='Enviarme una copia'
						onChange={() => {
							setCopy(!copy);
						}}
					/>
				</FormGroup>

				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='fcenter'
					sx={{ marginTop: '1rem', marginBottom: '1rem' }}
				>
					<MDButton
						size='small'
						color='primary'
						sx={{ margin: '1rem' }}
						onClick={sendMail}
					>
						SI
					</MDButton>
					<MDButton
						size='small'
						color='light'
						sx={{ margin: '1rem' }}
						onClick={() => {
							props.closeModal();
						}}
					>
						NO
					</MDButton>
				</Grid>
			</Box>
			<ToastContainer
				position='top-right'
				autoClose={3000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</React.Fragment>
	);
}

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 800,
	bgcolor: 'background.paper',
	border: '2px',
	boxShadow: 24,
	p: 4,
};

export default Email;
