/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

import validator from 'validator';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

// Images
import { Grid } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import brandPixcua from 'assets/images/pixcua.png';
import { useForm } from 'hooks/useForm';
import { useState } from 'react';
import { restartPassword } from 'actions/Auth/auth';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

function SignUp(): JSX.Element {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [errorMsg, setError] = useState<string>('');
	interface iResetPassword {
		email: string;
	}
	const usuario: iResetPassword = {
		email: '',
	};
	//Uso de hook useForm para manejo de campos en el formulario
	const [formValues, handleInputChange] = useForm(usuario);
	const { email } = formValues;

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (isFormValid()) {
			console.log('Enviando MAil');
			dispatch<any>(restartPassword({ email: email }));
			setError('');
			Swal.fire({
				position: 'center',
				icon: 'info',
				title: `¡Si el correo existe en nuestro sistema, <br/> en unos minutos recibiras un email con las instrucciones para acceder!`,
				showConfirmButton: false,
				timer: 5000,
			});
			setTimeout(() => {
				navigate('/login');
			}, 5000);
		}
	};
	const isFormValid = (): boolean => {
		if (!validator.isEmail(email)) {
			setError('El campo email es incorrecto');
			return false;
		}
		setError('');
		return true;
	};
	return (
		<MDBox pt={4} mt={5}>
			<Grid container justifyContent='center' alignItems='center'>
				<MDAvatar
					variant='square'
					style={{ marginTop: '2rem', width: '9rem' }}
					src={brandPixcua}
					alt='Avatar'
				/>
			</Grid>
			<Grid
				display={'flex'}
				alignItems={'center'}
				justifyContent={'center'}
				style={{ marginTop: '4.5rem', flexWrap: 'wrap' }}
			>
				<Card style={{ minWidth: '300px', maxWidth: '400px', margin: '5px' }}>
					<MDBox
						variant='gradient'
						bgColor='primary'
						borderRadius='lg'
						coloredShadow='success'
						mx={2}
						mt={-3}
						p={3}
						mb={1}
						textAlign='center'
					>
						<MDTypography variant='h4' fontWeight='medium' color='white'>
							Restablecer contraseña
						</MDTypography>
						<MDTypography display='block' variant='button' color='white'>
							Recibiras un correo con las instrucciones para restablecer tu contraseña
						</MDTypography>
					</MDBox>
					<MDBox pt={4} pb={3} px={3}>
						<MDBox component='form' role='form' onSubmit={handleSubmit}>
							<MDBox mb={2}>
								<MDInput
									type='email'
									label='Email'
									variant='standard'
									fullWidth
									name='email'
									value={email}
									onChange={handleInputChange}
									required
								/>
							</MDBox>

							<MDBox mb={2}>
								{errorMsg && (
									<MDTypography
										variant='text'
										color='error'
										fontWeight='light'
										textGradient
										style={{ fontSize: '12px' }}
									>
										{errorMsg}.
									</MDTypography>
								)}
							</MDBox>

							<MDBox mt={4} mb={1}>
								<MDButton variant='gradient' color='secondary' fullWidth type='submit'>
									Enviar
								</MDButton>
							</MDBox>

							<MDBox mt={3} mb={1} textAlign='center'>
								<MDTypography variant='button' color='info'>
									¿Ya tienes una cuenta?{' '}
									<MDTypography
										component={Link}
										to='/login'
										variant='button'
										color='primary'
										fontWeight='medium'
										textGradient
									>
										Entrar
									</MDTypography>
								</MDTypography>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
			</Grid>
		</MDBox>
	);
}

export default SignUp;
