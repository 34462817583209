import {
	iRestablecerPasswordReducer,
	PASSWORD_UPDATED,
	RestablecerPasswordDispatchTypes,
	VALIDATION_TOKEN_CLEAN,
	VALIDATION_TOKEN_SUCCESS,
} from 'actions/RestablecerPassword/restablecerPasswordTypes';

//Estado inicial
const INITIAL_STATE: iRestablecerPasswordReducer = {
	empresatknInfo: {
		id: null,
		email: '',
		nombre: '',
		empresa_id: null,
		activo: false,
	},
	passworUpdated: false,
};

//Reducer
export const RestablecerPasswordReducer = (
	state: iRestablecerPasswordReducer = INITIAL_STATE,
	action: RestablecerPasswordDispatchTypes
): iRestablecerPasswordReducer => {
	switch (action.type) {
		case VALIDATION_TOKEN_SUCCESS:
			return {
				...state,
				empresatknInfo: action.payload.empresatknInfo,
			};
		case VALIDATION_TOKEN_CLEAN:
			return {
				...state,
				empresatknInfo: INITIAL_STATE.empresatknInfo,
			};
		case PASSWORD_UPDATED:
			return {
				...state,
				passworUpdated: true,
			};

		default:
			return state;
	}
};
