
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from 'context';
import { Provider } from 'react-redux';
import { store } from 'store/Store';
import { Suspense } from 'react';
import './index.css';

ReactDOM.render(
	<Provider store={store}>
		<Suspense
			fallback={
				<div>
					<h1>Cargando...</h1>
				</div>
			}
		>
			<BrowserRouter>
				<MaterialUIControllerProvider>
					<App />
				</MaterialUIControllerProvider>
			</BrowserRouter>
		</Suspense>
	</Provider>,
	document.getElementById('root')
);
