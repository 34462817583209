import React, { useEffect, useState } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import DataTable from 'components/Tables/DataTable';
import {
	Button,
	InputLabel,
	NativeSelect,
	Switch,
	Typography,
} from '@mui/material';
import FormField from 'pantallas/MiCuenta/components/FormField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getConceptosBySearch } from 'actions/Catalogo/conceptos';
import { getUnidadesBySearch } from 'actions/Catalogo/unidades';
import { getTipoUso } from 'actions/Catalogo/tipo_uso';
import { numeroAMoneda } from 'helpers/aMoneda';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

const currencies = [
	{
		value: 'Factura',
		label: 'Factura',
	},
	{
		value: 'Recibo de honorarios',
		label: 'Recibo de honorarios',
	},
	{
		value: 'Nota de credito',
		label: 'Nota de credito',
	},
	{
		value: 'Recibo de arrendamiento',
		label: 'Recibo de arrendamiento',
	},
	{
		value: 'Nota de cargo',
		label: 'Nota de cargo',
	},
];

function CrearComprobante({
	selectOption,
	formConceptosValue,
	setFormConceptosValue,
	handleInputConceptosChange,
	btnAdd,
	datosTablaDetalleComprobante,
	totales,
	handleInputTotalesChange,
	usoFacturaValue,
	handleInputChangeUsoFactura,
	checked,
	handleChange,
	checkedIsr,
	handleChangeISR,
	porcentajeDescuento,
	handleInputPorcentajeChange,
	conceptoIdValue,
	setConceptoIdValue,
	inputConceptoIdValue,
	setInputConceptoIdValue,
	inputConceptoDesc,
	setInputConceptoDesc,
	unidadIdValue,
	setUnidadIdValue,
	inputUnidadIdValue,
	setInputUnidadIdValue,
	inputUnidadDesc,
	setInputUnidadDesc,
}: any): JSX.Element {
	const [opcion] = React.useState(selectOption);
	const [arrayTipoUso, setArrayTipoUso] = useState([]);

	const { precio_unitario, cantidad } = formConceptosValue;

	const initOptions: any[] = [];
	const [options, setOptions] = useState(initOptions);

	const initUnidadOptions: any[] = [];
	const [unidadOptions, setunidadOptions] = useState(initUnidadOptions);

	//Clave Concepto
	//Debouncing para obtener el autocompletado de conceptos
	useEffect(() => {
		let timeOutSearch = setTimeout(() => {
			if (inputConceptoIdValue.length > 2) {
				const data = getConceptosBySearch(inputConceptoIdValue);
				data
					.then((respuesta) => {
						if (respuesta.status === 200) {
							setOptions(respuesta.data.data);
						}
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500');
							console.log(error.response.data.message);
						} else if (error.response.status === 400) {
							console.log('error400');
							console.log(error.response.data.message);
						} else if (error.response.status === 403) {
							console.log('error403');
							console.log(error.response.data.message);
						} else if (error.response.status === 404) {
							console.log('error404');
						} else {
							console.log(error.response.data.message);
						}
					});
			} else {
				setOptions([]);
			}
		}, 500);

		return () => {
			clearTimeout(timeOutSearch);
		};
	}, [inputConceptoIdValue]);
	useEffect(() => {
		if (inputConceptoDesc) {
			setFormConceptosValue({
				...formConceptosValue,
				concepto: inputConceptoDesc,
				concepto_sat_id: conceptoIdValue,
				cantidad: 1,
			});
		} else {
			setFormConceptosValue({
				...formConceptosValue,
				concepto: '',
				concepto_sat_id: '',
				cantidad: 0,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputConceptoDesc]);

	//Clave Unidad
	//Debouncing para obtener el autocompletado de conceptos
	useEffect(() => {
		let timeOutSearch = setTimeout(() => {
			if (inputUnidadIdValue.length > 1) {
				const data = getUnidadesBySearch(inputUnidadIdValue);
				data
					.then((respuesta) => {
						if (respuesta.status === 200) {
							setunidadOptions(respuesta.data.data);
						}
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500');
							console.log(error.response.data.message);
						} else if (error.response.status === 400) {
							console.log('error400');
							console.log(error.response.data.message);
						} else if (error.response.status === 403) {
							console.log('error403');
							console.log(error.response.data.message);
						} else if (error.response.status === 404) {
							console.log('error404');
						} else {
							console.log(error.response.data.message);
						}
					});
			} else {
				setunidadOptions([]);
			}
		}, 500);

		return () => {
			clearTimeout(timeOutSearch);
		};
	}, [inputUnidadIdValue]);
	useEffect(() => {
		if (inputUnidadIdValue) {
			setFormConceptosValue({
				...formConceptosValue,
				unidad_sat_id: unidadIdValue,
				unidad: inputUnidadDesc,
			});
		} else {
			setFormConceptosValue({
				...formConceptosValue,
				unidad_sat_id: '',
				unidad: '',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputUnidadIdValue]);

	//Cambio en Importe
	useEffect(() => {
		if (precio_unitario !== 0) {
			setFormConceptosValue({
				...formConceptosValue,
				importe: precio_unitario * cantidad,
			});
		} else {
			setFormConceptosValue({
				...formConceptosValue,
				importe: 0,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [precio_unitario, cantidad]);

	// Get Data
	useEffect(() => {
		//Get TipoUso
		const data = getTipoUso();
		data
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setArrayTipoUso(respuesta.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data);
				} else {
					console.log(error.response.data.message);
				}
			});
	}, []);

	return (
		<MDBox sx={{ width: '100%', marginBottom: '2rem' }}>
			<MDBox mt={5}>
				<Typography
					color={'primary'}
					id='modal-modal-title'
					variant='h6'
					component='h2'
				>
					CONCEPTOS
				</Typography>
				<MDBox
					display={'flex'}
					flexWrap={'wrap'}
					justifyContent={'space-between'}
					alignItems={'center'}
					marginLeft={3}
					marginTop={2}
				>
					<MDBox display={'flex'} flexDirection={'column'}>
						<MDBox
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							<MDTypography
								variant='h6'
								fontWeight='bold'
								sx={{ marginRight: '1rem' }}
							>
								Tipo de comprobante
							</MDTypography>
							<TextField
								id='standard-select-currency'
								select
								value={opcion}
								variant='standard'
								disabled
								sx={{ width: '14rem' }}
							>
								{currencies.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</MDBox>
						<MDBox
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
							marginTop={1}
						>
							<MDTypography
								variant='h6'
								fontWeight='bold'
								sx={{ marginRight: '1rem' }}
							>
								Uso de CFDI
							</MDTypography>
							<NativeSelect
								onChange={handleInputChangeUsoFactura}
								value={usoFacturaValue.usoDeLaFactura}
								inputProps={{
									name: 'usoDeLaFactura',
									id: 'uncontrolled-usoDeLaFactura',
								}}
								style={{ width: '14rem' }}
								required
							>
								<option value={''}>Seleccione una opción</option>
								{arrayTipoUso.map((item) => (
									<option key={item.id} value={item.c_UsoCFDI}>
										{`${item.c_UsoCFDI} - ${item.descripcion}`}
									</option>
								))}
							</NativeSelect>
						</MDBox>
						<MDBox
							justifyContent={'space-between'}
							alignItems={'center'}
							marginTop={1}
						></MDBox>
					</MDBox>
				</MDBox>
			</MDBox>
			<MDBox component='form' mt={5} pb={3} px={3}>
				<Grid container spacing={5}>
					<Grid item xs={12} sm={3}>
						<Autocomplete
							value={conceptoIdValue}
							onChange={(event: any, newValue: any) => {
								setConceptoIdValue(newValue.c_ClaveProdServ);
								setInputConceptoDesc(newValue.descripcion);
							}}
							inputValue={inputConceptoIdValue}
							onInputChange={(event, newInputValue) => {
								if (newInputValue !== 'undefined - undefined') {
									setInputConceptoIdValue(newInputValue);
								}
							}}
							id='controllable-states-demo'
							options={options}
							getOptionLabel={(option) => {
								return option.c_ClaveProdServ + ' - ' + option.descripcion || '';
							}}
							isOptionEqualToValue={(option, value) => option.value === value.value}
							renderOption={(props, option) => (
								<Box component='li' {...props} style={{ fontSize: '11px' }}>
									{option.c_ClaveProdServ} {option.descripcion}
								</Box>
							)}
							renderInput={(params) => (
								<TextField {...params} label='Clave concepto*' variant='standard' />
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<InputLabel variant='standard' htmlFor='cantidadConceptosWizard'>
							Cantidad*
						</InputLabel>
						<input
							className='customInputFiled'
							type={'number'}
							min='0'
							id='cantidadConceptosWizard'
							placeholder='Cantidad*'
							name='cantidad'
							value={formConceptosValue.cantidad}
							onChange={handleInputConceptosChange}
						/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<Autocomplete
							value={unidadIdValue}
							onChange={(event: any, newValue: any) => {
								setUnidadIdValue(newValue.c_ClaveUnidad);
								setInputUnidadDesc(newValue.nombre);
							}}
							inputValue={inputUnidadIdValue}
							onInputChange={(event, newInputValue) => {
								if (newInputValue !== 'undefined - undefined') {
									setInputUnidadIdValue(newInputValue);
								}
							}}
							id='controllable-states-demo'
							options={unidadOptions}
							getOptionLabel={(option) => {
								return option.c_ClaveUnidad + ' - ' + option.nombre || '';
							}}
							isOptionEqualToValue={(option, value) => option.value === value.value}
							renderOption={(props, option) => (
								<Box component='li' {...props} style={{ fontSize: '11px' }}>
									{option.c_ClaveUnidad} {option.nombre}
								</Box>
							)}
							renderInput={(params) => (
								<TextField {...params} label='Clave unidad*' variant='standard' />
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<FormField
							label='Unidad*'
							placeholder='Unidad*'
							name='unidad'
							value={formConceptosValue.unidad}
							onChange={handleInputConceptosChange}
							disabled
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormField
							label='Concepto*'
							placeholder='Concepto*'
							name='concepto'
							value={formConceptosValue.concepto}
							onChange={handleInputConceptosChange}
						/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<InputLabel variant='standard' htmlFor='precioUnitarioConceptosWizard'>
							$ Precio Unitario*
						</InputLabel>
						<input
							className='customInputFiled'
							type={'number'}
							step='0.0001'
							min='0'
							id='precioUnitarioConceptosWizard'
							placeholder='P.U.'
							name='precio_unitario'
							value={formConceptosValue.precio_unitario}
							onChange={handleInputConceptosChange}
						/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<InputLabel variant='standard' htmlFor='importeConceptosWizard'>
							$ Importe
						</InputLabel>
						<input
							className='customInputFiled'
							type={'number'}
							step='0.0001'
							min='0'
							id='importeConceptosWizard'
							placeholder='Importe'
							name='importe'
							value={formConceptosValue.importe}
							onChange={handleInputConceptosChange}
							disabled
						/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<InputLabel variant='standard' htmlFor='impuestoConceptosWizard'>
							% IVA
						</InputLabel>
						<NativeSelect
							type={'number'}
							inputProps={{
								name: 'tasa_iva',
								id: 'impuestoConceptosWizard',
							}}
							value={formConceptosValue.tasa_iva}
							onChange={handleInputConceptosChange}
							style={{ width: '100%' }}
						>
							<option value={16}>16</option>
							<option value={0}>0</option>
							<option value={null}>IVA exento</option>
						</NativeSelect>
					</Grid>
					<Grid item xs={12} sm={3}>
						<FormField
							label='Número identificación'
							placeholder='Número identificación'
							name='numero_identificacion'
							value={formConceptosValue.numero_identificacion}
							onChange={handleInputConceptosChange}
						/>
					</Grid>
					<Grid item xs={12} sm={2} display='flex' alignItems={'center'}>
						<Button
							color='success'
							variant='contained'
							startIcon={<AddCircleIcon style={{ color: '#fff' }} />}
							style={{ backgroundColor: '#49d08a', color: '#fff' }}
							onClick={btnAdd}
						>
							Agregar
						</Button>
					</Grid>
				</Grid>
			</MDBox>
			<MDBox
				mt={1}
				mb={2}
				sx={{ width: '100%' }}
				style={{
					border: '0.5px solid #d1dde3',
					borderRadius: '10px',
					boxShadow: ' 1px 1px 8px 1px #74747456',
				}}
			>
				<DataTable table={datosTablaDetalleComprobante} />
			</MDBox>
			<MDBox
				display={'flex'}
				justifyContent={'flex-end'}
				style={{ width: '100%' }}
			>
				<MDBox display={'flex'} flexDirection={'column'}>
					<MDBox
						display={'flex'}
						justifyContent={'space-between'}
						style={{ minWidth: '200px' }}
					>
						<MDTypography
							variant='body2'
							fontWeight='medium'
							color='primary'
							sx={{ marginRight: '1rem' }}
						>
							Subtotal
						</MDTypography>

						<MDBox>
							{totales.subtotal ? numeroAMoneda(totales.subtotal) : '$ 0'}
						</MDBox>
					</MDBox>
					<MDBox
						display={'flex'}
						justifyContent={'space-between'}
						style={{ minWidth: '200px' }}
					>
						<MDTypography
							variant='body2'
							fontWeight='medium'
							color='primary'
							sx={{ marginRight: '1rem' }}
						>
							Descuento
						</MDTypography>
						<MDBox display={'flex'}>
							<MDBox display={'flex'} alignItems={'center'} marginRight={1}>
								<InputLabel
									variant='standard'
									htmlFor='porcentajeDescuentoConceptosWizard'
									style={{ marginRight: '0px', width: '15px' }}
								>
									%
								</InputLabel>
								<input
									className='customInputNumber'
									type={'number'}
									min='0'
									max='100'
									id='porcentajeDescuentoConceptosWizard'
									style={{ minWidth: '85px' }}
									name='porcentajeDescuento'
									value={porcentajeDescuento.porcentajeDescuento}
									onChange={handleInputPorcentajeChange}
								/>
							</MDBox>
							<MDTypography fontWeight='medium' marginRight={1}>
								=
							</MDTypography>
							<MDBox display={'flex'} alignItems={'center'}>
								<InputLabel
									variant='standard'
									htmlFor='cantidadDescuentoConceptosWizard'
									style={{ width: '15px' }}
								>
									$
								</InputLabel>
								<input
									className='customInputNumber'
									type={'number'}
									id='cantidadDescuentoConceptosWizard'
									min='0'
									max={totales.subtotal ? totales.subtotal : '0'}
									style={{ minWidth: '85px' }}
									name='descuento'
									value={totales.descuento}
									onChange={handleInputTotalesChange}
								/>
							</MDBox>
						</MDBox>
					</MDBox>
					<MDBox
						display={'flex'}
						justifyContent={'space-between'}
						style={{ minWidth: '200px' }}
					>
						<MDTypography
							variant='body2'
							fontWeight='medium'
							color='primary'
							sx={{ marginRight: '1rem' }}
						>
							I.V.A
						</MDTypography>
						<MDBox>{totales.iva ? numeroAMoneda(totales.iva) : '$ 0'}</MDBox>
					</MDBox>
					<MDBox
						display={'flex'}
						justifyContent={'space-between'}
						mb={2}
						style={{ minWidth: '200px', borderBottom: '2px solid #f6f6f6' }}
					>
						<MDTypography
							variant='body2'
							fontWeight='medium'
							color='primary'
							sx={{ marginRight: '1rem' }}
						>
							Retencion IVA
							<Switch
								checked={checked}
								onChange={handleChange}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						</MDTypography>
						<MDBox>
							{totales.retencion_iva ? numeroAMoneda(totales.retencion_iva) : '$ 0'}
						</MDBox>
					</MDBox>
					<MDBox
						display={'flex'}
						justifyContent={'space-between'}
						mb={2}
						style={{ minWidth: '200px', borderBottom: '2px solid #f6f6f6' }}
					>
						<MDTypography
							variant='body2'
							fontWeight='medium'
							color='primary'
							sx={{ marginRight: '1rem' }}
						>
							Retencion ISR
							<Switch
								checked={checkedIsr}
								onChange={handleChangeISR}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						</MDTypography>
						<MDBox>
							{totales.retencion_isr ? numeroAMoneda(totales.retencion_isr) : '$ 0'}
						</MDBox>
					</MDBox>
					<MDBox
						display={'flex'}
						justifyContent={'space-between'}
						style={{ minWidth: '200px' }}
					>
						<MDTypography
							variant='body2'
							fontWeight='medium'
							color='primary'
							sx={{ marginRight: '1rem' }}
						>
							TOTAL
						</MDTypography>
						<MDBox>{totales.total ? numeroAMoneda(totales.total) : '$ 0'}</MDBox>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
}

export default CrearComprobante;
