import { Dispatch } from 'redux';
import Swal from 'sweetalert2';
import { axiosClient } from '../../Config/axiosConfig';
import {
	iValidationEmpresaInfo,
	PASSWORD_UPDATED,
	RestablecerPasswordDispatchTypes,
	VALIDATION_TOKEN_SUCCESS,
} from './restablecerPasswordTypes';

//Validar token recibido por mail
export const validationToken = (token: any) => {
	return async (dispatch: Dispatch<RestablecerPasswordDispatchTypes>) => {
		axiosClient
			.get(`confirmation/${token}`)
			.then((respuesta) => {
				console.log(respuesta);
				if (respuesta.status === 201) {
					const confirmationUser: iValidationEmpresaInfo = respuesta.data.data;

					dispatch({
						type: VALIDATION_TOKEN_SUCCESS,
						payload: { empresatknInfo: confirmationUser },
					});
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log(error);
					console.log(error.response.data.msg);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error);
					Swal.fire({
						width: '35rem',
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else {
					console.log(error);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				}
			});
	};
};

//Envio de confirmacion de Contraseña o Nueva Contraseña
export const sendPassword = (tkn: string, email: string, password: string) => {
	return async (dispatch: Dispatch<RestablecerPasswordDispatchTypes>) => {
		axiosClient
			.patch(
				`confirmation/`,
				{
					email: email,
					password: password,
				},
				{
					headers: {
						Authorization: `${tkn}`,
					},
				}
			)
			.then((respuesta) => {
				if (respuesta.status === 201) {
					Swal.fire({
						position: 'center',
						icon: 'success',
						title: `¡Registro Exitoso!`,
						showConfirmButton: false,
						timer: 2000,
					});
					setTimeout(() => {
						dispatch({ type: PASSWORD_UPDATED });
					}, 2000);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log(error);
					console.log(error.response.data.msg);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error);
					Swal.fire({
						width: '35rem',
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error);
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: `${error.response.data.msg}`,
						showConfirmButton: false,
						timer: 3500,
					});
				} else {
					console.log(error);
					Swal.fire({
						position: 'bottom',
						icon: 'error',
						title: `Algo salió mal, por favor inténtelo de nuevo`,
						showConfirmButton: false,
						timer: 2000,
					});
				}
			});
	};
};
