import { combineReducers } from 'redux';
import { AuthReducer } from './AuthReducer';
import { LoadingReducer } from './LoadingReducer';
import { RestablecerPasswordReducer } from './RestablecerPasswordReducer';

//Todos los reducer se unen aquí
export const rootReducer = combineReducers({
	auth: AuthReducer,
	restPass: RestablecerPasswordReducer,
	loading: LoadingReducer,
});
