
// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard';

import { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import moment from 'moment';
import {
	LoadingDispatchTypes,
	LOADING_END,
	START_LOADING,
} from 'actions/Loading/loadingTypes';
import { axiosClient, getToken } from 'Config/axiosConfig';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import PXLoader from 'components/PXLoader/PXLoader';
import DefaultLineChart from 'examples/Charts/LineCharts/DefaultLineChart';
import MDBadgeDot from 'components/MDBadgeDot';
import PxcPieChart from './components/pxc-PieChart/PxcPieChart';
import { numeroAMoneda } from 'helpers/aMoneda';
import { numeroAMes } from 'helpers/aMes';

function Analytics(): JSX.Element {
	const dispatch = useDispatch();
	const { loading } = useSelector((state: RootStore) => state.loading);
	
	interface iPlan {
		timbres: number;
		vigencia: string;
		plan_id?: number;
	}
	const [suscripcionInfo, setSuscripcionInfo] = useState<iPlan>({
		timbres: 0,
		vigencia: '',
		plan_id: 0,
	});
	const [pendienteCobro, setPendienteCobro] = useState<number>(0);
	const [ingresosMesActual, setIngresosMesActual] = useState({
		total: 0,
		mes: 0,
	});

	const currentYear = moment().format('yyyy');
	const lastYear = moment().subtract(1, 'year').format('yyyy');
	const currentMonth = moment().format('M');

	const [ingresosAnteriores, setIngresosAnteriores] = useState([]);
	const [ingresosActuales, setIngresosActuales] = useState([]);

	useEffect(() => {
		//Obtener Suscripcion info
		const getSuscripcion = () => {
			return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
				await getToken();
				dispatch({ type: START_LOADING });
				axiosClient
					.get(`/suscripcion`)
					.then((respuesta) => {
						if (respuesta.status === 200) {
							setSuscripcionInfo({
								timbres: respuesta.data.data.timbres_disponibles,
								vigencia: moment(respuesta.data.data.fecha_fin).format('DD/MM/YYYY'),
								plan_id: respuesta.data.data.plan_id,
							});
						}
						dispatch({ type: LOADING_END });
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 400) {
							console.log('error400', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 403) {
							console.log('error403', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 404) {
							console.log('error404', error.response.data);
							dispatch({ type: LOADING_END });
						} else {
							console.log(error.response.data);
							dispatch({ type: LOADING_END });
						}
					});
			};
		};
		dispatch<any>(getSuscripcion());
		//Obtener pendienteCobro info
		const getPendienteCobro = () => {
			return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
				await getToken();
				dispatch({ type: START_LOADING });
				axiosClient
					.get(`/comprobantes/pendienteCobro/`)
					.then((respuesta) => {
						if (respuesta.status === 200) {
							if (respuesta.data[0].total) {
								setPendienteCobro(respuesta.data[0].total);
							} else {
								setPendienteCobro(0);
							}
						}
						dispatch({ type: LOADING_END });
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 400) {
							console.log('error400', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 403) {
							console.log('error403', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 404) {
							console.log('error404', error.response.data);
							dispatch({ type: LOADING_END });
						} else {
							console.log(error.response.data);
							dispatch({ type: LOADING_END });
						}
					});
			};
		};
		dispatch<any>(getPendienteCobro());
		//Obtener Ingresos
		const getIngresos = () => {
			return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
				await getToken();
				dispatch({ type: START_LOADING });
				axiosClient
					.get(`/comprobantes/ingresos/`)
					.then((respuesta) => {
						if (respuesta.status === 200) {
							const arrayActuales = respuesta.data.Actual;
							const arrayAnteriores = respuesta.data.Anterior;

							arrayActuales.map((item: any) => {
								if (item.mes === Number(currentMonth)) {
									setIngresosMesActual(item);
								}
							});

							const arrayIngresosActuales = [];
							for (let i = 1; i <= Number(currentMonth); i++) {
								if (arrayActuales.find((item: any) => item.mes === i)) {
									const itemFind = arrayActuales.filter((item: any) => item.mes === i);
									arrayIngresosActuales.push(itemFind[0]);
								} else {
									arrayIngresosActuales.push({ total: 0, mes: i });
								}
							}
							const arrayIngresosAnteriores = [];
							for (let i = 1; i <= 12; i++) {
								if (arrayAnteriores.find((item: any) => item.mes === i)) {
									const itemFind = arrayAnteriores.filter((item: any) => item.mes === i);
									arrayIngresosAnteriores.push(itemFind[0]);
								} else {
									arrayIngresosAnteriores.push({ total: 0, mes: i });
								}
							}
							setIngresosActuales(arrayIngresosActuales);
							setIngresosAnteriores(arrayIngresosAnteriores);
						}
						dispatch({ type: LOADING_END });
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 400) {
							console.log('error400', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 403) {
							console.log('error403', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 404) {
							console.log('error404', error.response.data);
							dispatch({ type: LOADING_END });
						} else {
							console.log(error.response.data);
							dispatch({ type: LOADING_END });
						}
					});
			};
		};
		dispatch<any>(getIngresos());
	}, []);

	interface Types {
		labels: string[];
		datasets: {
			label: string;
			color:
				| 'primary'
				| 'secondary'
				| 'info'
				| 'success'
				| 'warning'
				| 'error'
				| 'light'
				| 'dark';
			data: number[];
		}[];
	}

	const actualYearData = ingresosActuales.reduce(
		(acumulador: any, currentValue: any) => {
			return acumulador.concat(currentValue.total);
		},
		[]
	);
	const anteriorYearData = ingresosAnteriores.reduce(
		(acumulador: any, currentValue: any) => {
			return acumulador.concat(currentValue.total);
		},
		[]
	);

	const lineChartData: Types = {
		labels: [
			'Enero',
			'Febrero',
			'Marzo',
			'Abril',
			'Mayo',
			'Junio',
			'Julio',
			'Agosto',
			'Septiembre',
			'Octubre',
			'Noviembre',
			'Diciembre',
		],
		datasets: [
			{
				label: '2022',
				color: 'info',
				data: actualYearData,
			},
			{
				label: '2021',
				color: 'dark',
				data: anteriorYearData,
			},
		],
	};
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox py={3}>
				<MDBox mt={1.5}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color='success'
									icon='leaderboard'
									title='Ingresos mes actual'
									count={
										ingresosMesActual.total ? numeroAMoneda(ingresosMesActual.total) : 0
									}
									percentage={{
										color: 'success',
										amount: '',
										label: `Ingresos en ${numeroAMes(Number(currentMonth))}`,
									}}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color='secondary'
									icon='paid'
									title='Total cuentas por cobrar '
									count={
										!loading ? (
											`${numeroAMoneda(pendienteCobro)}`
										) : (
											<PXLoader showText={false} />
										)
									}
									percentage={{
										color: 'success',
										amount: '',
										label: 'Monto pendiente de cobro',
									}}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color='warning'
									icon='qr_code_2'
									title='Timbres'
									count={
										!loading ? (
											`${suscripcionInfo.timbres}`
										) : (
											<PXLoader showText={false} />
										)
									}
									percentage={{
										color: 'success',
										amount: '',
										label: 'Timbres disponibles',
									}}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									color='primary'
									icon='today'
									title='Vigencia'
									count={
										!loading ? (
											`${suscripcionInfo.vigencia}`
										) : (
											<PXLoader showText={false} />
										)
									}
									percentage={{
										color: 'success',
										amount: '',
										label: 'Tus timbres son vigentes hasta',
									}}
								/>
							</MDBox>
						</Grid>
					</Grid>
				</MDBox>
				{/* Dashboard Graficas */}
				<MDBox mt={6}>
					<Grid container spacing={3}>
						{/* Grafica Pastel */}
						<Grid item xs={12} md={6} lg={4}>
							<PxcPieChart />
						</Grid>
						{/* Graficas Lineales */}
						<Grid item xs={12} md={6} lg={8}>
							<DefaultLineChart
								title={`Ingresos de ${lastYear} vs ${currentYear}`}
								description={
									<MDBox
										display='flex'
										justifyContent='space-between'
										style={{ minHeight: '70px' }}
									>
										<MDBox display='flex' ml={-1}>
											<MDBadgeDot color='info' size='sm' badgeContent={currentYear} />
											<MDBadgeDot color='dark' size='sm' badgeContent={lastYear} />
										</MDBox>
									</MDBox>
								}
								chart={lineChartData}
							/>
						</Grid>
					</Grid>
				</MDBox>
			</MDBox>
		</DashboardLayout>
	);
}

export default Analytics;
