import { Box, Grid, InputLabel, NativeSelect, Typography } from '@mui/material';
import {
	getEntidades,
	getEntidadesByPais,
	getRegimenes,
} from 'actions/MiCuenta/miCuenta';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import FormField from 'pantallas/MiCuenta/components/FormField';
import { useEffect, useState } from 'react';
import servFormPago from '../../../../actions/Catalogo/forma_Pago';
import servMetodoPago from '../../../../actions/Catalogo/metodo_pago';
import './Cliente.css';

const Cliente = ({ formValue, handleInputChange, arrayClientes }: any) => {
	const [colFormaPago, setcolFormaPago] = useState([]);
	const [colMetodoPago, setcolMetodoPago] = useState([]);
	const [estadosList, setEstadosList] = useState([]);
	const [regimenes, setRegimenes] = useState([]);

	const getCombos = () => {
		servFormPago
			.get()
			.then((r) => {
				const colData = r.data.map(
					(item: { c_FormaPago: string; descripcion: string }) => {
						return {
							c_FormaPago: item.c_FormaPago,
							descripcion: item.descripcion,
						};
					}
				);
				setcolFormaPago(colData);
			})
			.catch((e) => console.error('Error cargar formas de pago', e));
		servMetodoPago
			.get()
			.then((r) => {
				const colData = r.data.map(
					(item: { c_MetodoPago: string; descripcion: string }) => {
						return {
							c_MetodoPago: item.c_MetodoPago,
							descripcion: item.descripcion,
						};
					}
				);
				setcolMetodoPago(colData);
			})
			.catch((e) => console.error('Error cargar metodos de pago', e));
	};
	// Obtener Data
	useEffect(() => {
		getCombos();

		//Get Regimenes
		const regimenesData = getRegimenes();
		regimenesData
			.then((respuesta) => {
				if (respuesta.status === 201) {
					setRegimenes(respuesta.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response.data.message);
				} else {
					console.log(error.response.data.message);
				}
			});
	}, []);

	useEffect(() => {
		let pais = '';
		if (formValue.pais === 'México') pais = 'MEX';
		else if (formValue.pais === 'USA') pais = 'USA';
		else if (formValue.pais === 'Canada') pais = 'CAN';

		const dataEntidades = getEntidadesByPais(pais);
		dataEntidades
			.then((respuesta) => {
				console.log('respuestaEntidades:::', respuesta);
				if (respuesta.status === 200) {
					setEstadosList(respuesta.data);
				}
			})
			.catch((error) => {
				console.log('respuestaEntidades:::', error);
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response);
				} else {
					console.log(error.response);
				}
			});
	}, [formValue.pais]);

	return (
		<MDBox
			display={'flex'}
			flexDirection={'column'}
			alignItems={'center'}
			sx={{ padding: '2rem' }}
		>
			<Box>
				<Typography
					color={'primary'}
					id='modal-modal-title'
					variant='h5'
					component='h2'
				>
					Cliente
				</Typography>
				<>
					<MDBox>
						<MDTypography variant='body2'>
							Todos los campos con * son obligatorios
						</MDTypography>
					</MDBox>
					<MDBox
						mt={1}
						style={{
							maxWidth: '600px',
							lineHeight: '18px',
							fontSize: '15px',
							textAlign: 'justify',
							backgroundColor: '#e1a90244',
							border: '2px solid #ffbf00',
							padding: '10px',
							borderRadius: '10px',
						}}
					>
						<MDTypography variant='captions' style={{ color: '#383838' }}>
							NOTA: Los datos ingresados en esta sección deberan coincidir exactamente
							con los registrados ante el SAT, respetando Mayúsculas, Minúsculas,
							Espacios, Acentos y Signos de puntuación.
						</MDTypography>
					</MDBox>
				</>
				<MDBox component='form' px={3} mt={2}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={4}>
							<InputLabel variant='standard' htmlFor='nombreClienteWizard'>
								Nombre o razón social*
							</InputLabel>
							<input
								className='customInputFiled'
								type={'text'}
								list='datalistOptions'
								id='nombreClienteWizard'
								placeholder='Nombre o razón social*'
								value={formValue.nombre ?? ''}
								name='nombre'
								onChange={handleInputChange}
								required
								autoComplete='off'
							/>

							<datalist id='datalistOptions'>
								{arrayClientes &&
									arrayClientes.map((opcion: any) => (
										<option key={opcion.id} value={opcion.nombre}>
											{opcion.nombre}
										</option>
									))}
							</datalist>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormField
								label='RFC*'
								placeholder='RFC*'
								value={formValue.rfc ?? ''}
								onChange={handleInputChange}
								name='rfc'
								type='text'
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormField
								label='C.P.*'
								placeholder='C.P.*'
								value={formValue.codigo_postal ?? ''}
								onChange={handleInputChange}
								name='codigo_postal'
								type='text'
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormField
								label='Calle'
								placeholder='Calle'
								value={formValue.calle ?? ''}
								onChange={handleInputChange}
								name='calle'
								type='text'
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormField
								label='# Exterior'
								placeholder='# Exterior'
								value={formValue.no_exterior ?? ''}
								onChange={handleInputChange}
								name='no_exterior'
								type='text'
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormField
								label='# Interior'
								placeholder='# Interior'
								value={formValue.no_interior ?? ''}
								onChange={handleInputChange}
								name='no_interior'
								type='text'
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							{/* <FormField
								label='País*'
								placeholder='País*'
								value={formValue.pais ?? ''}
								onChange={handleInputChange}
								name='pais'
								type='text'
							/> */}
							<InputLabel variant='standard' htmlFor='paisClienteWizardw'>
								País
							</InputLabel>
							<NativeSelect
								onChange={handleInputChange}
								value={formValue.pais ?? ''}
								inputProps={{
									name: 'pais',
									id: 'paisClienteWizard',
								}}
								style={{ width: '100%' }}
								required
							>
								<option value={''}>Seleccione una opción</option>
								<option value={'México'}>México</option>
								<option value={'USA'}>USA</option>
								<option value={'Canada'}>Canada</option>
							</NativeSelect>
						</Grid>
						<Grid item xs={12} sm={4}>
							<InputLabel variant='standard' htmlFor='estadoClienteWizardw'>
								Estado
							</InputLabel>
							<NativeSelect
								onChange={handleInputChange}
								value={formValue.estado ?? ''}
								inputProps={{
									name: 'estado',
									id: 'estadoClienteWizard',
								}}
								style={{ width: '100%' }}
								required
							>
								<option value={''}>Seleccione una opción</option>
								{estadosList.map((item) => (
									<option key={item.id} value={item.nombre}>
										{item.nombre}
									</option>
								))}
							</NativeSelect>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormField
								label='Localidad'
								placeholder='Localidad'
								value={formValue.localidad ?? ''}
								onChange={handleInputChange}
								name='localidad'
								type='text'
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormField
								label='Alcaldia/Municipio'
								placeholder='Alcaldia/Municipio'
								value={formValue.municipio ?? ''}
								onChange={handleInputChange}
								name='municipio'
								type='text'
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormField
								label='Colonia'
								placeholder='Colonia'
								value={formValue.colonia ?? ''}
								onChange={handleInputChange}
								name='colonia'
								type='text'
							/>
						</Grid>

						<Grid item xs={12} sm={4}>
							<FormField
								label='Referencia'
								placeholder='Referencia'
								value={formValue.referencia ?? ''}
								onChange={handleInputChange}
								name='referencia'
								type='text'
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<FormField
								label='Teléfono'
								placeholder='Teléfono'
								inputProps={{ type: 'number' }}
								value={formValue.telefono ?? ''}
								onChange={handleInputChange}
								name='telefono'
								type='text'
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormField
								label='email'
								placeholder='example@email.com'
								inputProps={{ type: 'email' }}
								value={formValue.email ?? ''}
								onChange={handleInputChange}
								name='email'
								type='text'
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDBox style={{ width: '100%' }}>
								<Typography color={'primary'} fontSize={15} component='p'>
									Tipo Contribuyente*
								</Typography>
								<NativeSelect
									onChange={handleInputChange}
									value={formValue.tipo_Persona ?? ''}
									inputProps={{
										name: 'tipo_Persona',
										id: 'selectTipoContribuyenteWizardCliente',
									}}
									style={{ width: '100%' }}
									required
								>
									<option value={''}>Seleccione una opción*</option>
									<option value={'Persona Fisica'}>Persona Fisica</option>
									<option value={'Persona Moral'}>Persona Moral</option>
								</NativeSelect>
							</MDBox>
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDBox style={{ width: '100%' }}>
								<Typography color={'primary'} fontSize={15} component='p'>
									Regimen Fiscal*
								</Typography>
								<NativeSelect
									onChange={handleInputChange}
									value={formValue.c_regimen_fiscal_Cliente ?? ''}
									inputProps={{
										name: 'c_regimen_fiscal_Cliente',
										id: 'selectRegimenWizardCliente',
									}}
									style={{ width: '100%' }}
									required
								>
									<option value={''}>Seleccione una opción*</option>
									{regimenes.map((item) => (
										<option key={item.id} value={item.c_RegimenFiscal}>
											{`${item.c_RegimenFiscal} - ${item.descripcion}`}
										</option>
									))}
								</NativeSelect>
							</MDBox>
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDBox style={{ width: '100%' }}>
								<InputLabel variant='standard' htmlFor='uncontrolled-native'>
									<Typography
										color={'primary'}
										id='modal-modal-title'
										fontSize={15}
										component='p'
									>
										Metodo de pago*
									</Typography>
								</InputLabel>
								<NativeSelect
									onChange={handleInputChange}
									value={formValue.metodo_de_pago ?? ''}
									inputProps={{
										name: 'metodo_de_pago',
										id: 'uncontrolled-metodo_de_pago',
									}}
									style={{ width: '100%' }}
									required
								>
									<option value={''}>Seleccione una opción*</option>
									{colMetodoPago.map((item) => (
										<option key={item.c_MetodoPago} value={item.c_MetodoPago}>
											{`${item.descripcion}`}
										</option>
									))}
								</NativeSelect>
							</MDBox>
						</Grid>
						<Grid item xs={12} sm={6}>
							<MDBox style={{ width: '100%' }}>
								<InputLabel variant='standard' htmlFor='uncontrolled-native'>
									<Typography
										color={'primary'}
										id='modal-modal-title'
										fontSize={15}
										component='p'
									>
										Forma de pago*
									</Typography>
								</InputLabel>
								<NativeSelect
									onChange={handleInputChange}
									value={formValue.forma_de_pago ?? ''}
									inputProps={{
										name: 'forma_de_pago',
										id: 'uncontrolled-native',
									}}
									style={{ width: '100%' }}
									required
								>
									<option value={''}>Seleccione una opción*</option>
									{(formValue.metodo_de_pago === 'PUE' ||
										formValue.metodo_de_pago === '') &&
										colFormaPago.map((item) => (
											<option key={item.c_FormaPago} value={item.c_FormaPago}>
												{`${item.c_FormaPago} - ${item.descripcion}`}
											</option>
										))}
									{formValue.metodo_de_pago === 'PPD' && (
										<option key='99' value='99'>
											{`99 - Por definir`}
										</option>
									)}
								</NativeSelect>
							</MDBox>
						</Grid>
					</Grid>
				</MDBox>
			</Box>
		</MDBox>
	);
};

export default Cliente;
