
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'components/Tables/DataTable';
import moment from 'moment';


import { useEffect, useState } from 'react';
import ProgressBar from 'pantallas/Comprobantes/components/ProgressBar/ProgressBar';
import Acciones from './components/Acciones';
import GuardarPago from './guardarPago/GuardarPago';
import {
	iMiEmpresaInfoToPDF,
	initMiEmpresaFormulario,
} from 'actions/MiCuenta/miCuentaTypes';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import { getEmpresaInfoById } from 'actions/MiCuenta/miCuenta';

import { useDispatch } from 'react-redux';
import { numeroAMoneda } from 'helpers/aMoneda';
import { getComprobantesCP } from 'actions/Comprobante/comprobante';
import PXLoader from 'components/PXLoader/PXLoader';
import { CSVLink } from 'react-csv';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function Pago(props: any): JSX.Element {
	const dispatch = useDispatch();
	const { empresa_id } = useSelector((state: RootStore) => state.auth);
	const { loading } = useSelector((state: RootStore) => state.loading);
	const [datos, setDatos] = useState([]);

	const [titulo, setTitulo] = useState('');
	const [open, setOpen] = useState(false);
	const [modo, setModo] = useState(0);
	const [id, setId] = useState(0);

	const [miEmpresa, setMiEmpresa] = useState<iMiEmpresaInfoToPDF>(
		initMiEmpresaFormulario
	);
	const [comprobanteToUpdate, setComprobanteToUpdate] = useState();

	const handleClose = (event?: object,reason?:string) => {
		if (reason && reason == "backdropClick")return;
		setOpen(false);
		setComprobanteToUpdate(null);
	};
	const handleOpen = () => setOpen(!open);

	const modoModal = (modo: number, item: { id: number }) => {
		setModo(modo);
		setId(item.id);
		setTitulo(modo === 0 ? 'Agregar Pago' : 'Actualizar Pago');
		handleOpen();
	};
	const isMyDataValid = () => {
		if (
			!miEmpresa.nombre ||
			!miEmpresa.rfc ||
			!miEmpresa.codigo_postal ||
			!miEmpresa.calle ||
			!miEmpresa.no_exterior ||
			!miEmpresa.colonia ||
			!miEmpresa.localidad ||
			!miEmpresa.estado ||
			!miEmpresa.pais
		) {
			return false;
		}

		return true;
	};

	//Estuctura de datos para tabla
	const datosTabla = {
		columns: [
			{ Header: 'UUID', accessor: 'UUID', width: '80px', align: 'center' },
			{ Header: 'Serie', accessor: 'Serie', width: '80px', align: 'center' },
			{ Header: 'Folio', accessor: 'Folio', width: '80px' },
			{ Header: 'Clientes', accessor: 'Cliente', width: '230px' },
			{ Header: 'Fecha pago', accessor: 'FechaPago', width: '100px' },
			{ Header: 'Monto pagado', accessor: 'MontoPagado' },
			{ Header: 'Estatus', accessor: 'Estatus' },
			{ Header: 'Acciones', accessor: 'Acciones' },
		],
		rows: datos
			.map((item) => {
				return {
					UUID: item.uuid,
					Serie: item.serie,
					Folio: item.folio,
					Cliente: item.nombre,
					FechaPago: (
						<>
							<span
								style={{
									display: 'flex',
									maxWidth: '105px',
									marginLeft: '-10px',
								}}
							>
								{item.complementoPagos[0] && item.complementoPagos[0].fecha_pago
									? moment(item.complementoPagos[0].fecha_pago).format('DD/MM/YYYY')
									: '--/--/--'}
							</span>
						</>
					),
					MontoPagado: (
						<>
							<span
								style={{
									display: 'flex',
									width: '125px',
								}}
							>
								{item.complementoPagos[0] && item.complementoPagos[0].monto
									? numeroAMoneda(item.complementoPagos[0].monto)
									: '$ __.__'}
							</span>
						</>
					),
					Estatus: (
						<>
							<ProgressBar progressId={item.estatus_comprobante_id} />
						</>
					),
					Acciones: (
						<>
							<Acciones
								item={item}
								setDatos={setDatos}
								isMyDataValid={isMyDataValid}
								modoModal={modoModal}
								setComprobanteToUpdate={setComprobanteToUpdate}
							/>
						</>
					),
				};
			})
			.reverse(),
	};

	//Get mi EmpresaInfo
	useEffect(() => {
		if (empresa_id) {
			const dataEmpresa = getEmpresaInfoById(empresa_id);
			dataEmpresa
				.then((respuesta) => {
					if (respuesta.status === 200) {
						setMiEmpresa(respuesta.data);
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response.data.message);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response.data.message);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response.data.message);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response.data.message);
					} else {
						console.log(error.response.data.message);
					}
				});
		}
	}, [empresa_id]);

	//Obtener Complementos de pago
	useEffect(() => {
		dispatch<any>(getComprobantesCP(setDatos));
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pt={6} pb={3}>
				<Card>
					<MDBox p={3} lineHeight={1}>
						<Grid
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='flex-end'
						>
							<MDButton
								size='small'
								color='primary'
								onClick={() => modoModal(0, { id: 0 })}
							>
								+ Agregar
							</MDButton>
							<CSVLink
								data={datos
									.map((d) => {
										if (d.estatus_comprobante_id === 1) {
											return {
												serie: d.serie,
												Folio: d.folio,
												Uuid: d.uuid,
												Cliente: d.nombre,
												FechaPago:
													d.complementoPagos[0] && d.complementoPagos[0].fecha_pago
														? d.complementoPagos[0].fecha_pago
														: '',
												MontoPagado:
													d.complementoPagos[0] && d.complementoPagos[0].monto
														? numeroAMoneda(d.complementoPagos[0].monto)
														: '',
												Estatus: 'Cancelado',
											};
										}
										if (d.estatus_comprobante_id === 2) {
											return {
												serie: d.serie,
												Folio: d.folio,
												Uuid: d.uuid,
												Cliente: d.nombre,
												FechaPago:
													d.complementoPagos[0] && d.complementoPagos[0].fecha_pago
														? d.complementoPagos[0].fecha_pago
														: '',
												MontoPagado:
													d.complementoPagos[0] && d.complementoPagos[0].monto
														? numeroAMoneda(d.complementoPagos[0].monto)
														: '',
												Estatus: 'Creado',
											};
										}
										if (d.estatus_comprobante_id === 3) {
											return {
												serie: d.serie,
												Folio: d.folio,
												Uuid: d.uuid,
												Cliente: d.nombre,
												FechaPago:
													d.complementoPagos[0] && d.complementoPagos[0].fecha_pago
														? d.complementoPagos[0].fecha_pago
														: '',
												MontoPagado:
													d.complementoPagos[0] && d.complementoPagos[0].monto
														? numeroAMoneda(d.complementoPagos[0].monto)
														: '',
												Estatus: 'Timbrado',
											};
										}
										if (d.estatus_comprobante_id === 4) {
											return {
												serie: d.serie,
												Folio: d.folio,
												Uuid: d.uuid,
												Cliente: d.nombre,
												FechaPago:
													d.complementoPagos[0] && d.complementoPagos[0].fecha_pago
														? d.complementoPagos[0].fecha_pago
														: '',
												MontoPagado:
													d.complementoPagos[0] && d.complementoPagos[0].monto
														? numeroAMoneda(d.complementoPagos[0].monto)
														: '',
												Estatus: 'Timbrado & Enviado',
											};
										}
										return {
											serie: d.serie,
											Folio: d.folio,
											Uuid: d.uuid,
											Cliente: d.nombre,
											FechaPago:
												d.complementoPagos[0] && d.complementoPagos[0].fecha_pago
													? d.complementoPagos[0].fecha_pago
													: '',
											MontoPagado:
												d.complementoPagos[0] && d.complementoPagos[0].monto
													? numeroAMoneda(d.complementoPagos[0].monto)
													: '',
											Estatus: d.estatus_comprobante_id,
										};
									})
									.reverse()}
								filename='Pagos.csv'
							>
								<MDButton size='small' sx={{ marginLeft: '5px', border: '1px solid' }}>
									+ Exportar CSV
								</MDButton>
							</CSVLink>
						</Grid>
						<MDTypography variant='h5' fontWeight='medium' color='primary'>
							Complementos de pago
						</MDTypography>
					</MDBox>
					<DataTable table={datosTabla} canSearch />
					{loading && (
						<>
							<MDBox m={4}>
								<PXLoader />
							</MDBox>
						</>
					)}
				</Card>
			</MDBox>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					zIndex: '19000',
				}}
			>
				<MDBox sx={style.Modal}>
				<Tooltip title='Cerrar' >
					<IconButton onClick={()=>handleClose()} color="secondary"  aria-label="upload picture" component="label" style={{position:'absolute',top:'3px',right:'3px', backgroundColor:'#fff',border:'1px solid #00ACC8'}}>
						<CloseIcon />
					</IconButton>
				</Tooltip>
					<GuardarPago
						handleOpen={handleOpen}
						modo={id}
						setDatos={setDatos}
						comprobanteToUpdate={comprobanteToUpdate}
					/>
				</MDBox>
			</Modal>
		</DashboardLayout>
	);
}
const style = {
	Modal: {
		position:'relative',
		width: '90%',
		maxWidth: '1200px',
		height: '95vh',
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
		overflow: 'auto',
		borderRadius: '10px',
	},
	EspacioTitulo: {
		marginTop: '1rem',
		marginBottom: '1rem',
	},
};
export default Pago;
