import { Button, Grid } from '@mui/material';
import MDAvatar from 'components/MDAvatar';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import brandPixcua from 'assets/images/pixcua.png';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import { descargarComprobante } from 'actions/Comprobante/comprobante';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { stringify } from 'uuid';

const ComprobanteXml = () => {
	const navigate = useNavigate();
	const params = useParams();

	const { authState } = useSelector((state: RootStore) => state.auth);

	const [comprobante, setComprobante] = useState({ estatus: 'cargando' });

	const atras = () => {
		navigate(-1);
	};
	useEffect(() => {
		const comprobanteData = descargarComprobante(params.uuid);
		comprobanteData
			.then((respuesta) => {
				console.log(respuesta);
				if (respuesta.status === 200) {
					setComprobante({ estatus: 'cargado' });
					// const url = window.URL.createObjectURL(new Blob([respuesta.data],{type: "text/xml"}));
					// const link = document.createElement('a');
					// link.href = url;
					// link.target= '_blankb';
					// link.setAttribute('href', url);
					// document.body.appendChild(link);
					// link.click();

					const url = window.URL.createObjectURL(new Blob([respuesta.data]));
					const link = document.createElement('a');
					link.href = url;
					link.target = '_blank';
					link.setAttribute('download', `${params.uuid}.xml`);
					document.body.appendChild(link);
					link.click();
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					setComprobante({ estatus: 'error' });
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					setComprobante({ estatus: 'error' });
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 401) {
					setComprobante({ estatus: 'error' });
					console.log('error401');
					console.log(error.response.data);
				} else if (error.response.status === 403) {
					setComprobante({ estatus: 'error' });
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					setComprobante({ estatus: 'error' });
					console.log('error404');
					console.log(error.response.data.message);
				} else {
					setComprobante({ estatus: 'error' });
					console.log(error.response.data.message);
				}
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const descargarXml = () => {
		const comprobanteData = descargarComprobante(params.uuid);
		comprobanteData
			.then((respuesta) => {
				console.log(respuesta);
				if (respuesta.status === 200) {
					setComprobante({ estatus: 'cargado' });
					const url = window.URL.createObjectURL(new Blob([respuesta.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', `${params.uuid}.xml`);
					document.body.appendChild(link);
					link.click();
				}
			})
			.catch((error) => {
				console.log(error);

				if (error.response.status === 500) {
					setComprobante({ estatus: 'error' });
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					setComprobante({ estatus: 'error' });
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 401) {
					setComprobante({ estatus: 'error' });
					console.log('error401');
					console.log(error.response.data);
				} else if (error.response.status === 403) {
					setComprobante({ estatus: 'error' });
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					setComprobante({ estatus: 'error' });
					console.log('error404');
					console.log(error.response.data.message);
				} else {
					setComprobante({ estatus: 'error' });
					console.log(error.response.data.message);
				}
			});
	};
	return (
		<>
			<MDBox pt={4} pb={3}>
				<Grid container justifyContent='center' alignItems='center'>
					<MDAvatar
						variant='square'
						style={{ marginTop: '2rem', width: '9rem' }}
						src={brandPixcua}
						alt='Avatar'
					/>
				</Grid>
				{comprobante.estatus === 'cargado' && (
					<MDBox pt={4} mt={5} textAlign='center'>
						<MDTypography variant='h4' fontWeight='medium' color='text'>
							Descargando tu comprobante:
						</MDTypography>
						<MDTypography variant='h4' fontWeight='medium' color='text'>
							{' '}
							{params.uuid}.xml
						</MDTypography>
						<MDTypography
							variant='button'
							color='text'
							onClick={descargarXml}
							style={{ cursor: 'pointer' }}
						>
							volver a{' '}
							<MDTypography
								variant='button'
								color='primary'
								fontWeight='medium'
								textGradient
							>
								Descargar
							</MDTypography>
						</MDTypography>
					</MDBox>
				)}
				{comprobante.estatus === 'error' && (
					<MDBox pt={4} mt={5} textAlign='center'>
						<MDTypography variant='h4' fontWeight='medium' color='text'>
							PÁGINA NO ENCONTRADA
						</MDTypography>
						<MDTypography variant='button' color='text'>
							volver a{' '}
							<MDTypography
								component={Link}
								to='/login'
								variant='button'
								color='primary'
								fontWeight='medium'
								textGradient
							>
								Inicio
							</MDTypography>
						</MDTypography>
					</MDBox>
				)}
				{comprobante.estatus === 'cargando' && (
					<>
						<MDBox pt={4} mt={5} textAlign='center'>
							<MDTypography variant='h4' fontWeight='medium' color='text'>
								Espere un momento.
							</MDTypography>
							<MDTypography variant='button' color='text'>
								Cargando...
							</MDTypography>
						</MDBox>
						<MDBox pt={4} mt={5} textAlign='center'>
							<MDTypography
								variant='button'
								color='text'
								onClick={descargarXml}
								style={{ cursor: 'pointer' }}
							>
								volver a{' '}
								<MDTypography
									variant='button'
									color='primary'
									fontWeight='medium'
									textGradient
								>
									intentar
								</MDTypography>
							</MDTypography>
						</MDBox>
					</>
				)}
			</MDBox>
		</>
	);
};

export default ComprobanteXml;
