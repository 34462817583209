import {
	AuthDispatchTypes,
	AUTH_LOGOUT,
	AUTH_SUCCESS,
	iAuthState,
	REGISTERING_FALSE,
	REGISTERING_OK,
	REGISTER_LOADING_END,
	REGISTER_START_LOADING,
} from 'actions/Auth/authTypes';

//Estado inicial
const INITIAL_STATE: iAuthState = {
	id: null,
	nombre: '',
	email: '',
	activo: false,
	empresa_id: null,
	tipo_usuario_id: null,
	authState: {
		loading: false,
		isAutenticated: false,
	},
	registerState: {
		loading: false,
		isRegister: false,
	},
};

//Inicia Reducer
export const AuthReducer = (
	state: iAuthState = INITIAL_STATE,
	action: AuthDispatchTypes
): iAuthState => {
	switch (action.type) {
		case REGISTER_START_LOADING:
			return {
				...state,
				registerState: { ...state.registerState, loading: true },
			};
		case REGISTER_LOADING_END:
			return {
				...state,
				registerState: { ...state.registerState, loading: false },
			};
		case AUTH_SUCCESS:
			return {
				...state,
				id: action.payload.usuario.id,
				nombre: action.payload.usuario.nombre,
				activo: action.payload.usuario.activo,
				email: action.payload.usuario.email,
				empresa_id: action.payload.usuario.empresa_id,
				tipo_usuario_id: action.payload.usuario.tipo_usuario_id,
				authState: { ...state.authState, isAutenticated: true, loading: false },
			};
		case AUTH_LOGOUT:
			return {
				...state,
				id: INITIAL_STATE.id,
				nombre: INITIAL_STATE.nombre,
				activo: INITIAL_STATE.activo,
				email: INITIAL_STATE.email,
				empresa_id: INITIAL_STATE.empresa_id,
				tipo_usuario_id: INITIAL_STATE.tipo_usuario_id,
				authState: { ...state.authState, isAutenticated: false },
			};
		case REGISTERING_OK:
			return {
				...state,
				registerState: { ...state.registerState, isRegister: true },
			};
		case REGISTERING_FALSE:
			return {
				...state,
				registerState: { ...state.registerState, isRegister: false },
			};

		default:
			return state;
	}
};
