import moment from 'moment';
//Definicion de interfaces -  se debe llamar igual a la entidad de la base de datos
export interface iComprobanteComplementoPago {
	c_regimen_fiscal_Cliente: string;
	c_regimen_fiscal_Empresa?: string;
	cadena_original: string;
	calle: string;
	cantidad_letra: string;
	certificado?: string;
	cliente_id?: number;
	codigo_postal?: string;
	codigo_qr?: string;
	colonia?: string;
	complementoPagos: iComplementoPagos[];
	createdAt: string;
	descuento?: number;
	empresa_id?: number;
	estado: string;
	estatus_comprobante_id?: number;
	fecha?: string;
	fecha_timbrado?: string;
	folio?: number;
	forma_de_pago?: string;
	id?: number;
	iva: number;
	isr?: number;
	localidad?: string;
	lugar_expedicion: string;
	metodo_de_pago?: string;
	moneda: string;
	motivo_de_descuento: string;
	municipio: string;
	no_certificado?: string;
	no_certificado_sat?: string;
	no_exterior?: string;
	no_interior?: string;
	nombre: string;
	num_cta_pago?: string;
	pagado?: boolean;
	pais: string;
	referencia?: string;
	retencion_isr: number;
	retencion_iva: number;
	rfc: string;
	sello?: string;
	sello_sat?: string;
	serie: string;
	subtotal: number;
	subtotal_iva_cero: number;
	tasa: number;
	timbrado?: boolean;
	tipo_cambio?: number;
	tipo_de_comprobante: string;
	tipo_uso: string;
	total: number;
	updatedAt?: string;
	uuid?: string;
	version?: string;
	//falta tipo_Persona
}
export const initComplementoDePago: iComprobanteComplementoPago = {
	c_regimen_fiscal_Cliente: '',
	c_regimen_fiscal_Empresa: '',
	cadena_original: '',
	calle: '',
	cantidad_letra: '',
	certificado: '',
	cliente_id: null,
	codigo_postal: '',
	codigo_qr: '',
	colonia: '',
	complementoPagos: [
		{
			cad_pago: 0,
			cert_pago: 0,
			comprobante_id: 0,
			createdAt: '',
			cta_beneficiario: '',
			cta_ordenante: '',
			documentoRelacionados: [
				{
					complemento_pago_id: 0,
					createdAt: '',
					documento_id: '',
					folio: '',
					id: 0,
					imp_pagado: 0,
					imp_saldo_ant: 0,
					imp_saldo_insoluto: 0,
					metodo_pago_dr: '',
					moneda_dr: '',
					num_parcialidad: '',
					serie: '',
					tipo_cambio_dr: '',
					updatedAt: '',
				},
			],
			fecha_pago: '',
			forma_pago_p: '',
			id: 0,
			moneda_p: '',
			monto: 0,
			nom_banco_ord_ext: '',
			num_operacion: '',
			rfc_emisor_cta_ben: '',
			rfc_emisor_cta_ord: '',
			sello_pago: '',
			tipo_cad_pago: '',
			tipo_cambio_p: '',
			updatedAt: '',
			version: '',
		},
	],
	createdAt: '',
	descuento: 0,
	empresa_id: 0,
	estado: '',
	estatus_comprobante_id: 0,
	fecha: '',
	fecha_timbrado: '',
	folio: 0,
	forma_de_pago: '',
	id: 0,
	iva: 0,
	isr: 0,
	localidad: '',
	lugar_expedicion: '',
	metodo_de_pago: '',
	moneda: '',
	motivo_de_descuento: '',
	municipio: '',
	no_certificado: '',
	no_certificado_sat: '',
	no_exterior: '',
	no_interior: '',
	nombre: '',
	num_cta_pago: '',
	pagado: false,
	pais: '',
	referencia: '',
	retencion_isr: 0,
	retencion_iva: 0,
	rfc: '',
	sello: '',
	sello_sat: '',
	serie: '',
	subtotal: 0,
	subtotal_iva_cero: 0,
	tasa: 0,
	timbrado: false,
	tipo_cambio: 0,
	tipo_de_comprobante: '',
	tipo_uso: '',
	total: 0,
	updatedAt: '',
	uuid: '',
	version: '',
};
export const initPostComplementoDePago = {
	c_regimen_fiscal_Cliente: '',
	c_regimen_fiscal_Empresa: '',
	serie: 'CP',
	calle: '',
	cantidad_letra: '',
	cliente_id: 0,
	codigo_postal: '',
	colonia: '',
	descuento: 0,
	tipo_de_comprobante: 'P',
	tipo_uso: 'CP01',
	nombre: '',
	rfc: '',
	no_exterior: '',
	no_interior: '',
	pais: '',
	estado: '',
	localidad: '',
	municipio: '',
	referencia: '',
	telefono: '',
	email: '',
	tasa: 0,
	subtotal: 0,
	subtotal_iva_cero: 0,
	motivo_de_descuento: '',
	iva: 0,
	retencion_iva: 0,
	retencion_isr: 0,
	total: 0,
	lugar_expedicion: '',
	moneda: 'MXN',
	tipo_Persona: '',
	complementoPago: [
		{
			version: '1.0',
			fecha_pago: '',
			forma_pago_p: '',
			moneda_p: 'MXN',
			tipo_cambio_p: 0,
			monto: 0,
			num_operacion: '',
			rfc_emisor_cta_ord: '',
			rfc_emisor_cta_ben: '',
			nom_banco_ord_ext: '',
			cta_ordenante: '',
			cta_beneficiario: '',
			tipo_cad_pago: '',
			cert_pago: '',
			cad_pago: '',
			sello_pago: '',
			documentosRelacionados: [
				{
					documento_id: '',
					serie: '',
					folio: '',
					moneda_dr: '',
					tipo_cambio_dr: 0,
					metodo_pago_dr: '',
					num_parcialidad: '',
					imp_saldo_ant: 0,
					imp_pagado: 0,
					imp_saldo_insoluto: 0,
				},
			],
		},
	],
};

export const initDocumentoRelacionado = {
	documento_id: '',
	serie: '',
	folio: '',
	moneda_dr: '',
	tipo_cambio_dr: '',
	metodo_pago_dr: 'PPD',
	num_parcialidad: '',
	imp_saldo_ant: '',
	imp_pagado: '',
	imp_saldo_insoluto: '',
};

const maxTime = moment().format('YYYY-MM-DD');

export const initComplementoPago = {
	id: '',
	comprobante_id: 0,
	version: '1.0',
	fecha_pago: maxTime,
	forma_pago_p: '01',
	moneda_p: 'MXN',
	tipo_cambio_p: 0,
	monto: '',
	num_operacion: '',
	rfc_emisor_cta_ord: '',
	rfc_emisor_cta_ben: '',
	nom_banco_ord_ext: '',
	cta_ordenante: '',
	cta_beneficiario: '',
	tipo_cad_pago: '',
	cert_pago: '',
	cad_pago: '',
	sello_pago: '',
};

export interface iComprobante {
	empresa_i?: number;
	empresa_id?: number;
	id?: number;
	folio?: number;
	sello?: string;
	no_certificado?: string;
	certificado?: string;
	pagado?: boolean;
	timbrado?: boolean;
	sello_sat?: string;
	no_certificado_sat?: string;
	fecha_timbrado?: string;
	version?: string;
	estatus_comprobante_id?: number;

	serie?: string;
	tipo_comprobante_id?: number;
	tipo_de_comprobante: string;
	tipo_uso: string;
	c_regimen_fiscal_Cliente: string;
	c_regimen_fiscal_Empresa?: string;

	cliente_id?: number;
	nombre: string;
	rfc: string;
	calle: string;
	no_exterior: string;
	no_interior: string;
	pais: string;
	estado: string;
	localidad: string;
	municipio: string;
	colonia: string;
	codigo_postal: string;
	referencia: string;
	telefono?: string;
	email?: string;
	forma_de_pago: string;
	metodo_de_pago: string;
	tipo_Persona?: string;

	tasa: number;
	subtotal: number;
	subtotal_iva_cero: number;
	descuento: number;
	motivo_de_descuento: string;
	iva: number;
	isr?: number;
	retencion_isr: number;
	retencion_iva: number;
	total: number;
	cantidad_letra: string;

	uuid?: string;
	codigo_qr?: string;

	lugar_expedicion: string;
	num_cta_pago: string;
	moneda: string;
	tipo_cambio?: number;
	fecha?: string;
	comprobantesDetalles: iComprobante_detalle[];
	tipo_relacion_id: string | number | null;
	comprobante_relacionado_uuid: string | number | null;
}
export interface iComplementoPagos {
	cad_pago?: any;
	cert_pago?: any;
	comprobante_id: number;
	createdAt?: string;
	cta_beneficiario?: string;
	cta_ordenante?: string;
	documentoRelacionados: iDocumentoRelacionados[];
	fecha_pago: string;
	forma_pago_p: string;
	id: number;
	moneda_p: string;
	monto: number;
	nom_banco_ord_ext: string;
	num_operacion: string;
	rfc_emisor_cta_ben: string;
	rfc_emisor_cta_ord: string;
	sello_pago?: any;
	tipo_cad_pago?: any;
	tipo_cambio_p?: any;
	updatedAt?: string;
	version?: string;
}
export interface iDocumentoRelacionados {
	complemento_pago_id: number;
	createdAt: string;
	documento_id: string;
	folio: string;
	id: number;
	imp_pagado: number;
	imp_saldo_ant: number;
	imp_saldo_insoluto: number;
	metodo_pago_dr: string;
	moneda_dr: string;
	num_parcialidad: string;
	serie: string;
	tipo_cambio_dr?: any;
	updatedAt?: string;
}
export interface iComprobante_detalle {
	id?: number;
	comprobante_id?: number;
	cantidad: number;
	unidad: string;
	precio_unitario: number;
	importe: number;
	concepto: string;
	tasa_iva: number | null | string;
	unidad_sat_id: string;
	concepto_sat_id: string;
	numero_identificacion?: string;
}
export const initComprobanteDetalle: iComprobante_detalle = {
	concepto_sat_id: '',
	cantidad: 0,
	unidad_sat_id: '',
	unidad: '',
	concepto: '',
	precio_unitario: 0,
	importe: 0,
	tasa_iva: 16,
	numero_identificacion: '',
};
export const initComprobanteDetalleList: iComprobante_detalle[] = [];

export interface iTotales {
	subtotal: number;
	subtotal_iva_cero: number;
	descuento: number;
	motivo_de_descuento: string;
	iva: number;
	isr: number;
	retencion_iva: number;
	retencion_isr: number;
	total: number;
	cantidad_letra: string;
}
export const initTotales: iTotales = {
	subtotal: 0,
	subtotal_iva_cero: 0,
	descuento: 0,
	motivo_de_descuento: 'Descuento',
	iva: 0,
	isr: 0,
	retencion_iva: 0,
	retencion_isr: 0,
	total: 0,
	cantidad_letra: '',
};
export interface iExtras {
	lugar_expedicion: string;
	num_cta_pago: string;
	moneda: string;
	tipo_cambio: number;
	tipo_relacion_id: string | number | null;
	comprobante_relacionado_uuid: string | number;
}
export const initExtras: iExtras = {
	lugar_expedicion: '',
	num_cta_pago: '',
	moneda: 'MXN',
	tipo_cambio: 0,
	tipo_relacion_id: '',
	comprobante_relacionado_uuid: '',
};
