import { axiosClient, getToken } from "../../Config/axiosConfig";
import { iMetodoPago } from "./metodo_pagoTypes";

class formaPago {
    async get() {
        await getToken();
        return await axiosClient.get<iMetodoPago[]>("/metodoPago");
    }
    async getId(id:number) {
        await getToken();
        return await axiosClient.get<iMetodoPago>(`/metodoPago/${id}`);
    }
}

export default new formaPago();