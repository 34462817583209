import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {  toast } from 'react-toastify';

const BtnEliminarDoc = ({
	item,
	comprobanteDetalleList,
	setComprobanteDetalleList,
}: any) => {
	const handleDelete = () => {
		setComprobanteDetalleList(
			comprobanteDetalleList.filter((itemList: any) => itemList !== item)
		);
		toast.success('Eliminado!');
	};
	return (
		<>
			<Button
				variant='contained'
				size='small'
				endIcon={<DeleteIcon style={{ color: '#fff' }} />}
				style={{ backgroundColor: '#eb5353', color: '#fff', fontSize: '10px' }}
				onClick={handleDelete}
			>
				Eliminar
			</Button>
		</>
	);
};

export default BtnEliminarDoc;
