// Material Dashboard 2 PRO React layouts
import ProfileOverview from 'layouts/pages/profile/profile-overview';

// @mui icons
import Icon from '@mui/material/Icon';

//pantallas Pixcua
import Comprobantes from 'pantallas/Comprobantes/index';
import MiCuenta from 'pantallas/MiCuenta/settings';
import Clientes from 'pantallas/Clientes/';
import Certificados from 'pantallas/Certificados';
import Suscripciones from 'pantallas/Suscripciones';
import Pago from 'pantallas/Pago';

import Analytics from 'pantallas/Dashboard/analytics';
import Reportes from 'pantallas/Reportes';
const routes = [
	{
		type: 'collapse',
		name: 'Dashboard',
		key: 'dashboard',
		route: '/',
		icon: <Icon fontSize='medium'>dashboard</Icon>,
		component: <Analytics />,
		noCollapse: true,
	},
	{
		type: 'collapse',
		name: 'Clientes',
		key: 'clientes',
		route: 'clientes',
		icon: <Icon fontSize='medium'>person</Icon>,
		component: <Clientes />,
		noCollapse: true,
	},
	{
		type: 'collapse',
		name: 'Comprobantes',
		key: 'comprobantes',
		route: 'comprobantes',
		icon: <Icon fontSize='medium'>description</Icon>,
		component: <Comprobantes />,
		noCollapse: true,
	},
	{
		type: 'collapse',
		name: 'Pagos',
		key: 'pixcuaPagos',
		route: 'pagos',
		icon: <Icon fontSize='medium'>paid</Icon>,
		component: <Pago />,
		noCollapse: true,
	},
	{
		type: 'collapse',
		name: 'Reportes',
		key: 'pixcuaReportes',
		route: 'reportes',
		icon: <Icon fontSize='medium'>assessment</Icon>,
		component: <Reportes />,
		noCollapse: true,
	},
	{
		type: 'collapse',
		name: 'Configuracion',
		key: 'configuracion',
		icon: <Icon fontSize='medium'>settings</Icon>,
		collapse: [
			{
				name: 'Mi Cuenta',
				key: 'miCuenta',
				route: 'configuracion/miCuenta',
				icon: <Icon fontSize='medium'>person</Icon>,
				component: <MiCuenta />,
			},
			{
				name: 'Certificados',
				key: 'certificados',
				route: 'configuracion/certificados',
				component: <Certificados />,
			},
			{
				name: 'Suscripciones',
				key: 'suscripciones',
				route: 'configuracion/suscripciones',
				component: <Suscripciones />,
			},
		],
	},
];

export default routes;
