// @mui material components
import { Card, Checkbox, FormControlLabel, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import { getClientesBySearch } from 'actions/Clientes/clientes';
import { cliente, initcliente } from 'actions/Clientes/clientesTypes';
import { getComprobantesByParams } from 'actions/Comprobante/comprobante';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
// import DataTable from 'components/Tables/DataTable';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import BaseLayout from './components/BaseLayout';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import { numeroAMoneda } from 'helpers/aMoneda';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import ProgressBar from '../Comprobantes/components/ProgressBar/ProgressBar';
// import DataTable from 'components/Tables/DataTable';
import DataTable from './components/DataTable';
// Settings page components

const Reportes = () => {
	const [formFecha, setFormFecha] = useState({
		fecha_inicio: '',
		fecha_fin: '',
	});
	const handleInputChangeFecha = ({ target }: any) => {
		setFormFecha({
			...formFecha,
			[target.name]: target.value,
		});
	};

	const [checkboxFechasValue, setCheckboxFechasValue] = useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckboxFechasValue(event.target.checked);
	};
	const [checkboxClienteValue, setCheckboxClienteValue] = useState(false);

	const handleClienteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckboxClienteValue(event.target.checked);
	};
	//useState para info del cliente
	const [formCliente, setFormCliente] = useState<cliente>(initcliente);
	const handleInputChangeCliente = ({ target }: any) => {
		setFormCliente({
			...formCliente,
			[target.name]: target.value,
		});
	};
	//useState para lista de busqueda de cliente
	const [arrayClientes, setArrayClientes] = useState([]);
	const [arrayReportesData, setArrayReportesData] = useState([]);
	const maxTime = moment().format('YYYY-MM-DD');

	//Estuctura de datos para tabla
	const datosTabla = {
		columns: [
			{ Header: 'Folio', accessor: 'Folio' },
			{ Header: 'Cliente', accessor: 'Cliente', align: 'left' },
			{ Header: 'Fecha', accessor: 'Fecha', align: 'left' },
			{ Header: 'Estatus', accessor: 'Estatus', align: 'left' },
			{ Header: 'Subtotal', accessor: 'Subtotal', align: 'left' },
			{ Header: 'I.V.A.', accessor: 'IVA', align: 'left' },
			{ Header: 'Total', accessor: 'Total', align: 'left' },
		],
		rows: arrayReportesData
			.map((item) => {
				return {
					Folio: item.serie + item.folio,
					Cliente: item.nombre,
					Fecha: item.fecha,
					// Estatus: item.estatus_comprobante_id,
					Estatus: (
						<>
							<ProgressBar progressId={item.estatus_comprobante_id} />
						</>
					),
					Subtotal: numeroAMoneda(item.subtotal),
					IVA: numeroAMoneda(item.iva),
					Total: numeroAMoneda(item.total),
				};
			})
			.reverse(),
	};
	//Debouncing para obtener el autocompletado de clientes
	useEffect(() => {
		let timeOutSearch = setTimeout(() => {
			if (formCliente.nombre.length > 0) {
				//Get ClienteBySearch
				const data = getClientesBySearch(formCliente.nombre);
				data
					.then((respuesta) => {
						if (respuesta.status === 200) {
							setArrayClientes(respuesta.data);
						}
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500');
							console.log(error.response.data.message);
						} else if (error.response.status === 400) {
							console.log('error400');
							console.log(error.response.data.message);
						} else if (error.response.status === 403) {
							console.log('error403');
							console.log(error.response.data.message);
						} else if (error.response.status === 404) {
							console.log('error404');
							setArrayClientes([]);
						} else {
							console.log(error.response.data.message);
						}
					});
			}
		}, 500);

		return () => {
			clearTimeout(timeOutSearch);
		};
	}, [formCliente.nombre]);
	// Asignación de cliente
	useEffect(() => {
		const clienteData = arrayClientes.find(
			(item) => item.nombre === formCliente.nombre
		);
		if (clienteData) {
			setFormCliente(clienteData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formCliente.nombre, arrayClientes]);

	//Efecto para limpiar imputCliente cuando se desactiva el check
	useEffect(() => {
		if (!checkboxClienteValue) {
			setFormCliente(initcliente);
		}
	}, [checkboxClienteValue]);
	//Efecto para limpiar imputsFecha cuando se desactiva el check
	useEffect(() => {
		if (!checkboxFechasValue) {
			setFormFecha({
				fecha_inicio: '',
				fecha_fin: '',
			});
		}
	}, [checkboxFechasValue]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		setArrayReportesData([]);

		if (!formFecha.fecha_inicio && !formFecha.fecha_fin && !formCliente.id) {
			console.log('solicitando TODO');
			const dataComprobantesReportes = getComprobantesByParams(
				formCliente.id,
				formFecha.fecha_inicio,
				formFecha.fecha_fin
			);
			dataComprobantesReportes
				.then((respuesta) => {
					if (respuesta.status === 200) {
						console.log('Respuesta!!Reportes', respuesta.data);
						const sumaIVA = respuesta.data
							.map((item: any) => {
								if (
									item.estatus_comprobante_id === 1 ||
									item.estatus_comprobante_id === 5
								) {
									return 0;
								}
								return item.iva;
							})
							.reduce((prev: any, curr: any) => prev + curr, 0);
						console.log('sumaIVA', sumaIVA);

						const sumaSubtotal = respuesta.data
							.map((item: any) => {
								if (
									item.estatus_comprobante_id === 1 ||
									item.estatus_comprobante_id === 5
								) {
									return 0;
								}
								return item.subtotal;
							})
							.reduce((prev: any, curr: any) => prev + curr, 0);
						console.log('sumaSubtotal', sumaSubtotal);

						const sumaTotal = respuesta.data
							.map((item: any) => {
								if (
									item.estatus_comprobante_id === 1 ||
									item.estatus_comprobante_id === 5
								) {
									return 0;
								}
								return item.total;
							})
							.reduce((prev: any, curr: any) => prev + curr, 0);
						console.log('sumaTotal', sumaTotal);
						respuesta.data.unshift({
							folio: '',
							serie: '',
							iva: sumaIVA,
							total: sumaTotal,
							subtotal: sumaSubtotal,
						});
						setArrayReportesData(respuesta.data);
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response);
						if (
							error.response.data === 'No ingreso los datos requerido en el query'
						) {
							toast.error('Ingrese un valor valido en los tipo de filtrado');
						}
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response);
					} else {
						console.log(error.response);
					}
				});
		} else if (formFecha.fecha_inicio && formFecha.fecha_fin && formCliente.id) {
			console.log('solicitando 3');
			const dataComprobantesReportes = getComprobantesByParams(
				formCliente.id,
				formFecha.fecha_inicio,
				formFecha.fecha_fin
			);
			dataComprobantesReportes
				.then((respuesta) => {
					if (respuesta.status === 200) {
						const sumaIVA = respuesta.data
							.map((item: any) => {
								if (
									item.estatus_comprobante_id === 1 ||
									item.estatus_comprobante_id === 5
								) {
									return 0;
								}
								return item.iva;
							})
							.reduce((prev: any, curr: any) => prev + curr, 0);
						console.log('sumaIVA', sumaIVA);

						const sumaSubtotal = respuesta.data
							.map((item: any) => {
								if (
									item.estatus_comprobante_id === 1 ||
									item.estatus_comprobante_id === 5
								) {
									return 0;
								}
								return item.subtotal;
							})
							.reduce((prev: any, curr: any) => prev + curr, 0);
						console.log('sumaSubtotal', sumaSubtotal);

						const sumaTotal = respuesta.data
							.map((item: any) => {
								if (
									item.estatus_comprobante_id === 1 ||
									item.estatus_comprobante_id === 5
								) {
									return 0;
								}
								return item.total;
							})
							.reduce((prev: any, curr: any) => prev + curr, 0);
						console.log('sumaTotal', sumaTotal);
						respuesta.data.unshift({
							folio: '',
							serie: '',
							iva: sumaIVA,
							total: sumaTotal,
							subtotal: sumaSubtotal,
						});
						setArrayReportesData(respuesta.data);
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response);
					} else {
						console.log(error.response);
					}
				});
		} else if (formFecha.fecha_inicio && formFecha.fecha_fin && !formCliente.id) {
			console.log('solicitando FECHAS');
			const dataComprobantesReportes = getComprobantesByParams(
				formCliente.id,
				formFecha.fecha_inicio,
				formFecha.fecha_fin
			);
			dataComprobantesReportes
				.then((respuesta) => {
					if (respuesta.status === 200) {
						const sumaIVA = respuesta.data
							.map((item: any) => {
								if (
									item.estatus_comprobante_id === 1 ||
									item.estatus_comprobante_id === 5
								) {
									return 0;
								}
								return item.iva;
							})
							.reduce((prev: any, curr: any) => prev + curr, 0);
						console.log('sumaIVA', sumaIVA);

						const sumaSubtotal = respuesta.data
							.map((item: any) => {
								if (
									item.estatus_comprobante_id === 1 ||
									item.estatus_comprobante_id === 5
								) {
									return 0;
								}
								return item.subtotal;
							})
							.reduce((prev: any, curr: any) => prev + curr, 0);
						console.log('sumaSubtotal', sumaSubtotal);

						const sumaTotal = respuesta.data
							.map((item: any) => {
								if (
									item.estatus_comprobante_id === 1 ||
									item.estatus_comprobante_id === 5
								) {
									return 0;
								}
								return item.total;
							})
							.reduce((prev: any, curr: any) => prev + curr, 0);
						console.log('sumaTotal', sumaTotal);
						respuesta.data.unshift({
							folio: '',
							serie: '',
							iva: sumaIVA,
							total: sumaTotal,
							subtotal: sumaSubtotal,
						});
						setArrayReportesData(respuesta.data);
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response);
					} else {
						console.log(error.response);
					}
				});
		} else if (
			!formFecha.fecha_inicio &&
			!formFecha.fecha_fin &&
			formCliente.id
		) {
			console.log('Solicitando CLIENTE');
			const dataComprobantesReportes = getComprobantesByParams(
				formCliente.id,
				formFecha.fecha_inicio,
				formFecha.fecha_fin
			);
			dataComprobantesReportes
				.then((respuesta) => {
					if (respuesta.status === 200) {
						const sumaIVA = respuesta.data
							.map((item: any) => {
								if (
									item.estatus_comprobante_id === 1 ||
									item.estatus_comprobante_id === 5
								) {
									return 0;
								}
								return item.iva;
							})
							.reduce((prev: any, curr: any) => prev + curr, 0);
						console.log('sumaIVA', sumaIVA);

						const sumaSubtotal = respuesta.data
							.map((item: any) => {
								if (
									item.estatus_comprobante_id === 1 ||
									item.estatus_comprobante_id === 5
								) {
									return 0;
								}
								return item.subtotal;
							})
							.reduce((prev: any, curr: any) => prev + curr, 0);
						console.log('sumaSubtotal', sumaSubtotal);

						const sumaTotal = respuesta.data
							.map((item: any) => {
								if (
									item.estatus_comprobante_id === 1 ||
									item.estatus_comprobante_id === 5
								) {
									return 0;
								}
								return item.total;
							})
							.reduce((prev: any, curr: any) => prev + curr, 0);
						console.log('sumaTotal', sumaTotal);
						respuesta.data.unshift({
							folio: '',
							serie: '',
							iva: sumaIVA,
							total: sumaTotal,
							subtotal: sumaSubtotal,
						});
						console.log('newRespuesta1', respuesta.data);

						setArrayReportesData(respuesta.data);
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response);
					} else {
						console.log(error.response);
					}
				});
		} else {
			console.log('Revisa los campos a filtrar para obtener la información');
			toast.error('Revisa los campos a filtrar para obtener la información');
		}
	};

	const ExcelExportData = arrayReportesData
		.map((item) => {
			const objExcel = (estatus: string): {} => {
				return {
					Folio: item.folio ? item.serie + item.folio : '',
					Cliente: item.nombre ? item.nombre : '',
					Fecha: item.fecha ? item.fecha : '',
					Estatus: estatus,
					Subtotal: item.subtotal ? item.subtotal : 0,
					IVA: item.iva ? item.iva : 0,
					Total: item.total ? item.total : 0,
				};
			};
			if (item.estatus_comprobante_id === 1) {
				return objExcel('Cancelado');
			} else if (item.estatus_comprobante_id === 2) {
				return objExcel('Creado');
			} else if (item.estatus_comprobante_id === 3) {
				return objExcel('Timbrado');
			} else if (item.estatus_comprobante_id === 4) {
				return objExcel('Timbrado & Enviado');
			} else if (item.estatus_comprobante_id === 5) {
				return objExcel('En proceso de Cancelación');
			} else {
				return objExcel('');
			}
		})
		.reverse();

	const fileType =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';
	const exportToExcel = async () => {
		const ws = XLSX.utils.json_to_sheet(ExcelExportData);
		const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(
			data,
			`Reporte${formFecha.fecha_inicio}-${formFecha.fecha_fin}${formCliente.nombre}` +
				fileExtension
		);
	};

	return (
		<BaseLayout>
			<MDBox mt={4}>
				<Grid container>
					<Grid item xs={12}>
						<MDBox mb={3}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<MDBox
										component='form'
										role='form'
										onSubmit={handleSubmit}
										mt={5}
										pb={3}
										px={3}
									>
										<MDBox display={'flex'} flexWrap={'wrap'}>
											<Card style={{ width: '50%', marginRight: '1rem', padding: '1rem' }}>
												<MDBox display={'flex'}>
													<FormControlLabel
														sx={{
															marginLeft: '.4rem',
															width: '11rem',
														}}
														control={
															<Checkbox
																checked={checkboxFechasValue}
																onChange={handleChange}
															/>
														}
														label='Por Fechas'
														color='secondary'
													/>
													<MDBox
														display={'flex'}
														flexWrap={'wrap'}
														style={{ width: '260px' }}
													>
														{checkboxFechasValue && (
															<>
																<MDBox style={{ marginRight: '1rem' }}>
																	<InputLabel variant='standard' htmlFor='FechaPagoCP'>
																		Fecha de Inicio:
																	</InputLabel>
																	<input
																		type='date'
																		id='FechaPagoCP'
																		name='fecha_inicio'
																		className='customInputFiled'
																		value={formFecha.fecha_inicio}
																		onChange={handleInputChangeFecha}
																		onKeyDown={(e) => e.preventDefault()}
																		disabled={!checkboxFechasValue}
																	/>
																</MDBox>
																<MDBox>
																	<InputLabel variant='standard' htmlFor='FechaPagoCP'>
																		Fecha de Fin:
																	</InputLabel>
																	<input
																		type='date'
																		id='FechaPagoCP'
																		name='fecha_fin'
																		// min={minTime}
																		max={maxTime}
																		className='customInputFiled'
																		value={formFecha.fecha_fin}
																		onChange={handleInputChangeFecha}
																		onKeyDown={(e) => e.preventDefault()}
																		disabled={!checkboxFechasValue}
																	/>
																</MDBox>
															</>
														)}
													</MDBox>
												</MDBox>
												<MDBox display={'flex'} mt={2}>
													<FormControlLabel
														sx={{
															marginLeft: '.4rem',
															width: '11rem',
														}}
														control={
															<Checkbox
																checked={checkboxClienteValue}
																onChange={handleClienteChange}
															/>
														}
														label='Por Cliente'
														color='secondary'
													/>

													<MDBox style={{ width: '260px' }}>
														{checkboxClienteValue && (
															<>
																<input
																	className='customInputFiled'
																	type={'text'}
																	list='datalistOptions'
																	id='nombreClienteWizard'
																	placeholder='Nombre o razón social*'
																	value={formCliente.nombre ?? ''}
																	name='nombre'
																	onChange={handleInputChangeCliente}
																	disabled={!checkboxClienteValue}
																	autoComplete='off'
																/>

																<datalist id='datalistOptions'>
																	{arrayClientes.map((opcion) => (
																		<option key={opcion.id} value={opcion.nombre}>
																			{opcion.nombre}
																		</option>
																	))}
																</datalist>
															</>
														)}
													</MDBox>
												</MDBox>
											</Card>

											<MDBox
												display={'flex'}
												justifyContent={'center'}
												alignItems={'center'}
											>
												<MDBox m={2}>
													<MDButton
														type='submit'
														size='small'
														color='primary'
														style={{ height: '5rem' }}
													>
														+ Generar Reporte
													</MDButton>
												</MDBox>
												<MDBox m={2}>
													<MDButton
														size='small'
														color='success'
														style={{ height: '5rem' }}
														onClick={() => {
															exportToExcel();
														}}
														disabled={arrayReportesData.length > 0 ? false : true}
													>
														+ Exportar excel
													</MDButton>
												</MDBox>
											</MDBox>
										</MDBox>
									</MDBox>
								</Grid>
								<Grid item xs={12}>
									<MDBox pt={2} pb={3}>
										<Card>
											<MDBox display={'flex'} justifyContent={'center'}>
												<MDBox style={{ width: '98%' }}>
													<DataTable
														table={datosTabla}
														canSearch
														entriesPerPage={{
															defaultValue: arrayReportesData.length,
															entries: [5, 10, 15, 20, 25],
														}}
													/>
												</MDBox>
												<ToastContainer
													position='top-right'
													autoClose={3000}
													hideProgressBar
													newestOnTop={false}
													closeOnClick
													rtl={false}
													pauseOnFocusLoss
													draggable
													pauseOnHover
												/>
											</MDBox>
										</Card>
									</MDBox>
								</Grid>
							</Grid>
						</MDBox>
					</Grid>
				</Grid>
			</MDBox>
		</BaseLayout>
	);
};

export default Reportes;
