
import { useEffect, useState } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';

// @mui material icons
import EditIcon from '@mui/icons-material/Edit';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';

// Images
import imagen from 'assets/images/sinImagen.png';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import {
	getEmpresaById,
	postEmpresaLogoByLogo,
} from 'actions/MiCuenta/miCuenta';
import { useToggle } from 'hooks/useToggle';
import { useForm } from 'hooks/useForm';
import PXLoader from 'components/PXLoader/PXLoader';
import { Toast } from 'Config/swalAlert';

function Header(): JSX.Element {
	const { empresa_id } = useSelector((state: RootStore) => state.auth);
	const [nombreEmpresa, setnombreEmpresa] = useState('Nombre de la empresa');
	const [value, toggleValue] = useToggle(true); //Recibe el valor inicial
	const [errorMsg, setErrorMsg] = useState('');
	const [formValues, handleInputChange, reset] = useForm({
		file: '',
	});
	const { file } = formValues;
	const [logo, setLogo] = useState('');
	const [enviando, setEnviando] = useState(false);
	const baseURL = process.env.REACT_APP_API_URL;

	useEffect(() => {
		const dataEmpresa = getEmpresaById(empresa_id);
		dataEmpresa
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setnombreEmpresa(respuesta.data.nombre);
					setLogo(respuesta.data.logo);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response.data.message);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response.data.message);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response.data.message);
				} else if (error.response.status === 404) {
					console.log('error404::::::');
					console.log(error.response.data.message);
				} else {
					console.log(error.response.data.message);
				}
			});
	}, [empresa_id]);

	const handleSubmit = (e: any) => {
		e.preventDefault();

		const formData = new FormData(e.currentTarget);

		if (isFormValid()) {
			setEnviando(true);
			postEmpresaLogoByLogo(formData)
				.then((respuesta) => {
					if (respuesta.status === 201) {
						window.location.reload();
						setLogo(respuesta.data.url);
						setTimeout(() => {
							setEnviando(false);
						}, 2000);
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response);
						setEnviando(false);
					} else if (error.response.status === 400) {
						if (error.response.data.data.message === 'File too large') {
							Toast.fire({
								icon: 'error',
								title: `Archivo demasiado grande.`,
								timer: 2000,
								position: 'top-right',
							});
							console.log('FileTOLAGE!!');
						}
						console.log(error.response.data.message);
						setEnviando(false);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response.data.message);
						setEnviando(false);
					} else if (error.response.status === 404) {
						console.log('error404');
						console.log(error.response.data.message);
						setEnviando(false);
					} else {
						console.log(error.response.data.message);
						setEnviando(false);
					}
				});
		}
	};

	const isFormValid = (): boolean => {
		// El inputFile no debe estar vacío
		if (file === '') {
			setErrorMsg('Por favor seleccione una imagen');
			return false;
		}
		//Regresa estado a un string vacío
		setErrorMsg('');
		return true;
	};

	const cancelarEdicion = () => {
		toggleValue();
	};

	return (
		<Card id='profile'>
			<MDBox style={{ marginTop: '1rem' }}>
				{!value && (
					<MDBox
					ml={2}
					mb={3}
					mt={1}
					style={{
						maxWidth: '600px',
						lineHeight: '18px',
						fontSize: '15px',
						textAlign: 'justify',
						backgroundColor: '#e1a90244',
						border: '2px solid #ffbf00',
						padding: '10px',
						borderRadius: '10px',
					}}
				>
					<MDTypography variant='captions' style={{ color: '#383838' }}>
						NOTA: El peso de la imagen no debe ser mayor a 600KB
					</MDTypography>
				</MDBox>
				)}
				<Grid container spacing={3} alignItems='center'>
					<Grid
						item
						marginLeft={2}
						paddingBottom={2}
					>
						{logo ? (
							<MDBox className='image-picture'>
								<img
									className='img-cover'
									src={`${baseURL}/empresas/imagen/${logo}`}
									alt='LogoEmpresa'
								/>
							</MDBox>
						) : (
							<MDAvatar src={imagen} alt='profile-image' size='md' shadow='sm' />
						)}
					</Grid>
					<Grid item marginLeft={-4}>
						<Button
							color='secondary'
							variant='text'
							size='large'
							startIcon={<EditIcon />}
							onClick={toggleValue}
						>
							Editar
						</Button>
					</Grid>
					<Grid item>
						<MDBox height='100%' mt={0.5} lineHeight={1}>
							<MDTypography variant='h5' fontWeight='medium'>
								{nombreEmpresa}
							</MDTypography>
						</MDBox>
					</Grid>
					{!value && (
						<form onSubmit={handleSubmit}>
							<MDBox display={'flex'} marginLeft={2} marginTop={3}>
								<input
									className={
										errorMsg === 'Por favor seleccione una imagen'
											? 'custom-fileInput custom-fileInputError'
											: 'custom-fileInput'
									}
									type='file'
									name='file'
									value={file}
									onChange={handleInputChange}
									id='imagenMiCuenta'
									accept='.PNG,.png,.JPEG, .jpeg, .JPG, .jpg'
									style={{ width: '100%' }}
								/>
								<Button
									color='secondary'
									variant='outlined'
									size='small'
									style={{ color: '#bdbdbd', margin: '5px' }}
									onClick={cancelarEdicion}
								>
									Cancelar
								</Button>
								{enviando ? (
									<Button
										type='submit'
										color='primary'
										variant='contained'
										size='small'
										style={{ color: 'gray', margin: '5px', paddingRight: '25px' }}
										disabled
									>
										<MDBox paddingLeft={2}>
											<PXLoader showText={false} size={10} />
										</MDBox>
										Subiendo....
									</Button>
								) : (
									<Button
										type='submit'
										variant='contained'
										size='small'
										style={{ backgroundColor: '#00ACC8', color: 'white', margin: '5px' }}
									>
										Subir
									</Button>
								)}
							</MDBox>
						</form>
					)}
				</Grid>
			</MDBox>
		</Card>
	);
}

export default Header;
