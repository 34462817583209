import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

export const axiosClient = axios.create({
	baseURL: baseURL,
	headers: {
		'Content-type': 'application/json',
	},
});

export async function getToken() {
	const token = localStorage.getItem('pxc-tkn') || '';
	axiosClient.defaults.headers.common['Authorization'] = token;
	return token;
}


