export const sortData = (
	arrayToSort: any,
	elementToSort: any,
	isAscending: boolean
) => {
	switch (elementToSort) {
		case 'nombre':
			if (isAscending) {
				arrayToSort.sort((a: any, b: any) => {
					const primero = a.nombre.toLocaleLowerCase();
					const segundo = b.nombre.toLocaleLowerCase();
					if (primero < segundo) {
						return -1;
					}
					if (primero > segundo) {
						return 1;
					}
					return 0;
				});
			} else {
				arrayToSort.sort((a: any, b: any) => {
					const primero = a.nombre.toLocaleLowerCase();
					const segundo = b.nombre.toLocaleLowerCase();
					if (primero > segundo) {
						return -1;
					}
					if (primero < segundo) {
						return 1;
					}
					return 0;
				});
			}

			break;

		default:
			break;
	}
};
