import MDBox from 'components/MDBox';
import brandPixcua from 'assets/images/pixcua.png';
import MDTypography from 'components/MDTypography';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import MDButton from 'components/MDButton';
import { Link } from 'react-router-dom';

const PaymentCancel = () => {
	return (
		<div>
			<MDBox marginLeft={3} style={{ position: 'absolute' }}>
				<MDBox
					component='img'
					src={brandPixcua}
					alt='Brand'
					width='11rem'
					marginTop={3}
					alignItems={'center'}
				/>
			</MDBox>
			<MDBox
				display='flex'
				alignItems='center'
				justifyContent={'center'}
				style={{ height: '100vh' }}
			>
				<MDBox textAlign={'center'}>
					<ReportGmailerrorredIcon
						color='error'
						style={{ width: '200px', height: '200px' }}
					/>
					<MDTypography variant='h3' color='text'>
						¡Error en pago!
					</MDTypography>
					<MDTypography mb={5} color='text'>
						Lo sentimos, la solicitud de pago no fue exitosa.
					</MDTypography>
					<MDButton
						component={Link}
						type='button'
						to='/'
						variant='gradient'
						style={{
							backgroundColor: '#858585',
							color: '#fff',
						}}
					>
						Volver&nbsp;
					</MDButton>
				</MDBox>
			</MDBox>
		</div>
	);
};

export default PaymentCancel;
