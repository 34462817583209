import { Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Activo = () => {
	return (
		<>
			<MDBox display={'flex'} alignItems='center'>
				<CheckCircleIcon color='success' fontSize='medium' />
				<Typography
					id='modal-modal-title'
					color={'secondary'}
					component='p'
					style={{ fontSize: '14px', marginLeft: '5px' }}
				>
					Activo
				</Typography>
			</MDBox>
		</>
	);
};

export default Activo;
