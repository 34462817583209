import {
	Grid,
	IconButton,
	InputLabel,
	NativeSelect,
	Typography,
} from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import FormField from 'pantallas/MiCuenta/components/FormField';
import { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DataTable from 'components/Tables/DataTable';
import BtnEliminarDoc from '../components/BtnEliminarDoc';
import { numeroAMoneda } from 'helpers/aMoneda';
import moment from 'moment';
import {
	initComplementoPago,
	initDocumentoRelacionado,
} from 'actions/Comprobante/comprobanteTypes';
import { getFormaPagoAll } from 'actions/Catalogo/forma_Pago';
import { cliente, initcliente } from 'actions/Clientes/clientesTypes';
import { getClienteById, getClientesBySearch } from 'actions/Clientes/clientes';
import {
	getDocumentoRelacionadoById,
	postPostComplementoDePago,
	putComprobante,
} from 'actions/Comprobante/comprobante';
import { Dispatch } from 'redux';
import {
	LoadingDispatchTypes,
	LOADING_END,
	START_LOADING,
} from 'actions/Loading/loadingTypes';
import { axiosClient, getToken } from 'Config/axiosConfig';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';


const GuardarPago = ({
	handleOpen,
	modo,
	setDatos,
	comprobanteToUpdate,
}: any) => {
	const dispatch = useDispatch();

	const maxTime = moment().format('YYYY-MM-DD');
	const minTime = moment().subtract(1, 'year').format('YYYY-MM-DD');
	//useState para cabeceras
	const [formValue, setFormValue] = useState(initComplementoPago);
	const handleInputChange = ({ target }: any) => {
		setFormValue({
			...formValue,
			[target.name]: target.value,
		});
	};

	

	//useState para campos relacionar documento
	const [formConceptosValue, setFormConceptosValue] = useState(
		initDocumentoRelacionado
	);
	//useState para tabla de documentos relacionados
	const [documentosRelacionadosList, setDocumentosRelacionadosList] = useState(
		[]
	);
	//useState para select de forma de pago
	const [colFormaPago, setcolFormaPago] = useState([]);

	//useState para info del cliente
	const [formCliente, setFormCliente] = useState<cliente>(initcliente);
	const handleInputChangeCliente = ({ target }: any) => {
		setFormCliente({
			...formCliente,
			[target.name]: target.value,
		});
	};
	//useState para lista de busqueda de cliente
	const [arrayClientes, setArrayClientes] = useState([]);
	//useState para busqueda del documento relacionado
	const [arrayDocumentosRelacionados, setArrayDocumentosRelacionados] = useState(
		[]
	);

	//Effecto para asignar informacion cuando es un Update Info
	useEffect(() => {
		if (modo === 1) {
			//Actualizar campos de complemento de pago
			setFormValue({
				cad_pago: '',
				cert_pago: '',
				comprobante_id: comprobanteToUpdate.complementoPagos[0].comprobante_id,
				cta_beneficiario: comprobanteToUpdate.complementoPagos[0].cta_beneficiario,
				cta_ordenante: comprobanteToUpdate.complementoPagos[0].cta_ordenante,
				fecha_pago: comprobanteToUpdate.complementoPagos[0].fecha_pago,
				forma_pago_p: comprobanteToUpdate.complementoPagos[0].forma_pago_p,
				id: comprobanteToUpdate.complementoPagos[0].id,
				moneda_p: comprobanteToUpdate.complementoPagos[0].moneda_p,
				monto: comprobanteToUpdate.complementoPagos[0].monto,
				nom_banco_ord_ext:
					comprobanteToUpdate.complementoPagos[0].nom_banco_ord_ext,
				num_operacion: comprobanteToUpdate.complementoPagos[0].num_operacion,
				rfc_emisor_cta_ben:
					comprobanteToUpdate.complementoPagos[0].rfc_emisor_cta_ben,
				rfc_emisor_cta_ord:
					comprobanteToUpdate.complementoPagos[0].rfc_emisor_cta_ord,
				sello_pago: comprobanteToUpdate.complementoPagos[0].sello_pago,
				tipo_cad_pago: comprobanteToUpdate.complementoPagos[0].tipo_cad_pago,
				tipo_cambio_p: comprobanteToUpdate.complementoPagos[0].tipo_cambio_p,
				version: comprobanteToUpdate.complementoPagos[0].version,
			});
			//Actualizar campos de tabla documentos relacionados
			setDocumentosRelacionadosList(
				comprobanteToUpdate.complementoPagos[0].documentoRelacionados
			);
			//Actualizar cliente
			//Get ClienteByID
			const data = getClienteById(comprobanteToUpdate.cliente_id);
			data
				.then((respuesta) => {
					if (respuesta.status === 200) {
						setFormCliente(respuesta.data);
					}
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500');
						console.log(error.response.data.message);
					} else if (error.response.status === 400) {
						console.log('error400');
						console.log(error.response.data.message);
					} else if (error.response.status === 403) {
						console.log('error403');
						console.log(error.response.data.message);
					} else if (error.response.status === 404) {
						console.log('error404');
						setFormCliente(initcliente);
					} else {
						console.log(error.response.data.message);
					}
				});
		}
	}, []);

	// Methods
	const resetConceptos = () => setFormConceptosValue(initDocumentoRelacionado);
	const handleInputConceptosChange = ({ target }: any) => {
		setFormConceptosValue({
			...formConceptosValue,
			[target.name]: target.value,
		});
	};
	const arraySubtotal = documentosRelacionadosList
		.map((item: any) => {
			if (typeof item.imp_pagado === 'string') {
				return parseFloat(item.imp_pagado);
			} else {
				return item.imp_pagado;
			}
		})
		.reduce((prev: any, curr: any) => prev + curr, 0);
	const subtotal = Number(arraySubtotal.toFixed(2));

	const getComprobantesAsync = () => {
		return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
			await getToken();
			dispatch({ type: START_LOADING });
			axiosClient
				.get(`/comprobantes/?tipo_comprobante_id=7`)
				.then((respuesta) => {
					setDatos(respuesta.data);
					dispatch({ type: LOADING_END });
				})
				.catch((error) => {
					if (error.response.status === 500) {
						console.log('error500', error.response.data);
						dispatch({ type: LOADING_END });
					} else if (error.response.status === 400) {
						console.log('error400', error.response.data);
						dispatch({ type: LOADING_END });
					} else if (error.response.status === 403) {
						console.log('error403', error.response.data);
						dispatch({ type: LOADING_END });
					} else if (error.response.status === 404) {
						console.log('error404', error.response.data);
						setDatos([]);
						dispatch({ type: LOADING_END });
					} else {
						console.log(error.response.data);
						dispatch({ type: LOADING_END });
					}
				});
		};
	};
	const handleSubmit = () => {
		if (isSubmitValid()) {
			if (modo === 0) {
				const postCP = postPostComplementoDePago({
					c_regimen_fiscal_Cliente: formCliente.c_regimen_fiscal_Cliente,
					c_regimen_fiscal_Empresa: '',
					tipo_comprobante_id: 7,
					calle: formCliente.calle,
					cantidad_letra: '',
					cliente_id: formCliente.id,
					codigo_postal: formCliente.codigo_postal,
					colonia: formCliente.colonia,
					descuento: 0,
					tipo_de_comprobante: 'P',
					tipo_uso: 'CP01',
					nombre: formCliente.nombre,
					rfc: formCliente.rfc,
					no_exterior: formCliente.no_exterior,
					no_interior: formCliente.no_interior,
					pais: formCliente.pais,
					estado: formCliente.estado,
					localidad: formCliente.localidad,
					municipio: formCliente.municipio,
					referencia: formCliente.referencia,
					telefono: formCliente.telefono,
					email: formCliente.email,
					tasa: 0,
					subtotal: 0,
					subtotal_iva_cero: 0,
					motivo_de_descuento: '',
					iva: 0,
					retencion_iva: 0,
					retencion_isr: 0,
					total: 0,
					lugar_expedicion: '39047',
					moneda: 'MXN',
					tipo_Persona: formCliente.tipo_Persona,
					complementoPago: [
						{
							version: '1.0',
							fecha_pago: formValue.fecha_pago,
							forma_pago_p: formValue.forma_pago_p,
							moneda_p: formValue.moneda_p,
							tipo_cambio_p: formValue.tipo_cambio_p,
							monto: formValue.monto,
							num_operacion: formValue.num_operacion,
							rfc_emisor_cta_ord: formValue.rfc_emisor_cta_ord,
							rfc_emisor_cta_ben: formValue.rfc_emisor_cta_ben,
							nom_banco_ord_ext: formValue.nom_banco_ord_ext,
							cta_ordenante: formValue.cta_ordenante,
							cta_beneficiario: formValue.cta_beneficiario,
							tipo_cad_pago: '',
							cert_pago: '',
							cad_pago: '',
							sello_pago: '',
							documentosRelacionados: documentosRelacionadosList,
						},
					],
				});
				postCP
					.then((respuesta) => {
						if (respuesta.status === 201) {
							console.log('respeusta', respuesta);
							console.log(respuesta.data);
							toast.success('¡Complemento de pago creado!');
							dispatch<any>(getComprobantesAsync());
							setTimeout(() => {
								handleOpen();
							}, 1500);
						}
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500');
							console.log(error.response);
						} else if (error.response.status === 400) {
							console.log('error400');
							console.log(error.response);
						} else if (error.response.status === 403) {
							console.log('error403');
							console.log(error.response);
						} else if (error.response.status === 404) {
							console.log('error404');
							console.log(error.response);
						} else {
							console.log(error.response);
						}
					});
			} else if (modo === 1) {
				//Aqui va la peticion para el update
				const putCP = putComprobante(
					{
						c_regimen_fiscal_Cliente: formCliente.c_regimen_fiscal_Cliente,
						c_regimen_fiscal_Empresa: '',
						tipo_comprobante_id: 7,
						calle: formCliente.calle,
						cantidad_letra: '',
						cliente_id: formCliente.id,
						codigo_postal: formCliente.codigo_postal,
						colonia: formCliente.colonia,
						descuento: 0,
						tipo_de_comprobante: 'P',
						tipo_uso: 'CP01',
						nombre: formCliente.nombre,
						rfc: formCliente.rfc,
						no_exterior: formCliente.no_exterior,
						no_interior: formCliente.no_interior,
						pais: formCliente.pais,
						estado: formCliente.estado,
						localidad: formCliente.localidad,
						municipio: formCliente.municipio,
						referencia: formCliente.referencia,
						telefono: formCliente.telefono,
						email: formCliente.email,
						tasa: 0,
						subtotal: 0,
						subtotal_iva_cero: 0,
						motivo_de_descuento: '',
						iva: 0,
						retencion_iva: 0,
						retencion_isr: 0,
						total: 0,
						lugar_expedicion: '39047',
						moneda: 'MXN',
						tipo_Persona: formCliente.tipo_Persona,
						complementoPago: [
							{
								version: '1.0',
								fecha_pago: formValue.fecha_pago,
								forma_pago_p: formValue.forma_pago_p,
								moneda_p: formValue.moneda_p,
								tipo_cambio_p: formValue.tipo_cambio_p,
								monto: formValue.monto,
								num_operacion: formValue.num_operacion,
								rfc_emisor_cta_ord: formValue.rfc_emisor_cta_ord,
								rfc_emisor_cta_ben: formValue.rfc_emisor_cta_ben,
								nom_banco_ord_ext: formValue.nom_banco_ord_ext,
								cta_ordenante: formValue.cta_ordenante,
								cta_beneficiario: formValue.cta_beneficiario,
								tipo_cad_pago: '',
								cert_pago: '',
								cad_pago: '',
								sello_pago: '',
								documentosRelacionados: documentosRelacionadosList,
							},
						],
					},
					comprobanteToUpdate.id
				);
				putCP
					.then((respuesta) => {
						if (respuesta.status === 201) {
							console.log('respeusta', respuesta);
							console.log(respuesta.data);

							toast.success('¡Complemento de pago actualizado!');
							dispatch<any>(getComprobantesAsync());
							setTimeout(() => {
								handleOpen();
							}, 1500);
						}
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500');
							console.log(error.response);
						} else if (error.response.status === 400) {
							console.log('error400');
							console.log(error.response);
						} else if (error.response.status === 403) {
							console.log('error403');
							console.log(error.response);
						} else if (error.response.status === 404) {
							console.log('error404');
							console.log(error.response);
						} else {
							console.log(error.response);
						}
					});
			}
		}
	};
	const btnAdd = () => {
		if (isFormValid()) {
			setDocumentosRelacionadosList([
				...documentosRelacionadosList,
				{
					documento_id: formConceptosValue.documento_id,
					serie: formConceptosValue.serie,
					folio: formConceptosValue.folio,
					moneda_dr: formValue.moneda_p,
					tipo_cambio_dr: formValue.tipo_cambio_p,
					metodo_pago_dr: formConceptosValue.metodo_pago_dr,
					num_parcialidad: formConceptosValue.num_parcialidad,
					imp_saldo_ant: formConceptosValue.imp_saldo_ant,
					imp_pagado: formConceptosValue.imp_pagado,
					imp_saldo_insoluto: formConceptosValue.imp_saldo_insoluto,
				},
			]);
			resetConceptos();
		}
	};
	const isFormValid = (): boolean => {
		if (
			documentosRelacionadosList.some(
				(item) => item.documento_id === formConceptosValue.documento_id
			)
		) {
			toast.error('El documento seleccionado ya existe .');
			return false;
		} else if (!formConceptosValue.documento_id) {
			toast.error('UUID o Folio de documento, es un campo requerido.');
			return false;
		} else if (!formConceptosValue.num_parcialidad) {
			toast.error('Parcialidad, es un campo requerido.');
			return false;
		} else if (!formConceptosValue.imp_saldo_ant) {
			toast.error('Saldo anterior, es un campo requerido.');
			return false;
		} else if (!formConceptosValue.imp_pagado) {
			toast.error('Monto pagado, es un campo requerido.');
			return false;
		}

		return true;
	};
	const isSubmitValid = (): boolean => {
		if (!formValue.fecha_pago) {
			toast.error('Fecha de pago, es un campo requerido.');
			return false;
		} else if (!formValue.monto) {
			toast.error('Monto pagado, es un campo requerido.');
			return false;
		} else if (Number(formValue.monto) !== subtotal) {
			console.log('cantidad1', Number(formValue.monto));
			console.log('cantidad2', subtotal);

			toast.error(
				'El monto pagado no coincide con el saldo acumulado de los documentos seleccionados.'
			);
			return false;
		} else if (!formCliente.nombre) {
			toast.error(
				'El campo "Nombre" en la sección Ordenante, es un campo requerido.'
			);
			return false;
		} 
		return true;
	};

	//Estuctura de datos para TABLA
	const datosTablaDocumentosRelacionados = {
		columns: [
			{ Header: 'UUID o Folio de documento', accessor: 'uuid' },
			{ Header: 'Parcialidad', accessor: 'parcialidad' },
			{ Header: 'Saldo anterior', accessor: 'saldoAnterior' },
			{ Header: 'Monto pagado', accessor: 'montoPagado' },
			{ Header: 'Saldo insoluto', accessor: 'saldoInsoluto' },
			{ Header: 'Acciones', accessor: 'acciones' },
		],
		rows: documentosRelacionadosList.map((item, index) => {
			return {
				uuid: item.documento_id,
				parcialidad: item.num_parcialidad,
				saldoAnterior: (
					<>
						{numeroAMoneda(Number(item.imp_saldo_ant))}
					</>
				),
				montoPagado: (
					<>
						{numeroAMoneda(Number(item.imp_pagado))}
					</>
				),
				saldoInsoluto: (
					<>
						{numeroAMoneda(Number(item.imp_saldo_insoluto))}
					</>
				),
				acciones: (
					<BtnEliminarDoc
						item={item}
						comprobanteDetalleList={documentosRelacionadosList}
						setComprobanteDetalleList={setDocumentosRelacionadosList}
					/>
				),
			};
		}),
	};

	// getFormaPagoAll
	useEffect(() => {
		const dataFormaPago = getFormaPagoAll();
		dataFormaPago
			.then((respuesta) => {
				if (respuesta.status === 200) {
					setcolFormaPago(respuesta.data);
				}
			})
			.catch((error) => {
				if (error.response.status === 500) {
					console.log('error500');
					console.log(error.response);
				} else if (error.response.status === 400) {
					console.log('error400');
					console.log(error.response);
				} else if (error.response.status === 403) {
					console.log('error403');
					console.log(error.response);
				} else if (error.response.status === 404) {
					console.log('error404');
					console.log(error.response);
				} else {
					console.log(error.response);
				}
			});
	}, []);
	//Debouncing para obtener el autocompletado de clientes
	useEffect(() => {
		let timeOutSearch = setTimeout(() => {
			if (formCliente.nombre.length > 0) {
				//Get ClienteBySearch
				const data = getClientesBySearch(formCliente.nombre);
				data
					.then((respuesta) => {
						if (respuesta.status === 200) {
							setArrayClientes(respuesta.data);
						}
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500');
							console.log(error.response.data.message);
						} else if (error.response.status === 400) {
							console.log('error400');
							console.log(error.response.data.message);
						} else if (error.response.status === 403) {
							console.log('error403');
							console.log(error.response.data.message);
						} else if (error.response.status === 404) {
							console.log('error404');
							setArrayClientes([]);
						} else {
							console.log(error.response.data.message);
						}
					});
			}
		}, 500);

		return () => {
			clearTimeout(timeOutSearch);
		};
	}, [formCliente.nombre]);
	// Asignación de cliente
	useEffect(() => {  
		const clienteData = arrayClientes.find(
			(item) => item.nombre === formCliente.nombre
		);
		if (clienteData) {
			setFormCliente(clienteData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formCliente.nombre,arrayClientes]);
	// Cambio en Saldo insoluto
	useEffect(() => {
		const impSI =
			Number(formConceptosValue.imp_saldo_ant) -
			Number(formConceptosValue.imp_pagado);

		if (Number(formConceptosValue.imp_pagado) !== 0) {
			if (
				Number(formConceptosValue.imp_saldo_ant) <
				Number(formConceptosValue.imp_pagado)
			) {
				setFormConceptosValue({
					...formConceptosValue,
					imp_pagado: formConceptosValue.imp_saldo_ant,
				});
			} else {
				setFormConceptosValue({
					...formConceptosValue,
					imp_saldo_insoluto: impSI.toFixed(2).toString(),
				});
			}
		} else {
			setFormConceptosValue({
				...formConceptosValue,
				imp_saldo_insoluto: '',
			});
		}
	}, [formConceptosValue.imp_saldo_ant, formConceptosValue.imp_pagado]);
	//Debouncing para obtener el autocompletado de uuid(Relacionar Documento)
	useEffect(() => {
		let timeOutSearch = setTimeout(() => {
			if (formConceptosValue.documento_id) {
				if (formCliente.nombre.length > 0) {
					//Get ClienteBySearch
					const data = getDocumentoRelacionadoById(
						formConceptosValue.documento_id,
						formCliente.id
					);
					data
						.then((respuesta) => {
							if (respuesta.status === 200) {
								setArrayDocumentosRelacionados(respuesta.data);
							}
						})
						.catch((error) => {
							if (error.response.status === 500) {
								console.log('error500');
								console.log(error.response.data.message);
							} else if (error.response.status === 400) {
								console.log('error400');
								console.log(error.response.data.message);
							} else if (error.response.status === 403) {
								console.log('error403');
								console.log(error.response.data.message);
							} else if (error.response.status === 404) {
								console.log('error404');
								setArrayDocumentosRelacionados([]);
							} else {
								console.log(error.response.data.message);
							}
						});
				} else {
					toast.error('Debes seleccionar un cliente primero.');
					resetConceptos();
				}
			}
		}, 500);

		return () => {
			clearTimeout(timeOutSearch);
		};
	}, [formConceptosValue.documento_id]);
	// Asignación de uuid(Relacionar Documento)
	useEffect(() => {
		const docRelacionadoData = arrayDocumentosRelacionados.find(
			(item) => item.uuid === formConceptosValue.documento_id
		);
		if (docRelacionadoData) {
			//Valida si se esta Creando(0) o Editando(1)
			if (modo) {
				if (docRelacionadoData.num_parcialidad === '') {
					const parcialidad = Number(docRelacionadoData.num_parcialidad) + 1;
					if (parcialidad === 1) {
						setFormConceptosValue({
							...formConceptosValue,
							documento_id: docRelacionadoData.uuid,
							num_parcialidad: parcialidad.toString(),

							imp_saldo_ant: docRelacionadoData.total,
							imp_pagado: docRelacionadoData.total,

							folio: docRelacionadoData.folio.toString(),
							serie: docRelacionadoData.serie.toString(),
						});
					} else {
						setFormConceptosValue({
							...formConceptosValue,
							documento_id: docRelacionadoData.uuid,
							num_parcialidad: parcialidad.toString(),
							imp_saldo_ant: docRelacionadoData.imp_saldo_insoluto,
							imp_pagado: docRelacionadoData.imp_saldo_insoluto,
							folio: docRelacionadoData.folio.toString(),
							serie: docRelacionadoData.serie.toString(),
						});
					}
				} else {
					//Falta Arreglar validacion cuando estas editando un docRelacionado
					const parcialidad = Number(docRelacionadoData.num_parcialidad) + 1;
					if (parcialidad === 1) {
						setFormConceptosValue({
							...formConceptosValue,
							documento_id: docRelacionadoData.uuid,
							num_parcialidad: parcialidad.toString(),

							imp_saldo_ant: docRelacionadoData.total,
							imp_pagado: docRelacionadoData.total,

							folio: docRelacionadoData.folio.toString(),
							serie: docRelacionadoData.serie.toString(),
						});
					} else {
						setFormConceptosValue({
							...formConceptosValue,
							documento_id: docRelacionadoData.uuid,
							num_parcialidad: parcialidad.toString(),
							imp_saldo_ant: docRelacionadoData.imp_saldo_insoluto,
							imp_pagado: docRelacionadoData.imp_saldo_insoluto,
							folio: docRelacionadoData.folio.toString(),
							serie: docRelacionadoData.serie.toString(),
						});
					}
				}
			} else {
				const parcialidad = Number(docRelacionadoData.num_parcialidad) + 1;

				if (parcialidad === 1) {
					setFormConceptosValue({
						...formConceptosValue,
						documento_id: docRelacionadoData.uuid,
						num_parcialidad: parcialidad.toString(),

						imp_saldo_ant: docRelacionadoData.total,
						imp_pagado: docRelacionadoData.total,

						folio: docRelacionadoData.folio.toString(),
						serie: docRelacionadoData.serie.toString(),
					});
				} else {
					setFormConceptosValue({
						...formConceptosValue,
						documento_id: docRelacionadoData.uuid,
						num_parcialidad: parcialidad.toString(),
						imp_saldo_ant: docRelacionadoData.imp_saldo_insoluto,
						imp_pagado: docRelacionadoData.imp_saldo_insoluto,
						folio: docRelacionadoData.folio.toString(),
						serie: docRelacionadoData.serie.toString(),
					});
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formConceptosValue.documento_id]);

	return (
		<>
		
			<MDBox>
			
				<Typography
					id='modal-modal-title'
					variant='h4'
					component='h2'
					color={'primary'}
				>
					{modo === 0 && 'Agregar complemento de pago'}
					{modo === 1 && 'Editar complemento de pago'}
				</Typography>
				<MDBox
					component='form'
					mt={5}
					pb={3}
					px={3}
				>
					
					<Grid container spacing={5}>
						<Grid item xs={12} sm={2.4}>
							<InputLabel variant='standard' htmlFor='FechaPagoCP'>
								Fecha de pago:
							</InputLabel>
							<input
								type='date'
								id='FechaPagoCP'
								name='fecha_pago'
								min={minTime}
								max={maxTime}
								className='customInputFiled'
								value={formValue.fecha_pago ?? maxTime}
								onChange={handleInputChange}
								onKeyDown={(e) => e.preventDefault()}
							/>
						</Grid>
						<Grid item xs={12} sm={2.4}>
							<InputLabel variant='standard' htmlFor='montoPagadoCP'>
								$ Monto pagado:
							</InputLabel>
							<input
								className='customInputFiled'
								type={'number'}
								step='0.01'
								min='0'
								id='montoPagadoCP'
								placeholder='$ Monto pagado'
								name='monto'
								value={formValue.monto ?? ''}
								onChange={handleInputChange}
							/>
						</Grid>
						<Grid item xs={12} sm={2.4}>
							<MDBox style={{ width: '100%' }}>
								<InputLabel variant='standard' htmlFor='uncontrolled-native'>
									Forma de pago*
								</InputLabel>
								<NativeSelect
									value={formValue.forma_pago_p ?? ''}
									onChange={handleInputChange}
									inputProps={{
										name: 'forma_pago_p',
										id: 'uncontrolled-native',
									}}
									style={{ width: '100%' }}
									required
								>
									{colFormaPago.map((item) => (
										<option key={item.c_FormaPago} value={item.c_FormaPago}>
											{`${item.c_FormaPago} - ${item.descripcion}`}
										</option>
									))}
								</NativeSelect>
							</MDBox>
						</Grid>
						<Grid item xs={12} sm={2.4}>
							<MDBox style={{ width: '100%' }}>
								<InputLabel variant='standard' htmlFor='monedaCP'>
									Moneda:
								</InputLabel>
								<NativeSelect
									type={'number'}
									inputProps={{
										name: 'moneda_p',
										id: 'monedaCP',
									}}
									value={formValue.moneda_p}
									onChange={handleInputChange}
									style={{ width: '100%' }}
								>
									<option value={'MXN'}>Peso Mexicano</option>
									<option value={'USD'}>Dólar Americano</option>
									<option value={'CAD'}>Dólar Canadiense</option>
									<option value={'EUR'}>Euro</option>
								</NativeSelect>
							</MDBox>
						</Grid>

						<Grid item xs={12} sm={2.4}>
							<InputLabel variant='standard' htmlFor='tipoCambioCP'>
								Tipo de cambio:
							</InputLabel>
							<input
								className='customInputFiled'
								type={'number'}
								step='0.01'
								min='0'
								id='tipoCambioCP'
								placeholder='Tipo de cambio:'
								name='tipo_cambio_p'
								value={formValue.moneda_p !== 'MXN' ? formValue.tipo_cambio_p : 0 ?? ''}
								onChange={handleInputChange}
								disabled={formValue.moneda_p === 'MXN' ? true : false}
							/>
						</Grid>
					</Grid>
					<MDBox
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
						mt={5}
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'beetwen',
						}}
					>
						<MDBox style={{ width: '50%', minWidth: '250px' }}>
							<MDBox>
								<Typography
									id='modal-modal-title'
									variant='h6'
									component='h2'
									color={'primary'}
								>
									Ordenante
								</Typography>
							</MDBox>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={5}>
									<InputLabel variant='standard' htmlFor='nombreClienteWizard'>
										Nombre:*
									</InputLabel>
									<input
										className='customInputFiled'
										type={'text'}
										list='datalistOptions'
										id='nombreClienteWizard'
										placeholder='Nombre o razón social*'
										value={formCliente.nombre ?? ''}
										name='nombre'
										onChange={handleInputChangeCliente}
										required
										autoComplete='off'
									/>

									<datalist id='datalistOptions'>
										{arrayClientes.map((opcion) => (
											<option key={opcion.id} value={opcion.nombre}>
												{opcion.nombre}
											</option>
										))}
									</datalist>
								</Grid>
								<Grid item xs={12} sm={5}>
									<FormField
										label='Cuenta Bancaria:'
										placeholder='Cuenta Bancaria:'
										value={
											formValue.forma_pago_p !== '01' ? formValue.cta_ordenante : '' ?? ''
										}
										onChange={handleInputChange}
										name='cta_ordenante'
										type='text'
										disabled={formValue.forma_pago_p === '01' ? true : false}
									/>
								</Grid>
								<Grid item xs={12} sm={5}>
									<FormField
										label='RFC del Banco:'
										placeholder='RFC del Banco:'
										value={formValue.rfc_emisor_cta_ord ?? ''}
										onChange={handleInputChange}
										name='rfc_emisor_cta_ord'
										type='text'
									/>
								</Grid>
								<Grid item xs={12} sm={5}>
									<FormField
										label='Nombre de Banco Exterior:'
										placeholder='Nombre de Banco Exterior:'
										value={formValue.nom_banco_ord_ext ?? ''}
										onChange={handleInputChange}
										name='nom_banco_ord_ext'
										type='text'
									/>
								</Grid>
							</Grid>
						</MDBox>
						<MDBox
							style={{
								width: '50%',
								minWidth: '250px',
							}}
						>
							<MDBox>
								<Typography
									id='modal-modal-title'
									variant='h6'
									component='h2'
									color={'primary'}
								>
									Beneficiario
								</Typography>
							</MDBox>
							<Grid container spacing={4}>
								<Grid item xs={12} sm={5}>
									<FormField
										label='Cuenta Bancaria:'
										placeholder='Cuenta Bancaria:'
										value={
											formValue.forma_pago_p !== '01'
												? formValue.cta_beneficiario
												: '' ?? ''
										}
										onChange={handleInputChange}
										name='cta_beneficiario'
										type='text'
										disabled={formValue.forma_pago_p === '01' ? true : false}
									/>
								</Grid>
								<Grid item xs={12} sm={5}>
									<FormField
										label='RFC del Banco:'
										placeholder='RFC del Banco:'
										value={formValue.rfc_emisor_cta_ben ?? ''}
										onChange={handleInputChange}
										name='rfc_emisor_cta_ben'
										type='text'
									/>
								</Grid>
								<Grid item xs={12} sm={5}>
									<FormField
										label='No. de Opéración:'
										placeholder='No. de Opéración:'
										value={
											formValue.forma_pago_p !== '01' ? formValue.num_operacion : '' ?? ''
										}
										onChange={handleInputChange}
										name='num_operacion'
										type='text'
										disabled={formValue.forma_pago_p === '01' ? true : false}
									/>
								</Grid>
							</Grid>
						</MDBox>
					</MDBox>
				</MDBox>
				<MDBox
					mt={2}
					display={'flex'}
					flexDirection={'column'}
					alignItems={'center'}
				>
					<MDBox width={'95%'} component='form'>
						<MDBox>
							<Typography
								id='modal-modal-title'
								variant='h6'
								component='h2'
								color={'primary'}
							>
								Relacionar documento
							</Typography>
						</MDBox>
						<Grid container spacing={5}>
							<Grid item xs={12} sm={3}>
								<InputLabel variant='standard' htmlFor='uuidTable'>
									UUID o Folio de documento*
								</InputLabel>
								<input
									className='customInputFiled'
									type={'text'}
									list='datalistUuidList'
									id='uuidTable'
									placeholder='UUID o Folio '
									name='documento_id'
									value={formConceptosValue.documento_id}
									onChange={handleInputConceptosChange}
									required
									autoComplete='off'
								/>
								<datalist id='datalistUuidList'>
									{arrayDocumentosRelacionados.map((opcion) => (
										<option key={opcion.id} value={opcion.uuid}>
											{`${opcion.uuid} - ${opcion.serie}
											${opcion.folio} - ${numeroAMoneda(opcion.total)}`}
										</option>
									))}
								</datalist>
							</Grid>
							<Grid item xs={12} sm={2}>
								<InputLabel variant='standard' htmlFor='parcialidadTable'>
									Parcialidad
								</InputLabel>
								<input
									className='customInputFiled'
									type={'number'}
									min='0'
									id='parcialidadTable'
									placeholder='Parcialidad'
									name='num_parcialidad'
									value={formConceptosValue.num_parcialidad}
									onChange={handleInputConceptosChange}
								/>
							</Grid>

							<Grid item xs={12} sm={2}>
								<InputLabel variant='standard' htmlFor='saldoAnteriorTable'>
									$ Saldo anterior
								</InputLabel>
								<input
									className='customInputFiled'
									type={'number'}
									step='0.0001'
									min='0'
									id='saldoAnteriorTable'
									placeholder='$ Saldo anterior'
									name='imp_saldo_ant'
									value={formConceptosValue.imp_saldo_ant}
									onChange={handleInputConceptosChange}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<InputLabel variant='standard' htmlFor='montoPagadoTable'>
									$ Monto pagado
								</InputLabel>
								<input
									className='customInputFiled'
									type={'number'}
									step='0.0001'
									min='0'
									id='montoPagadoTable'
									placeholder='$ Monto pagado'
									name='imp_pagado'
									value={formConceptosValue.imp_pagado}
									onChange={handleInputConceptosChange}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<InputLabel variant='standard' htmlFor='saldoInsolutoTable'>
									$ Saldo insoluto
								</InputLabel>
								<input
									className='customInputFiled'
									type={'number'}
									step='0.0001'
									min='0'
									id='saldoInsolutoTable'
									placeholder='$ Saldo insoluto'
									name='imp_saldo_insoluto'
									value={formConceptosValue.imp_saldo_insoluto}
									onChange={handleInputConceptosChange}
									disabled
								/>
							</Grid>

							<Grid item xs={12} sm={1} display='flex' alignItems={'center'}>
								<IconButton aria-label='add' size='large' onClick={btnAdd}>
									<AddCircleIcon fontSize='inherit' style={{ color: '#49d08a' }} />
								</IconButton>
							</Grid>
						</Grid>
					</MDBox>
				</MDBox>
				<MDBox
					mt={2}
					display={'flex'}
					flexDirection={'column'}
					alignItems={'center'}
				>
					<MDBox
						mt={1}
						mb={2}
						sx={{ width: '95%' }}
						style={{
							border: '0.5px solid #d1dde3',
							borderRadius: '10px',
							boxShadow: ' 1px 1px 8px 1px #74747456',
						}}
					>
						<DataTable
							table={datosTablaDocumentosRelacionados}
						/>
					</MDBox>
					<ToastContainer
						position='top-right'
						autoClose={3000}
						hideProgressBar
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				</MDBox>

				<Grid
					container
					direction='row'
					justifyContent='flex-end'
					alignItems='flex-end'
					spacing={1}
					mt={2}
				>
					<MDButton size='small' onClick={handleOpen}>
						Cancelar
					</MDButton>
					<MDButton
						size='small'
						color='primary'
						onClick={handleSubmit}
						disabled={documentosRelacionadosList.length ? false : true}
					>
						Guardar
					</MDButton>
				</Grid>
			</MDBox>
		</>
	);
};

export default GuardarPago;
