//Definicion Controller
import { axiosClient, getToken } from '../../Config/axiosConfig';
import { cliente } from './clientesTypes';
import { iMensaje } from '../../Utilidades/mensaje.interface';

class Clientes {
	async get() {
		await getToken();
		return await axiosClient.get<cliente[]>('/Clientes');
	}
	async post(data: cliente) {
		await getToken();
		return await axiosClient.post<iMensaje[]>('/Clientes', data);
	}
	async delete(id: number) {
		await getToken();
		return await axiosClient.delete<iMensaje[]>(`/Clientes/${id}`);
	}
	async update(id: number, data: cliente) {
		await getToken();
		return await axiosClient.put<iMensaje[]>(`/Clientes/${id}`, data);
	}
}

export default new Clientes();

export const getClientesBySearch = async (busqueda: string) => {
	await getToken();
	return await axiosClient.get(`/Clientes?busqueda=${busqueda}`);
};
export const getClienteById = async (clienteId: number) => {
	await getToken();
	return await axiosClient.get(`/Clientes/${clienteId}`);
};
