// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'components/Tables/DataTable';

// Data
import { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { iSuscripcion } from 'actions/Suscripcion/suscripcionTypes';
import Activo from 'pantallas/Certificados/components/Activo';
import NoActivo from 'pantallas/Certificados/components/NoActivo';
import moment from 'moment';
import {
	LoadingDispatchTypes,
	LOADING_END,
	START_LOADING,
} from 'actions/Loading/loadingTypes';
import { axiosClient, getToken } from 'Config/axiosConfig';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import PXLoader from 'components/PXLoader/PXLoader';

function Suscripciones(): JSX.Element {
	const dispatch = useDispatch();
	const { loading } = useSelector((state: RootStore) => state.loading);
	//useState que guarda la Data obtenida del servidor
	const [suscripcionData, setSuscripcionData] = useState<iSuscripcion[]>([]);

	//Get Suscripciones
	useEffect(() => {
		//Obtener Comprobantes
		const getSuscripciones = () => {
			return async (dispatch: Dispatch<LoadingDispatchTypes>) => {
				await getToken();
				dispatch({ type: START_LOADING });
				axiosClient
					.get(`/suscripciones`)
					.then((respuesta) => {
						if (respuesta.status === 200) {
							setSuscripcionData(respuesta.data);
							dispatch({ type: LOADING_END });
						}
					})
					.catch((error) => {
						if (error.response.status === 500) {
							console.log('error500', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 400) {
							console.log('error400', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 403) {
							console.log('error403', error.response.data);
							dispatch({ type: LOADING_END });
						} else if (error.response.status === 404) {
							console.log('error404', error.response.data);
							dispatch({ type: LOADING_END });
						} else {
							console.log(error.response.data);
						}
					});
			};
		};
		dispatch<any>(getSuscripciones());
	}, []);

	//Estuctura de datos para tabla
	const datosTablaSuscripciones = {
		columns: [
			{ Header: 'Fecha de Inicio', accessor: 'fechaInicio', width: '20%' },
			{ Header: 'Fecha Fin', accessor: 'fechaFin', width: '25%' },
			{ Header: 'Plan', accessor: 'plan' },
			{ Header: 'Estatus', accessor: 'estatus', width: '7%' },
		],
		rows: suscripcionData
			.map((item) => {
				return {
					fechaInicio: moment(item.fecha_inicio).format('DD/MM/YYYY'),
					fechaFin: moment(item.fecha_fin).format('DD/MM/YYYY'),
					plan: item.plan_nombre,
					estatus: item.activo ? <Activo /> : <NoActivo />,
				};
			})
			.reverse(),
	};
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pt={6} pb={3}>
				<Card>
					<MDBox p={3} lineHeight={1}>
						<MDTypography variant='h5' fontWeight='medium' color='primary'>
							Suscripciones
						</MDTypography>
					</MDBox>
					<DataTable table={datosTablaSuscripciones} canSearch />
					{loading && (
						<>
							<MDBox m={4}>
								<PXLoader />
							</MDBox>
						</>
					)}
				</Card>
			</MDBox>
		</DashboardLayout>
	);
}

export default Suscripciones;
