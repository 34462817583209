//Definicion de interfaces -  se debe llamar igual a la entidad de la base de datos

//Types
export const AUTH_START_LOADING = 'authStartLoading';
export const AUTH_LOADING_FINISH = 'authLoadingFinish';
export const REGISTER_START_LOADING = 'registerStartLoading';
export const REGISTER_LOADING_END = 'registerLoadingEnd';
export const REGISTERING_OK = 'registeringOK';
export const REGISTERING_FALSE = 'registeringFalse';
export const AUTH_LOGOUT = 'authLogOut';
export const AUTH_SUCCESS = 'authSuccess';
export const GET_EMPLEADO_DATA = 'getEmpleadoData';
export const GET_AVAILABLEDAYS = 'getAvailableDays';
export const CLEAN_AVAILABLEDAYS = 'cleanAvailableDays';

export interface iAuthState {
	id: number;
	nombre: string;
	activo: boolean;
	email: string;
	empresa_id: number;
	tipo_usuario_id: number;
	authState: {
		loading: boolean;
		isAutenticated: boolean;
	};
	registerState: {
		loading: boolean;
		isRegister: boolean;
	};
}

export interface iUsuario {
	id: number | null;
	nombre: string;
	activo: boolean;
	email: string;
	empresa_id: number;
	tipo_usuario_id: number | null;
}
export interface iLoginData {
	email: string;
	password: string;
}
export interface iNuevoUsuario {
	nombre: string;
	rfc: string;
	email: string;
	password: string;
	plan_id: number;
}

export interface planAtributos {
	id: number;
	nombre: string;
	cantidad: number;
	precio: number;
	alias: string;
	caracteristicas: string;
	stripe_id: string;
	periodo: string;
	estaActivo: boolean;
}

export interface AuthStartLoading {
	type: typeof AUTH_START_LOADING;
}
export interface AuthLoadingFinish {
	type: typeof AUTH_LOADING_FINISH;
}
export interface RegisterStartLoading {
	type: typeof REGISTER_START_LOADING;
}
export interface RegisterLoadingEnd {
	type: typeof REGISTER_LOADING_END;
}
export interface RegisteringOK {
	type: typeof REGISTERING_OK;
}
export interface RegisteringFalse {
	type: typeof REGISTERING_FALSE;
}
export interface AuthLogOut {
	type: typeof AUTH_LOGOUT;
}
export interface AuthSuccess {
	type: typeof AUTH_SUCCESS;
	payload: {
		usuario: iUsuario;
	};
}

//Types para el dispatch
export type AuthDispatchTypes =
	| AuthSuccess
	| AuthStartLoading
	| AuthLoadingFinish
	| AuthLogOut
	| RegisterStartLoading
	| RegisterLoadingEnd
	| RegisteringOK
	| RegisteringFalse;
