import { Tooltip } from '@mui/material';
import MDBox from 'components/MDBox';
import MDProgress from 'components/MDProgress';

interface iProps {
	progressId: number;
}
const ProgressBar = ({ progressId }: iProps) => {
	return (
		<>
			<MDBox style={{ width: '50px' }}>
				{progressId === 1 && (
					<Tooltip title='Cancelado'>
						<MDProgress value={100} color={'error'} />
					</Tooltip>
				)}
				{progressId === 2 && (
					<Tooltip title='Creado'>
						<MDProgress value={30} color={'primary'} />
					</Tooltip>
				)}
				{progressId === 3 && (
					<Tooltip title='Timbrado'>
						<MDProgress value={66} color={'primary'} />
					</Tooltip>
				)}
				{progressId === 4 && (
					<Tooltip title={`Timbrado & Enviado`}>
						<MDProgress value={100} color={'primary'} />
					</Tooltip>
				)}
				{progressId === 5 && (
					<Tooltip title={`En proceso de Cancelacion`}>
						<MDProgress value={100} color={'warning'} />
					</Tooltip>
				)}
			</MDBox>
		</>
	);
};

export default ProgressBar;
