import Pages from '../routes/Pages';
import { useEffect, JSXElementConstructor, Key, ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// Material Dashboard 2 PRO React TS routes
import routes from 'routes'; //Template
import SignUp from 'pantallas/autenticacion/sign-up';
import LogIn from 'pantallas/autenticacion/sign-in/';
import ResetPassword from 'pantallas/autenticacion/reset-password';
import ConfirmPassword from 'pantallas/autenticacion/confirmPassword';
import { RequireAuthToHidden } from './RequireAuthToHidden';
import { RequireAuth } from './RequireAuth';
import { useSelector } from 'react-redux';
import { RootStore } from 'store/Store';
import { useDispatch } from 'react-redux';
import { startChecking } from 'actions/Auth/auth';
import ComprobantePDF from 'pantallas/Comprobantes/components/comprobantePdf/ComprobantePDF';
import ComprobanteXml from 'pantallas/Comprobantes/components/comprobanteXML/ComprobanteXml';
import { RequireSuscriptionActive } from './RequireSuscriptionActive';
import UpdateSubscription from 'pantallas/autenticacion/updateSubsciption/UpdateSubscription';
import PaymentSuccesful from 'pantallas/autenticacion/updateSubsciption/PaymentSuccesful';
import PaymentCancel from 'pantallas/autenticacion/updateSubsciption/PaymentCancel';
import ComprobanteDescarga from 'pantallas/Comprobantes/components/comprobanteDescarga/ComprobanteDescarga';

const AppRouter = () => {
	const dispatch = useDispatch();
	const { authState } = useSelector((state: RootStore) => state.auth);
	const getRoutes = (allRoutes: any[]): any =>
		allRoutes.map(
			(route: {
				collapse: any;
				route: string;
				component: ReactElement<any, string | JSXElementConstructor<any>>;
				key: Key;
			}) => {
				if (route.collapse) {
					return getRoutes(route.collapse);
				}

				if (route.route) {
					return (
						<Route path={route.route} element={route.component} key={route.key} />
					);
				}

				return null;
			}
		);

	//Efecto que renueva el token cada que se refesca o carga la pagina
	useEffect(() => {
		dispatch<any>(startChecking());
	}, [dispatch]);

	if (authState.loading) {
		return (
			<div>
				<h1>Cargando...</h1>
			</div>
		);
	}

	return (
		<>
			<Routes>
				<Route element={<RequireAuthToHidden />}>
					<Route path='/login/' element={<LogIn />} />
				</Route>
				<Route element={<RequireAuth />}>
					<Route path='/dashboard/' element={<UpdateSubscription />} />
					<Route element={<RequireSuscriptionActive />}>
						<Route path='/' element={<Pages />}>
							{getRoutes(routes)}
							<Route path='*' element={<Navigate to='/' />} />
						</Route>
					</Route>
				</Route>

				{/* Rutas públicas */}
				<Route path='/comprobante/pdf/:uuid' element={<ComprobantePDF />} />
				<Route path='/comprobante/xml/:uuid' element={<ComprobanteXml />} />
				<Route
					path='/comprobante/descarga/:uuid'
					element={<ComprobanteDescarga />}
				/>
				<Route path='/resetPassword' element={<ResetPassword />} />
				<Route path='/signup/' element={<SignUp />} />
				<Route path='/signup/:plan' element={<SignUp />} />
				<Route path='/recuperacion/:tkn' element={<ConfirmPassword />} />
				<Route path='/payment/success' element={<PaymentSuccesful />} />
				<Route path='/payment/cancel' element={<PaymentCancel />} />
			</Routes>
		</>
	);
};

export default AppRouter;
