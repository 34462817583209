
import MDBox from 'components/MDBox'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const EstatusPagoPagado = () => {
  return (
    <>
			<MDBox display={'flex'} alignItems='center' ml={3}>
				<CheckCircleIcon  fontSize='medium' style={{color:'#6ddf7e'}} />
				
			</MDBox>
		</>
  )
}

export default EstatusPagoPagado