
// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';

// Settings page components
import BaseLayout from '../components/BaseLayout';
import Header from './components/Header';
import EmpresaInfo from './components/EmpresaInfo';

function MiCuenta(): JSX.Element {
	return (
		<BaseLayout>
			<MDBox mt={4}>
				<Grid container>
					<Grid item xs={12}>
						<MDBox mb={3}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Header />
								</Grid>
								<Grid item xs={12}>
									<EmpresaInfo />
								</Grid>
							</Grid>
						</MDBox>
					</Grid>
				</Grid>
			</MDBox>
		</BaseLayout>
	);
}

export default MiCuenta;
