//Definicion de interfaces -  se debe llamar igual a la entidad de la base de datos
export interface cliente {
	id: number;
	empresa_id: Number;
	nombre: string;
	rfc: string;
	calle: string;
	no_exterior: string;
	no_interior: string;
	colonia: string;
	localidad: string;
	referencia: string;
	municipio: string;
	estado: string;
	pais: string;
	codigo_postal: string;
	telefono: string;
	email: string;
	metodo_de_pago: string;
	forma_de_pago: string;
	activo: Boolean;
	c_regimen_fiscal_Cliente: string;
	tipo_Persona?: string;
}
export const initcliente: cliente = {
	id: 0,
	empresa_id: 0,
	nombre: '',
	rfc: '',
	calle: '',
	no_exterior: '',
	no_interior: '',
	colonia: '',
	localidad: '',
	referencia: '',
	municipio: '',
	estado: '',
	pais: '',
	codigo_postal: '',
	telefono: '',
	email: '',
	metodo_de_pago: '',
	forma_de_pago: '',
	activo: false,
	c_regimen_fiscal_Cliente: '',
	tipo_Persona: '',
};
