import MDBox from 'components/MDBox';
import brandPixcua from 'assets/images/pixcua.png';
import MDTypography from 'components/MDTypography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MDButton from 'components/MDButton';
import { Link } from 'react-router-dom';

const PaymentSuccesful = () => {
	return (
		<div>
			<MDBox marginLeft={3} style={{ position: 'absolute' }}>
				<MDBox
					component='img'
					src={brandPixcua}
					alt='Brand'
					width='11rem'
					marginTop={3}
					alignItems={'center'}
				/>
			</MDBox>
			<MDBox
				display='flex'
				alignItems='center'
				justifyContent={'center'}
				style={{ height: '100vh' }}
			>
				<MDBox textAlign={'center'}>
					<CheckCircleIcon
						style={{ width: '200px', height: '200px', color: '#36B628' }}
					/>
					<MDTypography variant='h3' color='text'>
						Su pago fue exitoso
					</MDTypography>
					<MDTypography mb={5} color='text'>
						Gracias por tu pago. Ahora puedes utilizar y acceder nuevamente a Pixcua
					</MDTypography>
					<MDButton
						component={Link}
						type='button'
						to='/'
						variant='gradient'
						style={{
							backgroundColor: '#858585',
							color: '#fff',
						}}
					>
						Ir&nbsp;
					</MDButton>
				</MDBox>
			</MDBox>
		</div>
	);
};

export default PaymentSuccesful;
