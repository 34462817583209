import {
	iLoadingState,
	LoadingDispatchTypes,
	LOADING_END,
	LOADING_FALSE,
	LOADING_OK,
	START_LOADING,
} from 'actions/Loading/loadingTypes';

//Estado inicial
const INITIAL_STATE: iLoadingState = {
	loading: false,
	isLoadingOk: false,
};

//Inicia Reducer
export const LoadingReducer = (
	state: iLoadingState = INITIAL_STATE,
	action: LoadingDispatchTypes
): iLoadingState => {
	switch (action.type) {
		case START_LOADING:
			return {
				...state,
				loading: true,
			};
		case LOADING_END:
			return {
				...state,
				loading: false,
			};
		case LOADING_OK:
			return {
				...state,
				isLoadingOk: true,
			};
		case LOADING_FALSE:
			return {
				...state,
				isLoadingOk: false,
			};

		default:
			return state;
	}
};
